import React from 'react';

interface ButtonProps {
  text: string;
  additionalStyle?: string;
  onClick?: () => void;
}

const Button = ({ text, additionalStyle, onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`text-xs lg:text-sm dark:border-none border dark:bg-darkButton dark:hover:bg-darkButtonHover dark:darkButtonText bg-plutoblue-500 text-white px-6 py-2 rounded-full text-center ${additionalStyle}`}
    >
      {text}
    </button>
  );
};

export default Button;
