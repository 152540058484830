import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import plutoLogo from '../../../assets/images/pluto-community-logo.png';
import { Project } from '../../../async/projects/projects';
import ProjectImageComponent from '../../../components/ProjectImageUpload/ProjectImage';
import store from '../../../store/store';

interface ProjectListProps {
  projects: Project[];
}

const ProjectList = ({ projects }: ProjectListProps) => {
  if (!projects || projects.length < 1) return null;
  const navigate = useNavigate();

  const setCurrentProject = store((s) => s.projectSlice.setCurrentProject);

  const set = (project) => {
    setCurrentProject(project);
    navigate(`/projects/${project.id}`);
  };

  return (
    <div>
      {projects.map((project, i) => (
        <div key={i} className="mb-6 max-w-screen">
          <div className=" flex flex-row">
            <div className="mt-1 h-full rounded-lg dark:bg-dark bg-white w-full ">
              <div className=" flex flex-col md:flex-row md:items-center">
                <div>
                  <ProjectImageComponent
                    src={project.imageUrl}
                    containerClassname="rounded-lg border-4 dark:border-gray-700 border-white"
                  />
                </div>

                <div className=" flex  md:place-content-between flex-col  ml-2 md:ml-6 mr-1 my-1 text-xs md:text-base">
                  <p className="dark:text-darkText text-gray-900 pb-1 font-flexa font-bold text-base md:text-2xl">
                    {project?.name}
                  </p>
                  <p className="text-[0.75rem] leading-3 md:text-base text-gray-500 font-flexa pr-2">
                    {project.domains?.join(', ') ?? ''}
                  </p>
                </div>
                <button
                  className="mt-4 md:mt-0 self-start md:self-center md:ml-auto text-xs md:text-sm md:font-bold bg-gray-200 text-plutoblue-500  rounded-full px-2 md:px-4 py-1   hover:cursor-pointer mb-1"
                  onClick={() => set(project)}
                >
                  Manage
                </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ProjectList;
