import { StylesConfig } from 'react-select';

export const multiSelectStyles: StylesConfig<any, true> = {
  control: (styles) => ({
    ...styles,
    backgroundColor: 'rgb(30 41 59)',
    color: 'white',
    border: '1px solid rgb(75 85 99)',
    borderRadius: '6px',
    boxShadow: 'none',
    ':focus': {
      border: '1px solid rgb(75 85 99)',
    },
    ':hover': {
      border: '1px solid rgb(75 85 99)',
    },
  }),
  // container: (styles) => {
  //   return { ...styles, border: '1px solid rgb(75 85 99)', borderRadius: '6px' };
  // },
  indicatorSeparator: (styles) => {
    return {
      ...styles,
      backgroundColor: 'rgb(75 85 99)',
    };
  },
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    const color = 'red';
    return {
      ...styles,
      backgroundColor: isDisabled ? 'red' : isSelected ? data.color : isFocused ? 'rgb(30 41 59)' : 'rgb(15 23 42)',
      color: isDisabled ? '#ccc' : isSelected ? ('red' ? 'white' : 'black') : 'white',
      cursor: isDisabled ? 'not-allowed' : 'default',

      ':active': {
        ...styles[':active'],
        backgroundColor: 'rgb(30 41 59)',
      },
    };
  },
  input: (styles) => {
    return {
      ...styles,
      color: 'white',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      backgroundColor: 'none',
    };
  },
  multiValue: (styles, { data }) => {
    return {
      ...styles,
      backgroundColor: 'rgb(15 23 42)',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      border: '1px solid rgb(75 85 99)',
      backgroundColor: 'rgb(15 23 42)',
      borderRadius: '6px',
      paddingTop: '0px',
      paddingBottom: '0px',
      ':hover': {
        backgroundColor: 'rgb(30 41 59)',
      },
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: data.color,
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    ':hover': {
      backgroundColor: data.color,
      color: 'white',
    },
  }),
  noOptionsMessage: (styles) => ({
    ...styles,
    backgroundColor: 'rgb(30 41 59)',
    color: 'white',
    border: 'rgb(30 41 59)',
  }),
};

export const multiSelectLightStyles: StylesConfig<any, true> = {};
