import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../../components/Button';
import ethereumLogo from '../../../assets/images/eth-logo.png';
import SecondaryButton from '../../../../components/SecondaryButton';
import Modal from '../../../../components/Modal';
import FileDragDrop from '../../../../components/FileDragDrop';
import { TiDelete } from 'react-icons/ti';
import TextInput from '../../../../components/TextInput';
import tw from 'twin.macro';
import '../../projects.css';
import store from '../../../../store/store';
import AddUser from './AddUser';
import ImportCsv from './ImportCsv';
import { useParams } from 'react-router-dom';
import CreateTag from './CreateTag';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import { Table } from '@mantine/core';
import DeleteTag from './DeleteTag';
import { FaRegTimesCircle } from 'react-icons/fa';
import AddTagsToUser from './AddTagsToUser';
import Pagination from './Pagination';
import { formatEthereumIdentity, formatProvider, formatWalletAddress } from '../../../../utils/helper';
import { DarkModeContext } from '../../../../hooks/hooks';

const Row = tw.td`text-xs p-2 border-b text-left  dark:border-b-darkSecondaryText`;

export enum ModalTypes {
  CREATE_TAG,
  DELETE_TAG,
}

const CommunityTags = () => {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<ModalTypes | null>(null);

  const projectUsers = store((s) => s.userSlice.projectUsers);
  const getTags = store((s) => s.tagSlice.getTags);
  const tags = store((s) => s.tagSlice.tags);
  const getUsers = store((s) => s.userSlice.getUsers);

  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const darkModeContext = useContext(DarkModeContext);

  const columns: Array<string> = ['Tag Name', ''];

  useEffect(() => {
    getTags(id);
    getUsers(id);
  }, [id]);

  return (
    <div>
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <>
          {currentModal === ModalTypes.CREATE_TAG && <CreateTag setOpen={setModalOpen} />}
          {currentModal === ModalTypes.DELETE_TAG && (
            <DeleteTag setOpen={setModalOpen} selectedTag={selectedTag} projectId={id} />
          )}
        </>
      </Modal>
      <div className="flex items-center mb-4">
        <div className="ml-auto space-x-4">
          <Button
            text={'Create Tag'}
            additionalStyle={'bg-plutoblue-500 text-white font-bold'}
            onClick={() => {
              setModalOpen(true);
              setCurrentModal(ModalTypes.CREATE_TAG);
            }}
          />
        </div>
      </div>

      {/* TABLE */}
      <div className="overflow-x-scroll hide-scrollbar">
        <Table className="border-b dark:border-b-darkSecondaryText  dark:text-darkText w-full ">
          <thead>
            <tr>
              {columns.map((header, i) => (
                <th key={i} className="p-2 text-bold border-b  text-left dark:border-b-darkSecondaryText text-lg">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tags.map((tag, i) => (
              <tr key={i} className=" group dark:hover:bg-darkSecondary hover:bg-lightHover">
                <Row>{tag.name}</Row>

                <Row className="">
                  <div className="flex flex-col space-y-1 lg:float-right lg:block">
                    <button
                      className="invisible group-hover:visible ml-2 bg-red-500 text-white text-xs  !py-1 !px-3 rounded-full"
                      onClick={() => {
                        setSelectedTag(tag);
                        setCurrentModal(ModalTypes.DELETE_TAG);
                        setModalOpen(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Row>
              </tr>
            ))}
          </tbody>
          {/* </table> */}
        </Table>
      </div>
      <Pagination />
    </div>
  );
};

export default CommunityTags;
