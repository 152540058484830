import React from 'react';
import tw from 'twin.macro';

const Label = tw.label`mb-4`;
const InfoLabel = tw.label`mb-2 text-xs text-darkSecondaryText`;
const Input = tw.input`truncate dark:text-darkText dark:placeholder:text-gray-500 dark:bg-darkSecondary p-2 border border-gray-200 rounded-md mb-1.5 dark:border-gray-600 focus:outline-none focus:border-gray-300  dark:focus:border-gray-300 dark:caret-darkText`;

interface TextInputProps {
  value: string;
  labelText?: string;
  inputPlaceholder: string;
  footnoteLabel?: string;
  customCss?: string;
  onChange: React.Dispatch<React.SetStateAction<string>>;
  type?: 'text' | 'number';
}

/**
 * Standard Text Input Component
 *
 * @param {string} labelText - Label above input component
 * @param {string} inputPlaceholder - Placeholder text inside input component
 * @param {string} footnoteLabel - small label below input component
 * @param {string} customCss - Additional tailwind classes to add to input text element for custom styling
 */
const TextInput = ({
  value,
  labelText,
  inputPlaceholder,
  footnoteLabel,
  customCss,
  onChange,
  type = 'text',
}: TextInputProps) => {
  const handleChange = (e) => {
    onChange(e.target.value);
  };
  return (
    <div className="flex flex-col">
      {labelText && <Label>{labelText}</Label>}
      <Input
        type={type}
        placeholder={inputPlaceholder}
        className={customCss}
        onChange={(e) => handleChange(e)}
        value={value}
      />
      {footnoteLabel && <InfoLabel>{footnoteLabel}</InfoLabel>}
    </div>
  );
};

export default TextInput;
