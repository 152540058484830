import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount, useProvider, useSigner, useSignMessage } from 'wagmi';
import { accountSlice } from '../../store/accountSlice';
import store from '../../store/store';
// import { useAuthenticateUserMutation } from "../../store/plutoapi"
import { SiweMessage } from 'siwe';

function createSiweMessage(address) {
  const message = new SiweMessage({
    domain: window.location.hostname,
    address,
    uri: origin,
    version: '1',
    chainId: Number.parseInt(process.env.CHAIN_ID),
  });
  return message.prepareMessage();
}

const SignMessage = () => {
  const { address } = useAccount();
  const provider = useProvider();
  const { data: signer, isError, isLoading } = useSigner();

  const signIn = store((state) => state.accountSlice.signIn);

  // const [authenticateUser, { isLoading, isSuccess }] = useAuthenticateUserMutation()
  // const { isLoading: signatureLoading, signMessage } = useSignMessage({
  //   onSuccess(sig, variables) {
  //     // authenticateUser({ signature: sig, address: address, chain: "ethereum" })
  //     console.log('sig success: ', variables);
  //     signIn(address, 'Welcome to Pluto X\n\nClick "Sign" to sign in.', sig);
  //   },
  // });

  const navigate = useNavigate();

  // useEffect(() => {
  //   // user successfully authed
  //   if (isSuccess && onSuccess !== undefined) {
  //     onSuccess()
  //   }
  // }, [isSuccess, navigate, onSuccess])

  const signInWithEthereum = async () => {
    const message = createSiweMessage(address);
    const sig = await signer.signMessage(message);
    signIn(address, message, sig, () => navigate('/projects'));
  };

  return (
    <button
      onClick={() => signInWithEthereum()}
      className="px-4 py-2 text-center justify-center dark:bg-plutoorange-500 bg-plutoblue-500 text-white justify-end group flex rounded-full items-center  text-sm"
    >
      {/* {signatureLoading || isLoading ? "Check Wallet" : "Sign In"} */}
      {'Sign In'}
    </button>
  );
};

export default SignMessage;
