/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Wallets } from '../wallets';
import { useAccount, useDisconnect } from 'wagmi';
import SignMessage from '../SignMessage';
import { MdClose } from 'react-icons/md';
import store from '../../store/store';
import { useNavigate } from 'react-router-dom';

interface WalletModalProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  headerText?: string;
  callback?: () => void;
  children?: JSX.Element;
}

const WalletModal = () => {
  const cancelButtonRef = useRef(null);
  const { address } = useAccount();
  const { disconnect } = useDisconnect();

  const open = store((state) => state.walletModalSlice.open);
  const closeWalletModal = store((state) => state.walletModalSlice.closeWalletModal);
  const accountData = store((state) => state.accountSlice.accountData);
  const signOut = store((state) => state.accountSlice.signOut);

  const navigate = useNavigate();

  return (
    <Transition.Root show={open}>
      <Dialog as="div" className="relative z-50" initialFocus={cancelButtonRef} onClose={() => closeWalletModal()}>
        <Transition.Child
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed z-50 inset-0 overflow-y-auto ">
          <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
            <Transition.Child
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative dark:bg-darkSecondary bg-white rounded-lg px-4 pt-3 pb-1 md:px-6 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:max-w-lg sm:w-full">
                <div>
                  <div className="absolute top-0 right-0 pt-2 pr-2">
                    <button
                      type="button"
                      className="dark:bg-darkSecondary bg-white rounded-md text-gray-400 hover:text-gray-500"
                      onClick={() => closeWalletModal()}
                    >
                      <span className="sr-only">Close</span>
                      <MdClose className="" aria-hidden="true" />
                    </button>
                  </div>
                  <div className="mt-3 text-center sm:mt-5">
                    {!address ? (
                      <>
                        <Wallets />
                      </>
                    ) : (
                      <>
                        {address && !accountData ? (
                          <div className="">
                            <div className="flex flex-row">
                              <span
                                className="m-1 dark:bg-darkSecondary bg-green-100 h-4 w-4 rounded-full flex items-center justify-center"
                                aria-hidden="true"
                              >
                                <span className="bg-green-400 h-2 w-2 rounded-full" />
                              </span>
                              <p className="mb-2 font-bold dark:text-darkText">
                                {`0x${address.toUpperCase().substring(2, 6)}...${address
                                  .toUpperCase()
                                  .substring(address.length - 4, address.length)}`}
                              </p>
                            </div>

                            <p className="mb-6 mx-2 text-gray-500 text-left">
                              To verify your account and sign in, please sign the message in your wallet
                            </p>

                            <div className="mx-2 grid grid-cols-2 gap-2 md:gap-4">
                              <button
                                onClick={() => {
                                  disconnect();
                                  signOut(() => navigate('/projects'));
                                }}
                                className="mr-auto px-4 py-2 text-center justify-center dark:bg-dark bg-gray-300 text-white justify-end rounded-full items-center text-sm"
                              >
                                Back
                              </button>

                              <SignMessage />
                            </div>
                          </div>
                        ) : null}
                      </>
                    )}
                  </div>
                </div>
                <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"></div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default WalletModal;
