import React, { useContext, useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import EditProject from './components/EditProject';
import tw from 'twin.macro';
import './projects.css';
import ManageIntegration from './components/ManageIntegration';
import EarlyAccess from './components/EarlyAccessPhase';
import { DarkModeContext, useDarkMode } from '../../hooks/hooks';
import store from '../../store/store';
import Community from './components/CommunityUsers';
import { ProjectPhases } from '../../store/projectSlice';
import COPY from './text.yaml';
import PrivateMintPhase from './components/PrivateMintPhase';
import PublicMintPhase from './components/PublicMintPhase';

const ConfigButton = tw.button`text-xs mt-12 md:mt-8 mx-auto md:mx-0 md:ml-auto text-white px-4 py-2 md:px-8 md:py-3 rounded-full font-bold bg-[#07074E] dark:bg-darkButton`;

const ProjectID = () => {
  const { id } = useParams();

  const isUserSignedIn = store((s) => s.accountSlice.isUserSignedIn);
  const getContracts = store((s) => s.contractSlice.getContracts);
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const getProject = store((s) => s.projectSlice.getProject);
  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const projectMissions = store((s) => s.projectSlice.projectMissions);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const getUsers = store((s) => s.userSlice.getUsers);
  const getTags = store((s) => s.tagSlice.getTags);
  const projectUsers = store((s) => s.userSlice.projectUsers);

  useEffect(() => {
    if (isUserSignedIn) {
      getProject(id);
      getContracts(id);
      getProjectMissions(id);
      getPhases(id);
      getUsers(id);
      getTags(id);
    }
  }, [id, isUserSignedIn]);

  return (
    <div>
      <>
        <div>
          <h1 className="font-flexa font-bold dark:text-darkText text-3xl mt-8 mb-4">
            {projectPhases.length > 0 ? `${COPY.project.title.phases}` : `${COPY.project.title.noPhases}`}
          </h1>
          <p className="dark:text-darkSecondaryText mb-8">
            {projectPhases.length > 0 ? `${COPY.project.description.phases}` : `${COPY.project.description.noPhases}`}
          </p>
        </div>
        <>
          <div className="mb-8">
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-10 text-center md:text-left">
              <EarlyAccess />
              <PrivateMintPhase />
              <PublicMintPhase />
            </div>
          </div>

          <div className="hidden">
            <h1 className="font-flexa dark:text-white font-bold text-3xl mb-4">Guides</h1>
            <p className="dark:text-darkSecondaryText mb-8">
              Learn more about using Mintkit. Follow the steps in the integration guides to setup the Mintkit SDK. If
              you are starting a new community, but are not ready to mint, focus on a website integration. If you have
              an existing community and are ready to mint, the contract integration guide can get you started.
            </p>

            <div className="grid grid-cols-3">
              <div className="mr-8 mb-8 pb-8 px-8 dark:bg-slate-800 bg-gray-100 rounded-xl">
                <div className="flex flex-col h-full justify-between">
                  <h2 className="dark:text-white text-4xl mt-8">Build a community with a website integration</h2>
                  <p className="dark:text-white text-md mt-8">
                    Embed the Mintkit Early Access component on your website to register new users through their wallets
                    and social connections.
                  </p>
                  <ConfigButton>Read the docs</ConfigButton>
                </div>
              </div>
              <div className="mr-8 mb-8 pb-8 px-8 dark:bg-slate-800 bg-gray-100 rounded-xl">
                <div className="flex flex-col h-full justify-between">
                  <h2 className="dark:text-white text-4xl mt-8">Build a gated mint with a contract integration</h2>
                  <p className="dark:text-white text-md mt-8">
                    Embed the Mintkit Mint component on your mint website to create a gated and bot protected mints. Use
                    powerful tools to avoid the pitfalls of minting with open APIs.
                  </p>
                  <ConfigButton>Read the docs</ConfigButton>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </div>
  );
};

export default ProjectID;
