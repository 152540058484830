import React, { useContext, useEffect, useState } from 'react';
import twitter from '../../../assets/images/twitter.png';
import discord from '../../../assets/images/discord.png';
import opensea from '../../../assets/images/opensea.png';
import twitterDark from '../../../assets/images/twitter-dark.png';
import discordDark from '../../../assets/images/discord-dark.png';
import openseaDark from '../../../assets/images/opensea-dark.png';
import ethereumLogo from '../../../assets/images/eth-logo.png';
import { TbExternalLink } from 'react-icons/tb';
import tw from 'twin.macro';
import RegistrationDetails from './RegistrationDetails';
import styled from 'styled-components';
import { DarkModeContext } from '../../../hooks/hooks';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ProjectPhases } from '../../../store/projectSlice';
import store from '../../../store/store';
import SubmitButton from '../../../components/SubmitButton';
import Modal from '../../../components/Modal';
import { PhaseLoadingState } from '../../../store/phasesSlice';
import Spinner from '../../../components/Spinner';
import { DeleteButton } from './phases/DeleteButton';
import TextInput from '../../../components/TextInput';
import InputText from '../../../components/InputText';
import DeletePhaseModal from './phases/DeletePhaseModal/DeletePhaseModal';
import { SecondaryButton } from './phases/SecondaryButton';
import { LOCAL_STORAGE_TYPES } from '../../../types/shared';
import PhaseDates from './phases/PhaseDates';
import DomainWarning from './phases/DomainWarning';
import { IoTrashOutline } from 'react-icons/io5';

const InnerSection = tw.div``;
const Col = tw.div`pr-2 `;
const SectionTitle = tw.div`text-sm font-flexa  dark:text-darkText`;
const DateText = tw.p`font-flexa dark:text-darkText`;

const Grid = tw.div`grid auto-rows-min gap-1`;
const GridItem = tw.div``;

const Section = styled.section`
  display: flex;
  layout: relative;
  flex-direction: column;
  margin-top: 1rem;
`;

const EarlyAccessPhase = () => {
  const darkModeContext = useContext(DarkModeContext);
  const navigate = useNavigate();

  const { id, phase } = useParams();
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const loadingType = store((s) => s.phaseSlice.loadingType);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const editProject = store((s) => s.projectSlice.editProject);
  const isUserSignedIn = store((s) => s.accountSlice.isUserSignedIn);
  const discordMissions = store((s) => s.discordMissionSlice.discordMissions);
  const deletePhase = store((s) => s.phaseSlice.deletePhase);
  const getEthBalanceMission = store((s) => s.ethMissionSlice.getEthBalanceMission);
  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [phaseDeleteText, setPhaseDeleteText] = useState('');

  const earlyAccessPhaseData = projectPhases?.find((phase) => phase.kind === ProjectPhases.EARLY_ACCESS);
  const earlyAccessActive = currentProjectData?.phaseId === earlyAccessPhaseData?.id;

  const socials = [
    {
      name: 'Eth',
      image: ethereumLogo,
      active: earlyAccessPhaseData?.missions.find((mission) => mission?.key === 'eth_balance'),
    },
    {
      name: 'Discord',
      image: darkModeContext.darkMode ? discordDark : discord,
      active: earlyAccessPhaseData?.missions.find((mission) => mission?.key === 'discord'),
    },
    {
      name: 'Twitter',
      image: darkModeContext.darkMode ? twitterDark : twitter,
      active: earlyAccessPhaseData?.missions.find((mission) => mission?.key === 'twitter'),
    },
  ];

  const deleteEarlyAccess = async () => {
    if (earlyAccessPhaseData && earlyAccessPhaseData.id) {
      const r = await deletePhase(earlyAccessPhaseData.id);
      if (r) {
        setShowDeleteModal(false);
        setPhaseDeleteText('');
        getPhases(id);
      }
    }
  };

  const setUpEditPhase = async () => {
    if (earlyAccessPhaseData) {
      try {
        let twitterExists = earlyAccessPhaseData.missions.find((mission) => mission.key === 'twitter');
        let discordExists = earlyAccessPhaseData.missions.find((mission) => mission.key === 'discord');
        let ethBalanceExists = earlyAccessPhaseData.missions.find((mission) => mission.key === 'eth_balance');
        if (twitterExists) {
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id, twitterExists.id);
        }
        if (discordExists) {
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id, discordExists.id);
        }
        if (ethBalanceExists) {
          const r = await getEthBalanceMission(ethBalanceExists.id);
          if (r) {
            localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_ETHEREUM_MISSION + id, r?.min_eth_balance_in_ether);
          }
        }

        if (earlyAccessPhaseData.tags.length > 0) {
          localStorage.setItem(
            LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id,
            JSON.stringify(earlyAccessPhaseData.tags)
          );
        }

        earlyAccessPhaseData?.starts_at &&
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + id, earlyAccessPhaseData?.starts_at);
        earlyAccessPhaseData?.ends_at &&
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + id, earlyAccessPhaseData?.ends_at);

        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_EDITING_PHASE + id, 'true');
        navigate(`/projects/${id}/edit`);
      } catch (e) {}
    }
  };

  return (
    <>
      {showLaunchModal && (
        <Modal open={showLaunchModal} setOpen={setShowLaunchModal}>
          <div>
            <p className="font-flexa text-4xl dark:text-darkText">Launch Early Accces</p>
            <p className="text-sm mb-4 mt-4 dark:text-darkSecondaryText">
              Launching will transition Mintkit to the early access phase. Press launch to continue.
            </p>

            {!currentProjectData?.domains?.length && <DomainWarning />}

            <div className="flex flex-row">
              <SubmitButton
                className={`mt-4 ml-auto ${currentProjectData?.domains?.length < 1 && 'opacity-25'}`}
                disabled={loadingType === PhaseLoadingState.SET_PHASE || currentProjectData?.domains?.length < 1}
                onClick={() => {
                  editProject(
                    id,
                    currentProjectData?.teamId,
                    currentProjectData?.name,
                    earlyAccessPhaseData?.id,
                    currentProjectData?.domains,
                    currentProjectData?.imageUrl,
                    () => setShowLaunchModal(false)
                  );
                }}
              >
                Launch {loadingType === PhaseLoadingState.SET_PHASE && <Spinner color={'white'} />}
              </SubmitButton>
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <DeletePhaseModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          phase={'Early Access'}
          deletePhase={deleteEarlyAccess}
          phaseDeleteText={phaseDeleteText}
          setPhaseDeleteText={setPhaseDeleteText}
        />
      )}
      <div className="h-full px-8 pb-4  dark:bg-slate-800 bg-gray-100 rounded-xl">
        {earlyAccessPhaseData ? (
          <div className={`flex flex-col h-full`}>
            <Grid>
              <GridItem>
                <div className="flex flex-row items-start mt-4">
                  <h2 className="dark:text-white font-flexa font-bold text-xl">Early Access</h2>
                  <p
                    className={`${
                      earlyAccessActive ? 'bg-green-500' : 'bg-plutoblue-500 dark:bg-plutoorange-500'
                    } px-2 ml-auto rounded-full my-1 text-white text-xs`}
                  >
                    {earlyAccessActive ? 'Active' : 'Inactive'}
                  </p>
                </div>
              </GridItem>
              <GridItem>
                <Section className="">
                  <PhaseDates phaseData={earlyAccessPhaseData} />
                </Section>
              </GridItem>
              {/* Settings */}
              <GridItem>
                <div className="mt-4 border border-1 rounded-xl border-gray-500 text-center">
                  <div className="grid ">
                    {/* <div className="flex flex-col border-r border-gray-500 border-1 py-2 px-2 text-center ">
                  <span className="dark:text-white text-xs font-bold font-flexa">Community Tag</span>
                  {earlyAccessPhaseData.tags.length > 0 ? (
                    <div className="flex flex-row text-center place-content-center">
                      {earlyAccessPhaseData.tags.map((tag, i) => (
                        <span key={i} className="dark:text-white text-xs mt-1">
                          {tag.name}
                          {i < earlyAccessPhaseData.tags.length - 1 ? <span>,&nbsp;</span> : ''}
                        </span>
                      ))}
                    </div>
                  ) : (
                    <span className="dark:text-white text-xs mt-1">None</span>
                  )}
                </div> */}
                    <div className="flex flex-col text-center py-2 px-2 ">
                      <span className="dark:text-white text-xs font-bold font-flexa">Requirements</span>
                      {earlyAccessPhaseData.length < 1 || earlyAccessPhaseData.missions?.length < 1 ? (
                        <p className="dark:text-white text-xs mt-1">None</p>
                      ) : (
                        <div className="flex flex-row items-center place-content-center my-1">
                          {socials.map((social, i) =>
                            social.active ? (
                              <img key={i} src={social.image} className=" mx-2 h-4 w-4 inline-block" alt="social" />
                            ) : null
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </GridItem>
            </Grid>

            <div className="flex flex-col-reverse xl:flex-row pt-8  gap-x-1 items-stretch  xl:items-end mt-auto ">
              <div className="space-x-2 mb-2 mt-4 xl:mt-0 flex justify-between items-center">
                <IoTrashOutline
                  className="text-plutoblue-500 dark:text-white text-xl hover:scale-110 hover:cursor-pointer mr-1 transition-all"
                  onClick={() => setShowDeleteModal(true)}
                />
                <SecondaryButton onClick={() => setUpEditPhase()} className="mr-auto">
                  Edit
                </SecondaryButton>
              </div>

              {!earlyAccessActive && (
                <SubmitButton className="ml-auto hover:scale-[102.5%]" onClick={() => setShowLaunchModal(true)}>
                  Launch
                </SubmitButton>
              )}
            </div>
          </div>
        ) : (
          <div className="flex flex-col h-full justify-between pb-4">
            <h2 className="dark:text-white text-4xl mt-8 ">Grow your community with Early Access</h2>
            <p className="dark:text-white text-md mt-8">
              Embed the Mintkit Early Access component on your website to register new users through their wallets and
              social connections.
            </p>
            <SubmitButton
              className="ml-auto !text-xs !font-bold !px-4 !py-2 md:!px-8 md:!py-3 !mb-0 !mt-8 !lg:mt-0"
              onClick={() => {
                if (currentProjectData?.componentsLastSeenAt) {
                  navigate(`/projects/${id}/edit`);
                } else {
                  navigate(`/projects/${id}/setup/${ProjectPhases.EARLY_ACCESS}`);
                }
              }}
            >
              Setup Early Access
            </SubmitButton>
          </div>
        )}
      </div>
    </>
  );
};

export default EarlyAccessPhase;
