import React from 'react';
import tw from 'twin.macro';
import { Phase } from '../../../../../async/phases/phases';
import CountdownTimer from './CountdownTimer';

interface PhaseDatesProps {
  phaseData: any;
}

const DateText = tw.p` dark:text-darkText text-sm`;

const PhaseDates = ({ phaseData }: PhaseDatesProps) => {
  return (
    <>
      <div className="flex flex-col">
        <div className="flex flex-row space-x-4 md:grid md:grid-cols-2">
          <div>
            <span className="dark:text-darkText text-sm">Start</span>
            <DateText> {phaseData?.starts_at ? `${new Date(phaseData?.starts_at).toLocaleString()}` : 'None'}</DateText>
          </div>
          <div>
            <span className="dark:text-darkText text-sm">End</span>
            {/* <Date>Mon, Aug 16</Date> */}
            <DateText>{phaseData?.ends_at ? `${new Date(phaseData?.ends_at).toLocaleString()}` : 'None'}</DateText>
          </div>
        </div>
        <CountdownTimer phaseData={phaseData} />
      </div>
    </>
  );
};

export default PhaseDates;
