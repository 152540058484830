import React, { useEffect, useState, useContext, createContext, useRef } from 'react';

type ColorTheme = 'dark' | 'light';

enum PlutoThemes {
  PLUTO_DARK = 'dark',
  PLUTO_LIGHT = 'light',
}

export const useDarkMode = () => {
  const [darkMode, setDarkMode] = useState(true);
  const colorTheme: ColorTheme = darkMode ? 'light' : 'dark';

  useEffect(() => {
    let theme = localStorage.getItem('PLUTO_THEME');
    if (theme) {
      setDarkMode(theme === PlutoThemes.PLUTO_DARK);
    }
  }, []);

  useEffect(() => {
    const root = window.document.documentElement;
    root.classList.remove(colorTheme);
    root.classList.add(darkMode ? 'dark' : 'light');
    localStorage.setItem('PLUTO_THEME', darkMode ? PlutoThemes.PLUTO_DARK : PlutoThemes.PLUTO_LIGHT);
  }, [colorTheme, darkMode]);

  return [darkMode, setDarkMode] as const;
};

interface ContextProps {
  darkMode: boolean;
  setDarkMode: React.Dispatch<React.SetStateAction<boolean>>;
}

// Context
export const DarkModeContext = createContext<ContextProps>({
  darkMode: false,
  setDarkMode: () => {},
});

interface Props {
  children: React.ReactNode;
}

export const DarkModeProvider: React.FC<Props> = ({ children }) => {
  const [darkMode, setDarkMode] = useDarkMode();

  return <DarkModeContext.Provider value={{ darkMode, setDarkMode }}>{children}</DarkModeContext.Provider>;
};

export const useInterval = (callback, delay) => {
  const savedCallback = useRef(null);

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      if (savedCallback) {
        savedCallback.current();
      }
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};
