import React from 'react';
import { Link, useParams } from 'react-router-dom';

const DomainWarning = () => {
  const { id } = useParams();
  return (
    <div>
      <p className="text-red-500 text-sm mb-2">
        Warning: No domain is currently configured for this project. To launch a phase, a domain must be configured for
        your project.
      </p>
      <div className="flex flex-row place-content-center">
        <Link
          className="mx-auto text-center dark:text-darkText my-2 underline hover:cursor-pointer"
          to={`/projects/${id}/settings`}
        >
          Add Domain
        </Link>
      </div>
    </div>
  );
};

export default DomainWarning;
