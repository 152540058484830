import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckBox from '../../../../components/CheckBox';
import SubmitButton from '../../../../components/SubmitButton';
import TextInput from '../../../../components/TextInput';
import store from '../../../../store/store';
import Select, { StylesConfig } from 'react-select';
import { multiSelectStyles } from './MultiSelectStyle';
import { UserLoadingState } from '../../../../store/userSlice';
import ErrorMessage from '../../../../components/ErrorMessage';
import { DarkModeContext } from '../../../../hooks/hooks';

interface AddUserProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AddUser = ({ setOpen }: AddUserProps) => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [wallet, setWallet] = useState('');
  const [discord, setDiscord] = useState('');
  const [twitter, setTwitter] = useState('');
  const newUser = store((s) => s.userSlice.newUser);
  const errorType = store((s) => s.userSlice.errorType);
  const errorMessage = store((s) => s.userSlice.errorMessage);
  const tags = store((s) => s.tagSlice.tags);
  const getTags = store((s) => s.tagSlice.getTags);

  const darkModeContext = useContext(DarkModeContext);

  const [selectedOption, setSelectedOption] = useState(null);

  const submitUser = async (project_id) => {
    const ids = selectedOption ? selectedOption.map((x) => x.id) : [];
    const r = await newUser(project_id, name, ids, wallet, twitter, discord);
    if (r) setOpen(false);
  };

  useEffect(() => {
    getTags(id);
  }, [id]);

  return (
    <div className="p-3 md:p-0 overflow-visible">
      <p className="font-flexa text-4xl dark:text-darkText">Add User</p>
      <p className="text-sm mb-4 dark:text-darkSecondaryText mt-1">
        Add users to your allowlist to ensure they have access to your project.
      </p>

      <TextInput inputPlaceholder={'Name'} value={name} onChange={setName} />
      <TextInput inputPlaceholder={'Wallet Address'} value={wallet} onChange={setWallet} customCss="mt-1" />
      <TextInput inputPlaceholder={'Twitter'} value={twitter} onChange={setTwitter} customCss="mt-1" />
      <TextInput inputPlaceholder={'Discord'} value={discord} onChange={setDiscord} customCss="mt-1" />

      {tags.length > 0 && <p className="text-sm mt-4 mb-1 dark:text-darkSecondaryText">Add Tags</p>}
      {tags.length > 0 && (
        <Select
          isMulti
          // maxMenuHeight={100}
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={tags}
          getOptionLabel={(tag) => tag.name}
          getOptionValue={(tag) => tag.name}
          styles={darkModeContext.darkMode && multiSelectStyles}
          className="overflow-visible"
        />
      )}
      {errorType === UserLoadingState.NEW_USER && <ErrorMessage>{errorMessage}</ErrorMessage>}

      <div className="mt-8">
        <SubmitButton className="mt-4" onClick={() => submitUser(id)}>
          Add User
        </SubmitButton>
      </div>
    </div>
  );
};

export default AddUser;
