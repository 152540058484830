import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import { AxiosResponse } from 'axios';
import { NewPhaseRequest, NewPhaseResponse, IndexPhaseResponse } from '../async/phases/phases';
import { LOCAL_STORAGE_TYPES } from '../types/shared';

export enum PhaseLoadingState {
  NULL,
  NEW_PHASE,
  GET_PHASES,
  SET_PHASE,
  EDIT_PHASE,
  ADD_MISSION_TO_PHASE,
  DELETE_PHASE,
  REMOVE_MISSION_FROM_PHASE,
}

export interface PhaseSlice {
  newPhase: (
    project_id: string,
    kind: string,
    contract_id: string | null,
    name: string,
    price_in_ether: string,
    contract_func_map: any,
    starts_at: any,
    ends_at: any
    // cb?: () => void
  ) => Promise<AxiosResponse | null>;
  getPhases: (projectId: string) => Promise<void>;
  editPhase: (
    project_id: string,
    phase_id: string,
    kind: string,
    contract_id: string,
    name: string,
    price_in_ether: string,
    contract_func_map: any,
    starts_at: any,
    ends_at: any
  ) => Promise<any>;
  addMissionToPhase: (phase_id: string, mission_id: string) => Promise<boolean>;
  deleteMissionFromPhase: (phase_id: string, mission_id: string, isDiscordMission?: boolean) => Promise<boolean>;
  deletePhase: (phase_id: string) => Promise<boolean | null>;
  clearLocalStorage: (projectID: string) => void;
  resetState: () => void;
  resetLoading: () => void;
  loadingType: PhaseLoadingState;
  loadingMessage: string;
  errorType: PhaseLoadingState;
  errorMessage: string;
  projectPhases: any;
}

const initalState = {
  loadingType: PhaseLoadingState.NULL,
  loadingMessage: '',
  errorType: PhaseLoadingState.NULL,
  errorMessage: '',
  projectPhases: [],
};

export const phaseSlice: PhaseSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newPhase: async (project_id, kind, contract_id, name, price_in_ether, contract_func_map, starts_at, ends_at) => {
    getState().resetLoading();
    try {
      setState({ loadingType: PhaseLoadingState.NEW_PHASE });

      let body = { project_id, kind, name, price_in_ether, starts_at, ends_at };

      if (kind !== 'earlyaccess') {
        body['contract_id'] = contract_id;
        body['contract_func_map'] = contract_func_map;
      }
      const response = await axios.post<NewPhaseResponse>(`/phases`, body);
      if (response && response.status === 200) {
        // success: get new phases for project
        getState().getPhases(project_id);
        setState({ loadingType: PhaseLoadingState.NULL });
        return response;
      }
    } catch (e) {
      let error = '';
      for (var key in e.response?.data?.errors) {
        error += `${key} ${e.response?.data?.errors[key]}`;
        error += '\n';
      }

      setState({
        errorType: PhaseLoadingState.NEW_PHASE,
        errorMessage: error,
        loadingType: PhaseLoadingState.NULL,
      });
      return null;
    }
  },
  getPhases: async (projectId) => {
    getState().resetLoading();
    try {
      setState({ loadingType: PhaseLoadingState.GET_PHASES });

      const response = await axios.get(`/projects/${projectId}/phases`);
      // const { data } = IndexPhaseResponse.fromJson(response.data);

      if (response && response.status === 200) {
        setState({ projectPhases: response.data.data, loadingType: PhaseLoadingState.NULL });
      }
    } catch (e) {
      let error = '';
      for (var key in e.response?.data?.errors) {
        error += `${key} ${e.response?.data?.errors[key]}`;
        error += '\n';
      }
      setState({
        errorType: PhaseLoadingState.GET_PHASES,
        errorMessage: error,
        loadingType: PhaseLoadingState.NULL,
      });
      return null;
    }
  },
  editPhase: async (
    project_id,
    phase_id,
    kind,
    contract_id,
    name,
    price_in_ether,
    contract_func_map,
    starts_at,
    ends_at
  ) => {
    getState().resetLoading();
    try {
      setState({ loadingType: PhaseLoadingState.EDIT_PHASE });
      let body = {
        name,
        kind,
        contract_id,
        price_in_ether,
        contract_func_map,
        starts_at,
        ends_at,
      };
      if (contract_id) {
        body['contract_id'] = contract_id;
        body['contract_func_map'] = contract_func_map;
      }
      const response = await axios.put(`/phases/${phase_id}`, body);

      if (response && response.status === 200) {
        getState().getPhases(project_id);
        setState({ loadingType: PhaseLoadingState.NULL });
        return response;
      }
    } catch (e) {
      let error = '';
      for (var key in e.response?.data?.errors) {
        error += `${key} ${e.response?.data?.errors[key]}`;
        error += '\n';
      }

      setState({
        errorType: PhaseLoadingState.NEW_PHASE,
        errorMessage: error,
        loadingType: PhaseLoadingState.NULL,
      });
      return null;
    }
  },
  deletePhase: async (phase_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: PhaseLoadingState.DELETE_PHASE });

      const response = await axios.delete(`/phases/${phase_id}`);

      if (response && response.status === 200) {
        setState({ loadingType: PhaseLoadingState.NULL });
        return true;
      }
    } catch (e) {
      setState({ loadingType: PhaseLoadingState.NULL });
      return null;
    }
  },
  addMissionToPhase: async (phase_id, mission_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: PhaseLoadingState.ADD_MISSION_TO_PHASE });

      const response = await axios.post(`/phases/${phase_id}/mission`, { mission_id });

      if (response && response.status === 200) {
        setState({ loadingType: PhaseLoadingState.NULL });
        return true;
      }
    } catch (e) {
      setState({ loadingType: PhaseLoadingState.NULL });
      return null;
    }
  },
  deleteMissionFromPhase: async (phase_id, mission_id, isDiscordMission) => {
    getState().resetLoading();
    try {
      setState({ loadingType: PhaseLoadingState.REMOVE_MISSION_FROM_PHASE });
      const response = await axios.delete(`/phases/${phase_id}/mission/${mission_id}`);

      if (response && response.status === 200) {
        setState({ loadingType: PhaseLoadingState.NULL });

        // if its a discord mission, need to remove guild data
        if (isDiscordMission) {
          api.getState().discordMissionSlice.clearMissionData();
        }
        return true;
      }
    } catch (e) {
      setState({ loadingType: PhaseLoadingState.NULL });
      return null;
    }
  },
  clearLocalStorage: (projectID) => {
    try {
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_ETHEREUM_MISSION + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_PRICE + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_QUANTITY + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_EDITING_PHASE + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + projectID);
      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + projectID);
    } catch (e) {}
  },
  resetLoading: () => {
    setState({
      loadingType: PhaseLoadingState.NULL,
      loadingMessage: '',
      errorType: PhaseLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
