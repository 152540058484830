import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import { LOCAL_STORAGE_TYPES } from '../types/shared';

export enum TwitterMissionLoadingState {
  NULL,
  NEW_TWITTER_MISSION,
}

export interface TwitterMissionSlice {
  loadingType: TwitterMissionLoadingState;
  loadingMessage: string;
  errorType: TwitterMissionLoadingState;
  errorMessage: string;
  resetState: () => void;
  resetLoading: () => void;
  newTwitterMission: (project_id: string, final_redirect_url: string) => Promise<void>;
  getTwitterMission: (mission_id: string) => Promise<any>;
}

const initalState = {
  loadingType: TwitterMissionLoadingState.NULL,
  loadingMessage: '',
  errorType: TwitterMissionLoadingState.NULL,
  errorMessage: '',
};

export const twitterMissionSlice: TwitterMissionSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newTwitterMission: async (project_id, final_redirect_url) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TwitterMissionLoadingState.NEW_TWITTER_MISSION });
      const response = await axios.post(`/missions/twitter`, {
        project_id,
        final_redirect_url,
      });
      if (response && response.status === 200) {
        setState({ loadingType: TwitterMissionLoadingState.NULL });
        try {
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + project_id, response.data.mission_id);
        } catch (e) {}

        window.location.replace(response.data.redirect_url);
      }
    } catch (e) {
      console.log('newTwitterMission error: ', e);
    }
  },
  getTwitterMission: async (mission_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TwitterMissionLoadingState.NEW_TWITTER_MISSION });
      const response = await axios.get(`/missions/twitter/${mission_id}`);
      if (response && response.status === 200) {
        setState({ loadingType: TwitterMissionLoadingState.NULL });
        return response.data;
      }
    } catch (e) {
      return null;
    }
  },
  resetLoading: () => {
    setState({
      loadingType: TwitterMissionLoadingState.NULL,
      loadingMessage: '',
      errorType: TwitterMissionLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
