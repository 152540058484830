export const formatEthereumIdentity = (address) => {
  if (!address) return address;
  let n = address.lastIndexOf('/');
  let result = address.substring(n + 1);
  return result;
};

export const formatProvider = (provider) => {
  if (!provider) return provider;
  let n = provider.lastIndexOf('/');
  let result = provider.substring(n + 1);
  return result;
};

export const getContractNetwork = (address) => {
  if (!address) return address;

  let result = address.substring(0, address.indexOf(':'));

  if (result === 'ethereum') {
    return 'Mainnet';
  } else {
    let n = address.lastIndexOf('-');
    return result.substring(n + 1);
  }
};

export const formatWalletAddress = (address) => {
  if (!address) return address;
  return `0x${address.toUpperCase().substring(2, 6)}...${address
    .toUpperCase()
    .substring(address.length - 4, address.length)}`;
};

export const getEtherescanLink = (address, network) => {
  switch (network) {
    case 'Mainnet':
      return `https://etherscan.io/address/${address}`;
    case 'goerli':
      return `https://goerli.etherscan.io/address/${address}`;
    default:
      return `https://etherscan.io/address/${address}`;
  }
};

export const classNames = (...classes: string[]) => {
  return classes.filter(Boolean).join(' ');
};
