import { Input, Modal, Textarea } from '@mantine/core';
import React, { useState } from 'react';
import { MarketingButton } from './MarketingButton';

const ContactSection = () => {
  const [email, setEmail] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  // TODO - no API endpoint for contact yet - add when its available
  const submitContact = () => {
    setEmail('');
    setSuccessMessage('We have received your email! We will get in touch with you soon!');
    setTimeout(() => {
      setSuccessMessage('');
    }, 5000);
    return;
  };

  return (
    <div className="bg-marketingBg pt-8 md:pt-36 pb-36 text-white flex flex-col  place-content-center text-center px-4">
      <div className="place-content-center items-center flex flex-col">
        <h2 className="text-3xl md:text-5xl mb-6">
          Need smart contract or <br className="hidden md:block" /> website help?
        </h2>
        <h2 className=" md:text-lg text-marketingText">
          If you need help writing a smart contract or building a website, just <br className="hidden md:block" />{' '}
          contact us. Our partner network can help.
        </h2>

        <div className="w-full md:w-1/3 mt-8">
          <div className="relative">
            <input
              className="w-full truncate text-darkText placeholder:text-gray-500 border border-white border-opacity-10 rounded-full bg-white bg-opacity-5 p-4 focus:outline-none focus:border-gray-300 caret-darkText"
              placeholder="name@email.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <MarketingButton
              onClick={() => submitContact()}
              className="absolute right-1.5 md:right-1 !top-1/2 !-translate-y-1/2 transform"
            >
              Get in touch
            </MarketingButton>
          </div>
        </div>
        {successMessage && <p className="mt-4 text-green-500">{successMessage}</p>}
      </div>
    </div>
  );
};

export default ContactSection;
