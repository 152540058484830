import React from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  atelierCaveDark,
  atelierHeathDark,
  atelierSulphurpoolDark,
  atomOneDark,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

const solidityCode = `import "pluto/mintkit-contracts/contracts/TokenGated.sol";

contract Example is TokenGated {
  function mint(uint256 quantity, SignedToken token) public payable onlyToken(token) {
    // your mint logic
  }
}`;

const SolidityComponent = () => {
  return (
    <SyntaxHighlighter
      className={`rounded-t-lg rounded-b-lg hide-scrollbar`}
      style={atomOneDark}
      language={'solidity'}
      showLineNumbers={true}
      wrapLines={false}
      customStyle={{
        background: 'rgba(255,255, 255, 0)',
      }}
      lineNumberStyle={{
        color: 'rgba(255,255, 255, 0.20)',
      }}
    >
      {solidityCode}
    </SyntaxHighlighter>
  );
};

export default SolidityComponent;
