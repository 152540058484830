import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckBox from '../../../../components/CheckBox';
import SubmitButton from '../../../../components/SubmitButton';
import TextInput from '../../../../components/TextInput';
import store from '../../../../store/store';
import Select, { StylesConfig } from 'react-select';

interface AddUserProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: any;
  selectedTag: any;
  projectId: string;
}

const DeleteUserTag = ({ setOpen, selectedUser, selectedTag, projectId }: AddUserProps) => {
  const deleteTagFromUser = store((s) => s.tagSlice.deleteTagFromUser);
  const handleDelete = async () => {
    if (selectedUser.id) {
      const r = await deleteTagFromUser(selectedUser?.id, selectedTag?.id, projectId);
      if (r) setOpen(false);
    }
  };

  return (
    <div className="p-3 md:p-0">
      <p className="font-flexa text-4xl dark:text-darkText">Delete Tag</p>
      <p className="text-sm mb-2 dark:text-darkSecondaryText mt-2">Are you sure you want to remove this tag?</p>
      <p className="font-bold text-sm mb-1 dark:text-darkSecondaryText">
        <span className="dark:text-darkText">{`User: `}</span>
        {selectedUser?.name}
      </p>
      <p className="font-bold text-sm mb-4 dark:text-darkSecondaryText">
        <span className="dark:text-darkText">{`Tag: `}</span>
        {selectedTag?.name}
      </p>
      <div className="mt-8">
        <SubmitButton className="mt-4" onClick={() => handleDelete()}>
          Remove
        </SubmitButton>
      </div>
    </div>
  );
};

export default DeleteUserTag;
