import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import FileDragDrop from '../../components/FileDragDrop';

const Section = tw.div`my-8`;
const SectionHeader = tw.p`mb-2 dark:text-darkText`;
const Card = tw.div`py-12 px-3 border border-gray-200 rounded-md text-center space-y-4`;
const StepButton = tw.button`text-xs px-4 py-2 md:px-8 md:py-3 rounded-full font-bold `;

const NewCommunityUser = () => {
  const [csvFile, setCsvFile] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  return (
    <div className="md:mx-auto max-w-xl">
      {/* IMPORT EXISTING COMMUNITY */}
      <Section>
        {/* <SectionHeader>Import Existing Community</SectionHeader> */}
        <h2 className="text-6xl font-flexa font-bold dark:text-darkText mb-4">Import Existing Community</h2>

        <p className="dark:text-darkSecondaryText mb-8">
          The community database stores all users interested in a project. Use it to stay organized during an NFT
          project. Embed the Mintkit early access component on your website to give users a central place to join your
          community even before minting is ready. When it's time to mint, select members from your community to provide
          exclusive mint access.
        </p>
        <FileDragDrop
          uploadInstructionText={'Browse, Drag & Drop your whitelist'}
          acceptedFileTypes={'csv'}
          setFile={setCsvFile}
          file={csvFile}
        />
      </Section>
      <div className="flex flex-row md:mt-48 mt-24">
        <StepButton onClick={() => navigate(-1)} className="mr-auto bg-white text-black border border-1">
          Back
        </StepButton>
        <StepButton
          onClick={() => navigate(`/projects/${id}`)}
          className="ml-auto bg-[#07074E] dark:bg-darkButton text-white"
        >
          Next
        </StepButton>
      </div>
    </div>
  );
};

export default NewCommunityUser;
