import React from 'react';
import { TbExternalLink } from 'react-icons/tb';

interface EtherscanLinkProps {
  href: string;
  address: string;
  className?: string;
}

const EtherscanLink = ({ href, address, className }: EtherscanLinkProps) => {
  return (
    <a
      target="_blank"
      href={href}
      className={`text-sm dark:text-white inline-flex items-center hover:cursor-pointer hover:underline  ${className}`}
      rel="noreferrer"
    >
      {address}
      <TbExternalLink className="text-gray-500 text-gray-400 ml-1 hover:underline" />
    </a>
  );
};

export default EtherscanLink;
