import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import store from '../../../../store/store';
import { LOCAL_STORAGE_TYPES } from '../../../../types/shared';

const TwitterAuth = ({ slug }) => {
  const newTwitterMission = store((s) => s.twitterMissionSlice.newTwitterMission);
  const { id } = useParams();
  const [twitterAuthed, setTwitterAuthed] = useState(false);
  const projectMissions = store((s) => s.projectSlice.projectMissions);
  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const getTwitterMission = store((s) => s.twitterMissionSlice.getTwitterMission);
  const [twitterUsername, setTwitterUsername] = useState('Connected');

  useEffect(() => {
    const checkTwitterStatus = async () => {
      try {
        let twitter = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id);
        if (twitter) {
          let r = await getProjectMissions(id);
          if (r) {
            let completedAuth = r.find((mission) => mission.id === twitter);
            if (completedAuth && completedAuth.ready) {
              let data = await getTwitterMission(twitter);
              if (data && data.username) {
                setTwitterUsername(`@${data.username}`);
              } else {
                setTwitterUsername('Connected');
              }
              setTwitterAuthed(true);
            }
          }
        }
      } catch (e) {}
    };

    checkTwitterStatus();
  }, []);

  return (
    <>
      {twitterAuthed ? (
        <div className="flex flex-row items-center">
          <FaCheck className="text-green-500 mr-2" /> <span>{twitterUsername}</span>
        </div>
      ) : (
        <button
          onClick={() => newTwitterMission(id, window.location.href.split('?')[0])}
          className={`text-xs lg:text-sm dark:border-none border dark:bg-darkButton dark:darkButtonText bg-plutoblue-500 text-white px-6 py-2 rounded-full text-center`}
        >
          Connect
        </button>
      )}
    </>
  );
};

export default TwitterAuth;
