import React from 'react';
import { SiDiscord, SiGithub, SiTwitter } from 'react-icons/si';
import logo from '../../../assets/images/pluto-x-thicc.png';
import styles from '../marketing.module.css';

const socialLinks = [
  { text: 'twitter', link: 'https://twitter.com/gm_pluto', icon: <SiTwitter /> },
  { text: 'github', link: 'https://github.com/pluto', icon: <SiGithub /> },
  { text: 'discord', link: 'https://discord.gg/plutoxyz', icon: <SiDiscord /> },
];
const footerLinks = [
  { text: 'terms of service', link: 'https://github.com/pluto' },
  { text: 'privacy policy', link: 'https://github.com/pluto' },
];

const MarketingFooter = () => {
  return (
    <footer className="xl:pt-64 pt-24 bg-marketingBg px-8 xl:px-36">
      <div className="w-full  relative flex flex-col md:flex-row place-content-center items-center pb-8 lg:pb-16 bg-marketingBg">
        <div className="flex flex-row items-center place-content-center gap-4">
          <a href="https://www.pluto.xyz" target="_blank">
            <img src={logo} className=" h-16" alt="logo" />
          </a>
          <a href="https://www.pluto.xyz" target="_blank" className="text-white font-medium leading-5">
            Mintkit <br />
            <span className="text-sm font-normal">by Pluto</span>
          </a>
        </div>

        <div className="absolute inset-x-auto flex flex-row  mt-16">
          {socialLinks.map((footer) => (
            <a
              key={footer.text}
              target="_blank"
              className={`px-2 text-white text-xl hover:scale-105 hover:transition-all`}
              href={footer.link}
            >
              {footer.icon}
            </a>
          ))}
        </div>

        <ul className="pt-16 lg:mt-0 grid grid-cols-2 gap-8 md:text-center md:flex md:flex-row md:ml-auto  md:items-center md:space-x-4  px-2 md:px-0">
          {footerLinks.map((footer) => (
            <a
              key={footer.text}
              target="_blank"
              className={`text-white text-md ${styles.linkUnderline}`}
              href={footer.link}
            >
              {footer.text}
            </a>
          ))}
        </ul>
      </div>
    </footer>
  );
};

export default MarketingFooter;
