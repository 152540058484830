import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import plutoLogo from '../../assets/images/pluto-logo-orange.png';

interface SidebarProps {
  sidebarOpen: boolean;
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Sidebar = ({ sidebarOpen, setSidebarOpen }: SidebarProps) => {
  const navigate = useNavigate();
  const MenuItems = [{ name: 'Projects', link: '/projects' }];

  const SocialItems = ['Discord', 'Twitter', 'Documentation'];

  return (
    <div>
      {sidebarOpen && (
        <div className="relative lg:hidden" role="dialog" aria-modal="true">
          <div className="fixed inset-0 bg-gray-600 bg-opacity-75 z-30"></div>

          <div className="fixed inset-0 z-40 flex">
            <div className="relative max-w-xs w-full dark:bg-dark bg-white pt-5 pb-4 flex-1 flex flex-col">
              <div className="absolute top-0 right-0 -mr-12 pt-2">
                <button
                  type="button"
                  className="ml-1 flex items-center justify-center h-10 w-10 rounded-full"
                  onClick={() => setSidebarOpen(false)}
                >
                  <span className="sr-only">Close sidebar</span>
                  {/* <!-- Heroicon name: outline/x --> */}
                  <svg
                    className="h-6 w-6 text-white"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="2"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>

              <div className="flex-shrink-0 px-4 flex items-center mb-4">
                <p
                  className="dark:text-darkText text-black lg:block text-4xl hover:cursor-pointer"
                  onClick={() => navigate('/projects')}
                >
                  Mintkit
                </p>
              </div>
              <div className="mt-5 mx-2 flex-1 h-0 overflow-y-auto space-y-3">
                {/* <!-- Current: "bg-gray-100 text-gray-900", Default: "text-gray-600 hover:bg-gray-50 hover:text-gray-900" --> */}
                {MenuItems.map((item, i) => (
                  <Link
                    key={i}
                    to={item.link}
                    onClick={() => setSidebarOpen(false)}
                    className=" dark:text-darkText text-gray-900 group rounded-md py-2 px-2 flex items-center text-base font-medium"
                  >
                    {item.name}
                  </Link>
                ))}
              </div>
            </div>

            <div className="flex-shrink-0 w-14">
              {/* <!-- Dummy element to force sidebar to shrink to fit close icon --> */}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Sidebar;
