import React, { useEffect } from 'react';
import { Wallets } from '../../components/wallets';
import { Fragment, useRef } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { useAccount, useDisconnect } from 'wagmi';
import SignMessage from '../../components/SignMessage';
import { MdClose } from 'react-icons/md';
import store from '../../store/store';
import { useNavigate } from 'react-router-dom';

const WalletConnectPage = () => {
  const { address } = useAccount();
  const { disconnect } = useDisconnect();
  const isUserSignedIn = store((state) => state.accountSlice.isUserSignedIn);
  const accountData = store((state) => state.accountSlice.accountData);
  const navigate = useNavigate();

  useEffect(() => {
    // If user is signed in, redirect them to projects page
    if (isUserSignedIn) {
      navigate('/projects');
    }
  }, [isUserSignedIn]);

  return (
    <div className="w-screen h-screen max-h-screen overflow-hidden p-4 dark:bg-darkSecondary    dark:text-darkText">
      <div className="fixed top-0 z-20 h-16 w-full flex md:px-28">
        <p
          className="dark:text-darkText text-black hidden lg:block m-4 text-4xl hover:cursor-pointer"
          onClick={() => navigate('/')}
        >
          Mintkit
        </p>
      </div>
      <div className="flex flex-col h-screen items-center place-content-center">
        <div className="flex flex-col mx-4 text-center ">
          <p className="text-4xl mb-2">Welcome</p>
          <p className="text-xl mb-8">Connect your wallet and sign in</p>
        </div>
        {!address ? (
          <Wallets />
        ) : address && !accountData ? (
          <div className="">
            <div className="flex flex-row place-content-center">
              <span className="m-1  h-4 w-4 rounded-full flex items-center justify-center" aria-hidden="true">
                <span className="bg-green-400 h-2 w-2 rounded-full" />
              </span>
              <p className="mb-2 font-bold">
                {`0x${address.toUpperCase().substring(2, 6)}...${address
                  .toUpperCase()
                  .substring(address.length - 4, address.length)}`}
              </p>
            </div>

            <p className="mb-6 mx-2 text-gray-500 text-left">
              To verify your account and sign in, please sign the message in your wallet
            </p>

            <div className="grid grid-cols-2 gap-20 place-content-center mx-auto">
              <button
                className="px-4 py-2 text-center justify-center rounded-full text-sm border border-plutoblue-500 dark:border-gray-300"
                onClick={() => disconnect()}
              >
                Disconnect
              </button>
              <SignMessage />
            </div>
          </div>
        ) : null}
        <p className="text-sm absolute bottom-10 dark:text-darkText">
          By connecting your wallet and using Mintkit, you agree to our Terms of Service and Privacy Policy
        </p>
      </div>
    </div>
  );
};

export default WalletConnectPage;
