import React, { useEffect, useState } from 'react';
import { IoTrashOutline } from 'react-icons/io5';
import { RiGasStationFill } from 'react-icons/ri';
import { TbExternalLink } from 'react-icons/tb';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';
import tw from 'twin.macro';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/Spinner';
import SubmitButton from '../../../components/SubmitButton';
import { PhaseLoadingState } from '../../../store/phasesSlice';
import { ProjectPhases } from '../../../store/projectSlice';
import store from '../../../store/store';
import { LOCAL_STORAGE_TYPES } from '../../../types/shared';
import {
  formatEthereumIdentity,
  formatWalletAddress,
  getContractNetwork,
  getEtherescanLink,
} from '../../../utils/helper';
import { DeleteButton } from './phases/DeleteButton';
import DeletePhaseModal from './phases/DeletePhaseModal/DeletePhaseModal';
import DomainWarning from './phases/DomainWarning';
import PhaseDates from './phases/PhaseDates';
import { SecondaryButton } from './phases/SecondaryButton';

const Col = tw.div`pr-2 `;
const Section = styled.section`
  display: flex;
  layout: relative;
  flex-direction: column;
  margin-top: 1rem;
`;

const Grid = tw.div`grid auto-rows-min gap-1`;
const GridItem = tw.div``;

const PublicMintPhase = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const [showLaunchModal, setShowLaunchModal] = useState(false);
  const editProject = store((s) => s.projectSlice.editProject);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const loadingType = store((s) => s.phaseSlice.loadingType);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const deletePhase = store((s) => s.phaseSlice.deletePhase);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [phaseDeleteText, setPhaseDeleteText] = useState('');

  const publicPhaseData = projectPhases?.find(
    (phase) => phase.kind === ProjectPhases.PROTECTED_PUBLIC_MINT || phase.kind === ProjectPhases.PUBLIC_MINT
  );
  const publicMintActive = currentProjectData?.phaseId === publicPhaseData?.id;
  const currentContract =
    publicPhaseData && projectsContracts.length > 0
      ? projectsContracts.find((contract) => contract.id === publicPhaseData.contract_id)
      : null;

  const deletePublicMint = async () => {
    if (publicPhaseData && publicPhaseData.id) {
      const r = await deletePhase(publicPhaseData.id);
      if (r) {
        setShowDeleteModal(false);
        setPhaseDeleteText('');
        getPhases(id);
      }
    }
  };

  // setup localStorage for editing private mint phase
  const setupPublicMintPhase = async () => {
    if (publicPhaseData) {
      try {
        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id, publicPhaseData?.contract_id);
        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_PRICE + id, publicPhaseData?.price_in_ether);
        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_QUANTITY + id, publicPhaseData?.max_quantity_per_user);
        localStorage.setItem(
          LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id,
          JSON.stringify(publicPhaseData?.contract_func_map)
        );

        publicPhaseData?.starts_at &&
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + id, publicPhaseData?.starts_at);
        publicPhaseData?.ends_at &&
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + id, publicPhaseData?.ends_at);

        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_EDITING_PHASE + id, 'true');
        navigate(`/projects/${id}/mintSetup/${publicPhaseData?.kind}`);
      } catch (e) {}
    }
  };

  return (
    <>
      {showLaunchModal && (
        <Modal open={showLaunchModal} setOpen={setShowLaunchModal}>
          <div>
            <p className="font-flexa text-4xl dark:text-darkText">Launch Mint Phase</p>
            <p className="text-sm mb-4 mt-4 dark:text-darkSecondaryText">
              Launching will transition Mintkit to the public mint phase. Press launch to continue.
            </p>

            {!currentProjectData?.domains?.length && <DomainWarning />}

            <div className="flex flex-row">
              <SubmitButton
                className="mt-4 ml-auto"
                disabled={loadingType === PhaseLoadingState.SET_PHASE}
                onClick={() => {
                  editProject(
                    id,
                    currentProjectData?.teamId,
                    currentProjectData?.name,
                    publicPhaseData?.id,
                    currentProjectData?.domains,
                    currentProjectData?.imageUrl,
                    () => setShowLaunchModal(false)
                  );
                }}
              >
                Launch {loadingType === PhaseLoadingState.SET_PHASE && <Spinner color={'white'} />}
              </SubmitButton>
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <DeletePhaseModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          phase={'Public Mint'}
          deletePhase={deletePublicMint}
          phaseDeleteText={phaseDeleteText}
          setPhaseDeleteText={setPhaseDeleteText}
        />
      )}
      {currentContract && publicPhaseData ? (
        <div className="h-full px-4 pb-4 xl:px-8 dark:bg-slate-800 bg-gray-100 rounded-xl">
          <div className="flex flex-col h-full ">
            <Grid>
              <GridItem>
                <div className="flex flex-row items-start mt-4">
                  <h2 className="dark:text-white font-flexa font-bold text-xl">Public Mint</h2>
                  <p
                    className={`${
                      publicMintActive ? 'bg-green-500' : 'bg-plutoblue-500 dark:bg-plutoorange-500'
                    } px-2 ml-auto rounded-full my-1 text-white text-xs`}
                  >
                    {publicMintActive ? 'Active' : 'Inactive'}
                  </p>
                </div>
              </GridItem>
              <GridItem>
                <Section className="">
                  <Col>
                    <PhaseDates phaseData={publicPhaseData} />
                  </Col>
                </Section>
              </GridItem>
              {/* Settings */}
              <GridItem>
                <div className="mt-4 border border-1 rounded-xl border-gray-500 text-center">
                  <div className="grid grid-cols-2  border-gray-500">
                    <div className="py-2 px-2 flex flex-col border-r border-gray-500 border-1 ">
                      <span className="dark:text-white text-xs font-bold font-flexa">Network</span>
                      <span className="dark:text-white text-xs mt-1">
                        {`${getContractNetwork(currentContract.address)}`}
                      </span>
                    </div>

                    {/* <div className="py-2 px-2 flex flex-col  border-r border-gray-500 border-1  ">
                  <span className="dark:text-white text-xs font-bold font-flexa">Quantity</span>
                  <span className="dark:text-white text-xs mt-1">{publicPhaseData.max_quantity_per_user}</span>
                </div> */}
                    <div className="py-2 px-2 flex flex-col">
                      <span className="dark:text-white text-xs font-bold font-flexa">Price</span>
                      <span className="dark:text-white">
                        <RiGasStationFill size={14} className=" inline dark:text-white" />
                        <span className=" text-xs dark:text-white">+ {`${publicPhaseData.price_in_ether} Ξ`}</span>
                      </span>
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem>
                <div className="flex flex-row justify-between items-center">
                  <div className="pt-4 flex flex-col ">
                    <span className="flex-grow mr-auto dark:text-white font-bold">
                      {`${getContractNetwork(currentContract.address)} Contract`}
                    </span>

                    <a
                      target="_blank"
                      href={getEtherescanLink(
                        formatEthereumIdentity(currentContract.address),
                        getContractNetwork(currentContract.address)
                      )}
                      className="text-sm   dark:text-white flex flex-row items-center hover:cursor-pointer hover:underline self-start"
                      rel="noreferrer"
                    >
                      {formatWalletAddress(formatEthereumIdentity(currentContract.address))}
                      <TbExternalLink className="text-gray-500 text-gray-400 ml-1 hover:underline" />
                    </a>
                  </div>
                  <div className="flex flex-col  pt-4 ">
                    <span className="dark:text-white font-bold text-sm">Bot Protection</span>
                    <div className="flex flex-row">
                      <p className="dark:text-darkText text-xs">
                        {publicPhaseData.kind === ProjectPhases.PROTECTED_PUBLIC_MINT ? 'ON' : 'OFF'}
                      </p>
                    </div>
                  </div>
                </div>
              </GridItem>
              <GridItem />
            </Grid>
            <div className="flex flex-col-reverse xl:flex-row pt-8  gap-x-1 items-stretch  xl:items-end mt-auto">
              <div className="space-x-2 mb-2 mt-4 xl:mt-0 flex justify-between items-center">
                <IoTrashOutline
                  className="text-plutoblue-500 dark:text-white text-xl hover:scale-110 hover:cursor-pointer mr-1 transition-all"
                  onClick={() => setShowDeleteModal(true)}
                />
                <SecondaryButton onClick={() => setupPublicMintPhase()} className="mr-auto">
                  Edit
                </SecondaryButton>
              </div>

              {!publicMintActive && (
                <SubmitButton className="ml-auto hover:scale-[102.5%]" onClick={() => setShowLaunchModal(true)}>
                  Launch
                </SubmitButton>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div className="pb-8 px-8 dark:bg-slate-800 bg-gray-100 rounded-xl text-center md:text-left">
          <div className="flex flex-col h-full justify-between">
            <h2 className="dark:text-white text-4xl mt-8">Open your project to the public</h2>
            <p className="dark:text-white text-md mt-8">
              When you're ready, set up a mint so anyone can come to your project to join your community.
            </p>
            <SubmitButton
              className="ml-auto !text-xs !font-bold !px-4 !py-2 md:!px-8 md:!py-3 !mb-0 !mt-8 !lg:mt-0"
              onClick={() => {
                if (currentProjectData?.componentsLastSeenAt) {
                  navigate(`/projects/${id}/mintSetup/${ProjectPhases.PUBLIC_MINT}`);
                } else {
                  navigate(`/projects/${id}/setup/${ProjectPhases.PUBLIC_MINT}`);
                }
              }}
            >
              Set up Public Mint
            </SubmitButton>
          </div>
        </div>
      )}
    </>
  );
};

export default PublicMintPhase;
