import React from 'react';
import metamaskImg from '../../assets/images/metamask.svg';

interface NoWalletProps {
  noWallets: boolean;
  setNoWallets: React.Dispatch<React.SetStateAction<boolean>>;
}

const NoWallet = ({ noWallets, setNoWallets }: NoWalletProps) => {
  return (
    <div className="flex flex-col">
      <div className="dark:text-white p-2 text-center mt-2">
        Web3 authentication is based on ownership of a wallet. You'll need to install a wallet to continue
      </div>
      <div>
        <a
          className="grid grid-cols-4 rounded-md p-2 text-center m-3 dark:bg-darkSecondary dark:border dark:border-gray-600  dark:hover:bg-darkHover bg-gray-100 hover:bg-gray-200 transition-all"
          href="https://metamask.io/download/"
          target="_blank"
          rel="noreferrer"
        >
          <img src={metamaskImg} alt="wallet img" className="h-8" />

          <button className="dark:text-white  col-span-2">Install MetaMask</button>
        </a>
      </div>
      <div className="grid grid-cols-4 rounded-md p-2 text-center mb-2 mx-3 dark:hover:bg-darkHover hover:bg-gray-200 dark:bg-darkSecondary bg-gray-100 transition-all dark:border dark:border-gray-600">
        <button className="dark:text-white col-start-2 col-span-2" onClick={() => setNoWallets(false)}>
          Back to Wallets
        </button>
      </div>
    </div>
  );
};

export default NoWallet;
