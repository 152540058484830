import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import {
  EditProjectRequest,
  EditProjectResponse,
  IndexProjectResponse,
  NewProjectRequest,
  NewProjectResponse,
  Project,
  ShowProjectResponse,
} from '../async/projects/projects';
import plutoLogo from '../assets/images/pluto-community-logo.png';

export enum ProjectLoadingState {
  NULL,
  NEW_PROJECT,
  EDIT_PROJECT,
  DELETE_PROJECT,
}

export enum ProjectPhases {
  NONE = 'none',
  EARLY_ACCESS = 'earlyaccess',
  PRIVATE_MINT = 'privatemint',
  PUBLIC_MINT = 'publicmint',
  PROTECTED_PUBLIC_MINT = 'protectedpublicmint',
}

export interface ProjectSlice {
  allProjectData: Project[];
  currentProjectData: Project;
  editingProjectPhase: ProjectPhases;
  projectMissions: Array<any>;
  newProject: (name: string, domains: string[], image_base64, callback: (id: string) => void) => Promise<void>;
  editProject: (
    id: string,
    team_id: string,
    projectName: string,
    phase_id: string,
    domains: string[],
    image_base64: string,
    callback: () => void
  ) => Promise<void>;
  getProject: (id: string) => Promise<boolean>;
  getAllProjects: () => Promise<void>;
  getProjectMissions: (project_id: string) => Promise<any>;
  setCurrentProject: (project: any) => void;
  deleteProject: (project_id: string) => Promise<boolean>;
  resetState: () => void;
  resetLoading: () => void;
  loadingType: ProjectLoadingState;
  loadingMessage: string;
  errorType: ProjectLoadingState;
  errorMessage: string;
}

const initalState = {
  currentProjectData: null,
  editingProjectPhase: ProjectPhases.EARLY_ACCESS,
  allProjectData: null,
  projectMissions: [],
  loadingType: ProjectLoadingState.NULL,
  loadingMessage: '',
  errorType: ProjectLoadingState.NULL,
  errorMessage: '',
};

export const projectSlice: ProjectSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newProject: async (name, domains, imageBase64, callback) => {
    getState().resetLoading();
    try {
      setState({ loadingType: ProjectLoadingState.NEW_PROJECT });
      let teamId = api.getState().accountSlice.currentTeam;
      if (teamId) {
        const body = NewProjectRequest.toJson({ name, teamId, domains, imageBase64 });
        const response = await axios.post(`/projects`, body);
        if (response && response.status === 200) {
          const { data } = NewProjectResponse.fromJson(response.data);
          setState({ currentProjectData: data, loadingType: ProjectLoadingState.NULL });

          // fetch list of projects on new project creation
          getState().getAllProjects();
          callback(data.id);
        }
      }
    } catch (e) {
      let error = '';
      for (var key in e.response?.data?.errors) {
        error += `${key} ${e.response?.data?.errors[key]}`;
        error += '\n';
      }
      setState({
        errorType: ProjectLoadingState.NEW_PROJECT,
        errorMessage: error,
        loadingType: ProjectLoadingState.NULL,
      });
      return null;
    }
  },
  getAllProjects: async () => {
    getState().resetLoading();
    try {
      let teamId = api.getState().accountSlice.currentTeam;
      if (teamId) {
        const response = await axios.get(`/teams/${teamId}/projects`);
        if (response && response.status === 200) {
          const { data } = IndexProjectResponse.fromJson(response.data);
          setState({ allProjectData: data });
        }
      }
    } catch (e) {}
  },
  getProject: async (id) => {
    getState().resetLoading();
    try {
      const response = await axios.get(`/projects/${id}`);
      if (response && response.status === 200) {
        const { data } = ShowProjectResponse.fromJson(response.data);
        setState({ currentProjectData: data });
        return true;
      }
    } catch (e) {
      return false;
    }
  },
  getProjectMissions: async (id) => {
    getState().resetLoading();
    try {
      const response = await axios.get(`/projects/${id}/missions`);
      if (response && response.status === 200) {
        setState({ projectMissions: response.data.data });
        return response.data.data;
      }
    } catch (e) {
      console.log('getProjectMissions error:', e);
      return null;
    }
  },
  editProject: async (id, teamId, name, phaseId, domains, base64Image, cb) => {
    getState().resetLoading();
    setState({
      loadingType: ProjectLoadingState.EDIT_PROJECT,
    });

    // if user didnt change image, have send emptry string as its not changing
    let imageBase64 = base64Image;
    if (imageBase64?.substring(0, 4) === 'http') {
      imageBase64 = '';
    }
    try {
      const body = EditProjectRequest.toJson({ name, teamId, phaseId, domains, imageBase64 });
      const response = await axios.put(`/projects/${id}`, body);
      if (response && response.status === 200) {
        // refetch phases on edit project to get changed phase state
        const { data } = EditProjectResponse.fromJson(response.data);
        api.getState().phaseSlice.getPhases(id);
        setState({ currentProjectData: data });
        if (cb) cb();
      }
    } catch (e) {
      setState({
        errorType: ProjectLoadingState.EDIT_PROJECT,
        errorMessage: e.response?.data?.error ? e.response?.data?.error : 'Error editing project',
        loadingType: ProjectLoadingState.NULL,
      });
      return null;
    }
  },
  deleteProject: async (project_id) => {
    getState().resetLoading();
    try {
      const response = await axios.delete(`/projects/${project_id}`);
      if (response?.status === 200) {
        // fetch list of projects on project deletion
        getState().getAllProjects();
        return true;
      }
    } catch (e) {
      setState({
        errorType: ProjectLoadingState.DELETE_PROJECT,
        errorMessage: e.response?.data?.error ?? 'Error deleting project',
        loadingType: ProjectLoadingState.NULL,
      });

      return false;
    }
  },
  setCurrentProject: (project) => {
    setState({ currentProjectData: project });
  },

  resetLoading: () => {
    setState({
      loadingType: ProjectLoadingState.NULL,
      loadingMessage: '',
      errorType: ProjectLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
