import { Disclosure } from '@headlessui/react';
import React, { useContext } from 'react';
import ToggleSwitch from '../../../components/ToggleSwitch';
import twitter from '../../../assets/images/twitter.png';
import discord from '../../../assets/images/discord.png';
import twitterDark from '../../../assets/images/twitter-dark.png';
import discordDark from '../../../assets/images/discord-dark.png';
import wallet from '../../../assets/images/wallet.png';
import { BiWallet } from 'react-icons/bi';
import TwitterIntegration from './missions/TwitterIntegration';
import WalletIntegration from './missions/WalletIntegration';
import DiscordIntegration from './missions/DiscordIntegration';
import { DarkModeContext } from '../../../hooks/hooks';
import store from '../../../store/store';
import { useSearchParams } from 'react-router-dom';
import { ToggleRegistrationRequirements } from '../../../types/shared';

interface SocialIntegrationProps {
  toggleRegistrationRequirements: ToggleRegistrationRequirements;
  setToggleRegistrationRequirements: React.Dispatch<React.SetStateAction<ToggleRegistrationRequirements>>;
  ethBalance: string;
  setEthBalance: React.Dispatch<React.SetStateAction<string>>;
}

const SocialIntegration = ({
  toggleRegistrationRequirements,
  setToggleRegistrationRequirements,
  ethBalance,
  setEthBalance,
}: SocialIntegrationProps) => {
  let [searchParams] = useSearchParams();
  let OAuthError = searchParams.get('error');
  const darkModeContext = useContext(DarkModeContext);

  // check if account connected with discord auth
  const discordMissions = store((s) => s.discordMissionSlice.discordMissions);

  const steps: any[] = [
    {
      id: 0,
      name: 'Wallet',
      value: 'eth_balance',
      enabled: toggleRegistrationRequirements.eth_balance,
      icon: wallet,
      content: <WalletIntegration inline={true} ethBalance={ethBalance} setEthBalance={setEthBalance} />,
    },
    {
      id: 1,
      name: 'Twitter',
      value: 'twitter',
      enabled: toggleRegistrationRequirements.twitter,
      icon: darkModeContext.darkMode ? twitterDark : twitter,
      content: <TwitterIntegration inline={true} />,
    },

    {
      id: 3,
      name: 'Discord',
      value: 'discord',
      enabled: toggleRegistrationRequirements.discord,
      icon: darkModeContext.darkMode ? discordDark : discord,
      content: <DiscordIntegration inline={true} />,
    },
  ];

  return (
    <>
      <dl className={`border-[1px]  dark:border-white border-black   rounded-xl mt-4 ${OAuthError ? 'mb-2' : 'mb-10'}`}>
        {steps.map((step, index) => (
          <Disclosure
            as="div"
            key={step.id}
            className={`${step.enabled ? 'bg-white bg-opacity-10 ' : ' hover:bg-white hover:bg-opacity-10 '}`}
          >
            <>
              <div className="py-3 px-4 relative text-left w-full flex flex-1 items-center text-lg  rounded-l-full">
                {index === 0 ? (
                  <BiWallet className="h-5 dark:text-white" />
                ) : (
                  <img src={step.icon} className="h-5 " alt="img icon" />
                )}

                <span
                  className="dark:text-white font-bold text-sm md:text-base
                ml-3"
                >
                  {step.name}
                </span>

                <span className="h-7 flex items-center ml-auto">
                  <ToggleSwitch
                    enabled={step.enabled}
                    setEnabled={() =>
                      setToggleRegistrationRequirements({
                        ...toggleRegistrationRequirements,
                        [step.value]: !step.enabled,
                      })
                    }
                  />
                </span>
              </div>

              {step.enabled ? (
                <Disclosure.Panel static as="dd" className="pb-2 ml-4 flex flex-col">
                  <div className="text-sm md:text-base dark:text-darkText">{step.content}</div>
                </Disclosure.Panel>
              ) : null}
            </>
          </Disclosure>
        ))}
      </dl>
      {OAuthError && <p className="text-sm text-red-500 italic">{OAuthError}. please try again.</p>}
    </>
  );
};

export default SocialIntegration;
