import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { RiGasStationFill } from 'react-icons/ri';
import SocialIntegration from './components/socialintegration';
import { RadioGroup } from '@headlessui/react';
import { HiExternalLink } from 'react-icons/hi';
import store from '../../store/store';
import { formatEthereumIdentity, formatWalletAddress, getContractNetwork, getEtherescanLink } from '../../utils/helper';
import { TbExternalLink } from 'react-icons/tb';
import { PhaseLoadingState } from '../../store/phasesSlice';
import Spinner from '../../components/Spinner';
import ErrorMessage from '../../components/ErrorMessage';
import SubmitButton from '../../components/SubmitButton';
import TextAreaInput from '../../components/TextAreaInput';
import { ContractLoadingState } from '../../store/contractSlice';
import { SelectDropdown } from '../../components/SelectDropdown';
import { ProjectPhases } from '../../store/projectSlice';
import MintFunctions from './components/MintFunctions';
import { LOCAL_STORAGE_TYPES, ToggleRegistrationRequirements } from '../../types/shared';
import { DiscordMissionLoadingState } from '../../store/discordMissionSlice';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import { Contract } from '../../async/contracts/contracts';
import Modal from '../../components/Modal';
import CreateTag from './components/CommunityUsers/CreateTag';
import TagMintGating from './components/CommunityUsers/TagMintGating';
import DateTimePicker from './components/missions/DateTimePicker/DateTimePicker';

const Section = tw.div`mt-4 flex flex-col`;
const Label = tw.label`mt-4 dark:text-white font-bold font-flexa text-black`;
const StepButton = tw.button`text-xs px-4 py-2 md:px-8 md:py-3 rounded-full font-bold `;
const InstructionText = tw.p`text-xs md:text-base dark:text-white text-gray-700 mb-4`;

const CheckMark = () => {
  return (
    <svg
      className="h-5 w-5 dark:text-white text-black"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      aria-hidden="true"
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
        clipRule="evenodd"
      />
    </svg>
  );
};

const MintSetup = () => {
  const navigate = useNavigate();
  const { id, phase } = useParams();
  let [searchParams, setSearchParams] = useSearchParams();
  const importSourceCode = `<script type="text/javascript"
  src="https://mintkit.app/sdk.js?k=${id}">
</script>`;
  const domSourceCode = `
    <div id="mintkit-frame"></div>
  `;
  const [bot, setBots] = useState(true);
  const bots = [
    { name: 'Enabled', value: true },
    { name: 'Disabled', value: false },
  ];
  const [projectPhase, setProjectPhase] = useState(validProjectPhaseURL(phase));
  const [linkContractOpen, setLinkContractOpen] = useState(false);
  const [currentContract, setCurrentContract] = useState<null | Contract>(null);
  const [mintQuantity, setMintQuantity] = useState('');
  const [mintPrice, setMintPrice] = useState('');
  const [toggleRegistrationRequirements, setToggleRegistrationRequirements] = useState<ToggleRegistrationRequirements>({
    eth_balance: false,
    twitter: false,
    discord: false,
  });
  const [ethBalance, setEthBalance] = useState('');
  const [phaseFunctions, setPhaseFunctions] = useState({});
  const [twitterMissionId, setTwitterMissionId] = useState('');
  const [discordMissionId, setDiscordMissionId] = useState('');
  const [editingPhase, setEditingPhase] = useState(false);
  // 3 hour bug chase here...on some mounts, component remounts and you lose state set in useEffect...so try initially
  const [currentTags, setCurrentTags] = useState(
    localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id))
      : null
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // zustand actions
  const getContracts = store((s) => s.contractSlice.getContracts);
  const getContractPhaseMappings = store((s) => s.contractSlice.getContractPhaseMappings);
  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const newPhase = store((s) => s.phaseSlice.newPhase);
  const getDiscordMission = store((s) => s.discordMissionSlice.getDiscordMission);
  const addMissionToPhase = store((s) => s.phaseSlice.addMissionToPhase);
  const newEthBalanceMission = store((s) => s.ethMissionSlice.newEthBalanceMission);
  const clearLocalStorage = store((s) => s.phaseSlice.clearLocalStorage);
  const editPhase = store((s) => s.phaseSlice.editPhase);
  const editEthBalanceMission = store((s) => s.ethMissionSlice.editEthBalanceMission);
  const deleteMissionFromPhase = store((s) => s.phaseSlice.deleteMissionFromPhase);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const getTags = store((s) => s.tagSlice.getTags);
  const addTagToPhase = store((s) => s.tagSlice.addTagToPhase);
  const deleteTagsFromPhase = store((s) => s.tagSlice.deleteTagsFromPhase);

  // zustand state
  const contractPhaseMappings = store((s) => s.contractSlice.contractPhaseMappings);
  const errorType = store((s) => s.phaseSlice.errorType);
  const errorMessage = store((s) => s.phaseSlice.errorMessage);
  const guildRoleData = store((s) => s.discordMissionSlice.guildRoleData);
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const projectMissions = store((s) => s.projectSlice.projectMissions);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  // loading
  const phaseLoadingType = store((s) => s.phaseSlice.loadingType);
  const contractLoadingType = store((s) => s.contractSlice.loadingType);
  const discordLoadingType = store((s) => s.discordMissionSlice.loadingType);

  const privateMintPhaseData = projectPhases?.find((phase) => phase.kind === ProjectPhases.PRIVATE_MINT);
  const publicMintPhaseData = projectPhases?.find(
    (phase) => phase.kind === ProjectPhases.PUBLIC_MINT || phase.kind === ProjectPhases.PROTECTED_PUBLIC_MINT
  );

  const selectContract = (projectContract) => {
    setLinkContractOpen(!linkContractOpen);
    setCurrentContract(projectContract);
    localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id, projectContract.id);
  };

  // if user enables bot protection, need to manually change phase (since url wont be accurate)
  const selectBotProtection = (e) => {
    setBots(e);
    if (e) {
      setProjectPhase(ProjectPhases.PROTECTED_PUBLIC_MINT);
    } else {
      setProjectPhase(ProjectPhases.PUBLIC_MINT);
    }
    setPhaseFunctions({ ...phaseFunctions, mint: '' });
    localStorage.setItem(
      LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id,
      JSON.stringify({ ...phaseFunctions, mint: '' })
    );
  };

  // create phase
  const createPhase = async (id) => {
    // create new phase to get phase ID.
    const res = await newPhase(
      id,
      projectPhase,
      currentContract?.id,
      'TODO: name',
      mintPrice,
      phaseFunctions,
      startDate ? startDate.toISOString() : '',
      endDate ? endDate.toISOString() : ''
    );
    let phaseId = res?.data?.data?.id ? res.data.data.id : null;

    if (phaseId) {
      // if private mint, for each mission, add mission to current phase
      if (projectPhase === ProjectPhases.PRIVATE_MINT) {
        if (currentTags) {
          addTagToPhase(phaseId, currentTags);
        }
        if (toggleRegistrationRequirements.eth_balance) {
          let ethMissionId = await newEthBalanceMission(id, ethBalance);
          if (ethMissionId) {
            let r = await addMissionToPhase(phaseId, ethMissionId);
          }
        }
        if (toggleRegistrationRequirements.discord) {
          let r = await addMissionToPhase(
            phaseId,
            localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id)
          );
        }
        if (toggleRegistrationRequirements.twitter) {
          let r = await addMissionToPhase(
            phaseId,
            localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id)
          );
        }
      }
      // clear cache and navigate to project page

      try {
        clearLocalStorage(id);
      } catch (e) {}
      navigate(`/projects/${id}`);
    }
  };

  const edit = async (id) => {
    // create new phase to get phase ID
    let tempId = phase === ProjectPhases.PRIVATE_MINT ? privateMintPhaseData?.id : publicMintPhaseData?.id;
    let phaseData = phase === ProjectPhases.PRIVATE_MINT ? privateMintPhaseData : publicMintPhaseData;

    const res = await editPhase(
      id,
      tempId,
      projectPhase,
      currentContract?.id,
      'TODO: name',
      mintPrice,
      phaseFunctions,
      startDate ? startDate.toISOString() : '',
      endDate ? endDate.toISOString() : ''
    );
    if (res) {
      let phaseId = res.data.data.id ? res.data.data.id : null;

      // check if account previously had mission or not
      let twitterExists = phaseData?.missions.find((mission) => mission?.key === 'twitter');
      let ethExists = phaseData?.missions.find((mission) => mission.key === 'eth_balance');
      let discordExists = phaseData?.missions.find((mission) => mission?.key === 'discord');

      // Eth CASES:
      // Eth_balance previously existed, now being deleted
      // Eth_balance previously existed, value being changed
      // Eth balance didnt exist, now being created
      if (phaseId && projectPhase) {
        // eth mission being deleted
        if (toggleRegistrationRequirements.eth_balance) {
          if (ethExists) {
            let edit = await editEthBalanceMission(ethExists?.id, ethBalance);
          } else {
            let ethMissionId = await newEthBalanceMission(id, ethBalance);
            if (ethMissionId) {
              let r = await addMissionToPhase(phaseId, ethMissionId);
            }
          }
        } else {
          if (ethExists) {
            let r = await deleteMissionFromPhase(phaseId, ethExists?.id);
          }
        }

        // Twitter CASES:
        // twitter previously existed, now being deleted
        // twitter didnt exist before, now being added
        if (toggleRegistrationRequirements.twitter) {
          if (twitterExists) {
            // do nothing: twitter is already connected
          } else {
            let r = await addMissionToPhase(
              phaseId,
              localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id)
            );
          }
        } else {
          if (twitterExists) {
            let r = await deleteMissionFromPhase(phaseId, twitterExists?.id);
          }
        }

        // Discord CASES:
        // discord previously existed, now being deleted
        // discord didnt exist before, now being added
        // discord missions are editied inline, so edit doesnt apply
        if (toggleRegistrationRequirements.discord) {
          if (discordExists) {
            // do nothing: twitter is already connected
          } else {
            let r = await addMissionToPhase(
              phaseId,
              localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id)
            );
          }
        } else {
          if (discordExists) {
            let r = await deleteMissionFromPhase(phaseId, discordExists?.id, true);
          }
        }

        // TAGS
        // no previous tags, user adds all new tags (create ids)
        // user adds an additional tag to current tags (only create changed ids)
        // user deletes a tag and tags still remain (only delete changed ids)
        /// user deletes all tags (remove all ids)
        if (currentTags && currentTags.length > 0) {
          let newTags = currentTags.filter((newTag) => !phaseData?.tags?.some((tag) => tag.id === newTag.id));
          let removedTags = phaseData?.tags.filter((newTag) => !currentTags.some((tag) => tag.id === newTag.id));
          if (newTags && newTags.length > 0) {
            let r = await addTagToPhase(phaseId, newTags);
          }
          if (removedTags && removedTags.length > 0) {
            let r = await deleteTagsFromPhase(phaseId, removedTags);
          }
        } else {
          // previously had tags, deleted them
          if (phaseData?.tags?.length > 0) {
            let r = await deleteTagsFromPhase(phaseId, phaseData.tags);
          }
        }
      }

      // clear cache and navigate to project page
      try {
        clearLocalStorage(id);
      } catch (e) {
        console.log('e');
      }
      navigate(`/projects/${id}`);
    }
  };

  // reload selected contract if returning from redirect
  useEffect(() => {
    if (projectsContracts.length > 0) {
      try {
        let contract = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id);
        let findContract = projectsContracts.find((projectContract) => projectContract.id === contract);
        if (findContract) {
          setCurrentContract(findContract);
        }
      } catch (e) {}
    }
  }, [projectsContracts]);

  // due to redirect, repopulate previously filled out fields
  useEffect(() => {
    const redirectRepopulate = async () => {
      try {
        let editing = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_EDITING_PHASE + id);
        if (editing) {
          setEditingPhase(true);
        }

        let discord = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id);
        let twitter = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id);
        let eth = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_ETHEREUM_MISSION + id);
        let mintP = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_PRICE + id);
        let mintQ = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_QUANTITY + id);
        let phaseFuncs = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id);
        let phaseTags = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id);

        setToggleRegistrationRequirements({
          discord: discord ? true : false,
          twitter: twitter ? true : false,
          eth_balance: eth ? true : false,
        });
        setEthBalance(eth ? eth : '');
        setMintPrice(mintP ? mintP : '');
        setMintQuantity(mintQ ? mintQ : '');
        setPhaseFunctions(phaseFuncs ? JSON.parse(phaseFuncs) : {});
        if (discord) {
          getDiscordMission(discord);
        }
        if (!contractPhaseMappings) {
          getContractPhaseMappings();
        }
        if (twitter) {
          setTwitterMissionId(twitter);
        }
        if (projectsContracts.length < 1) getContracts(id);
        if (projectPhases.length < 1) getPhases(id);

        if (phaseTags) {
          setCurrentTags(JSON.parse(phaseTags));
        }

        if (phase === ProjectPhases.PUBLIC_MINT) {
          setBots(false);
        }

        let startDate = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + id);
        let endDate = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + id);
        startDate && setStartDate(new Date(startDate));
        endDate && setEndDate(new Date(endDate));

        getTags(id);
      } catch (e) {}
    };

    redirectRepopulate();
  }, []);

  const findCurrentTwitterMission = twitterMissionId
    ? projectMissions.find((mission) => mission?.id === twitterMissionId)
    : null;

  const disableIfNoEthBalance =
    phase === ProjectPhases.PRIVATE_MINT && toggleRegistrationRequirements.eth_balance && !ethBalance;

  // current twitter mission exists but they didnt OAuth successfully
  const disableIfTwitterNone =
    phase === ProjectPhases.PRIVATE_MINT &&
    toggleRegistrationRequirements.twitter &&
    (!findCurrentTwitterMission || (findCurrentTwitterMission && findCurrentTwitterMission.ready === false));

  // edge case: have to disable submit button if discord toggled but they havent selected role and guild :/
  // disable if discord options arent selected
  const disableIfDiscordNone =
    phase === ProjectPhases.PRIVATE_MINT &&
    (discordLoadingType !== DiscordMissionLoadingState.NULL ||
      (toggleRegistrationRequirements.discord &&
        (!guildRoleData ||
          (guildRoleData && (!guildRoleData.guild_id || !guildRoleData.role_id || !guildRoleData.invite_link)))));

  // disable create phase button until requirements are met
  const currentContractPhaseMappings =
    contractPhaseMappings && contractPhaseMappings?.filter((func) => func?.phase_kind === projectPhase);

  // user must have completed necessary steps for their configuration.
  const createPhaseButtonDisabled =
    !currentContract ||
    !projectPhase ||
    !allMintFunctionsSelected(phaseFunctions, currentContractPhaseMappings) ||
    disableIfDiscordNone ||
    disableIfTwitterNone ||
    disableIfNoEthBalance;

  return (
    <div className="md:mx-auto max-w-xl">
      <h2 className="text-6xl font-flexa font-bold dark:text-darkText mb-4">{`Create ${
        phase === 'privatemint' ? 'Private' : 'Public'
      } Mint`}</h2>
      <p className="dark:text-darkText">
        Add a Mint Phase to link Mintkit with your contract. Mint phases can be used to help mint NFTs through your
        contract. Use them to add constraints to your mint, such as joining the discord, following on twitter or being a
        part of a community list. They can also be used to guard your mints against bots.
      </p>

      <Section>
        <Label className="!mt-10">
          Price
          <RequiredAsterisk />
        </Label>
        <p className="dark:text-white text-gray-700 mb-4">
          The price for minting during this phase. Enter "0" for free mints. This price is only used in the UI. Your
          contract must enforce the price.
        </p>
        <div className="flex flex-row w-full items-center mb-8">
          <RiGasStationFill size={30} className="mr-2 dark:text-white" />
          <p className="mr-2 dark:text-white">+</p>
          <input
            value={mintPrice}
            name="Price"
            id="Price"
            className="w-full p-2 flex border dark:text-white dark:bg-gray-700 border-grey-100 rounded-md"
            placeholder="ETH"
            onChange={(e) => {
              setMintPrice(e.target.value);
              localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_PRICE + id, e.target.value);
            }}
            required
          />
        </div>

        <Label>
          Link a Contract
          <RequiredAsterisk />
        </Label>
        <p className="dark:text-white text-gray-700">
          Attach a mint phase to the Solidity contract function to execute when a user presses Mint.
        </p>
        {currentContract ? (
          <>
            <div className="flex flex-col dark:text-darkText mb-8">
              <p className="dark:text-darkText mt-4 font-bold underline">Linked Contract:</p>
              <div className="flex flex-row dark:text-darkText">
                <span className="mr-auto">{`${getContractNetwork(currentContract.address)} Contract`}</span>
                <a
                  target="_blank"
                  href={getEtherescanLink(
                    formatEthereumIdentity(currentContract.address),
                    getContractNetwork(currentContract.address)
                  )}
                  className="ml-auto flex flex-row items-center hover:cursor-pointer hover:underline"
                  rel="noreferrer"
                >
                  {/* <span className="ml-auto flex flex-row items-center hover:cursor-pointer hover:underline"> */}
                  {formatWalletAddress(formatEthereumIdentity(currentContract.address))}
                  <TbExternalLink className="text-gray-500 text-gray-400 ml-1 hover:underline" />
                </a>
              </div>
              <SubmitButton
                className="self-start !py-1 !px-2"
                onClick={() => {
                  setCurrentContract(null);
                  localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id);

                  // clear mint functions
                  setPhaseFunctions({});
                  localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id);
                }}
              >
                Unlink
              </SubmitButton>
            </div>
          </>
        ) : (
          <div className="flex flex-row mb-8">
            <div className="relative">
              {projectsContracts.length > 0 && (
                <button
                  onClick={() => setLinkContractOpen(!linkContractOpen)}
                  className="text-xs px-3 py-2 md:px-3 md:py-2 rounded-full font-bold mr-4 text-white bg-[#07074E] dark:bg-darkButton dark:border-none mt-4"
                >
                  Link an Existing Contract
                </button>
              )}
              {linkContractOpen && projectsContracts.length > 0 && (
                <div
                  className="z-10 absolute mt-2 w-80 rounded-md shadow-lg dark:bg-gray-700 dark:border dark:border-white bg-white ring-1 dark:ring-gray-500 ring-black ring-opacity-5 py-1 focus:outline-none transition-all"
                  role="menu"
                  aria-orientation="vertical"
                  aria-labelledby="user-menu-button"
                >
                  <div className="">
                    <p className="font-2xl mt-2 mb-4 px-4 dark:text-white font-flexa">Contracts</p>

                    {projectsContracts.map((projectContract, i) => (
                      <a
                        onClick={() => {
                          selectContract(projectContract);

                          // clear mint functions
                          setPhaseFunctions({});
                          localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id);
                        }}
                        className="cursor-pointer hover:bg-lightHover dark:hover:bg-gray-800 px-4 block py-2 dark:text-white text-sm text-gray-700 transition-all"
                        role="menuitem"
                        id="user-menu-item-0"
                        key={i}
                      >
                        <div className="flex flex-row">
                          <span className="mr-auto"> {`${getContractNetwork(projectContract.address)} Contract`}</span>
                          <span className="ml-auto">
                            {' '}
                            {formatWalletAddress(formatEthereumIdentity(projectContract.address))}
                          </span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </div>
            <button
              className="text-xs px-3 py-2 md:px-3 md:py-2 rounded-full font-bold bg-white text-black dark:border-none border border-1 mt-4"
              onClick={() => navigate(`/projects/${id}/contractSetup/${phase}`)}
            >
              Add New
            </button>
          </div>
        )}

        <Label>Minting Dates</Label>

        <p className="dark:text-white text-gray-700 mb-4">Choose a start and end time for your users to mint</p>
        <DateTimePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />

        {currentContract && projectPhase ? (
          <>
            <div className="mt-12">
              <Label>
                Select compatible Mint functions
                <RequiredAsterisk />
              </Label>
              <InstructionText>
                A contract must have at least one valid mint function to link Mintkit. Check the
                <a
                  href="https://docs.pluto.xyz"
                  className="text-white ml-1 mr-1 whitespace-nowrap bg-gray-700 pb-[2px] px-2 rounded-full"
                >
                  guide
                  <HiExternalLink size={16} className="ml-[1px] pb-[2px] inline" />
                </a>
                to ensure your linked function is correct.
              </InstructionText>
            </div>

            <MintFunctions
              currentContract={currentContract}
              projectPhase={projectPhase}
              phaseFunctions={phaseFunctions}
              setPhaseFunctions={setPhaseFunctions}
            />
          </>
        ) : null}

        {/*  Only show constraits for a private mint: public mint is open */}
        {phase === 'privatemint' && (
          <div className="mt-8">
            {/* <Label>Mint Constraints</Label>
            <p className="dark:text-white text-gray-700 mb-4">
              Constraints are enforced when users attempt to mint on your site through PlutoKit. If you add a
              constraint, it cannot be bypassed and a user must complete it to mint.
            </p> */}
            {/* <SocialIntegration
              toggleRegistrationRequirements={toggleRegistrationRequirements}
              setToggleRegistrationRequirements={setToggleRegistrationRequirements}
              ethBalance={ethBalance}
              setEthBalance={setEthBalance}
            /> */}
            <TagMintGating currentTags={currentTags} setCurrentTags={setCurrentTags} />
          </div>
        )}
        {phase === ProjectPhases.PROTECTED_PUBLIC_MINT || phase === ProjectPhases.PUBLIC_MINT ? (
          <>
            <Label>Bot Protection</Label>
            <p className="dark:text-white text-gray-700 mb-4">
              Bot Guard uses Mintkit's ML model to detect bots using on chain and off chain signals of wallet and user
              quality. We recommend always enabling Bot Guard.
            </p>
            <RadioGroup
              value={bot}
              onChange={(e) => {
                selectBotProtection(e);
              }}
              className="grid grid-cols-2 md:grid-cols-3 gap-10"
            >
              {bots.map((option, i) => (
                <RadioGroup.Option
                  key={i}
                  value={option.value}
                  className={`py-3 px-3 border dark:bg-gray-700 dark:text-white ${
                    bot === option.value ? 'dark:border-white border-black' : 'dark:border-gray-600 border-gray-100'
                  } rounded-md hover:cursor-pointer dark:hover:bg-gray-500 hover:bg-gray-200`}
                >
                  {bot === option.value ? <CheckMark /> : <div className="h-5 w-5 " />}
                  <p className="pt-10">{option.name}</p>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
          </>
        ) : null}
      </Section>
      {/* Error Message */}
      {errorType === PhaseLoadingState.NEW_PHASE && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <div className="flex flex-row md:mt-48 mt-24">
        <StepButton
          onClick={() => {
            // removeQueryParams();

            if (editingPhase) {
              clearLocalStorage(id);
            }
            navigate(-1);
          }}
          className="mr-auto bg-white text-black border border-1"
        >
          Back
        </StepButton>
        <StepButton
          disabled={phaseLoadingType === PhaseLoadingState.NEW_PHASE || createPhaseButtonDisabled}
          onClick={() => {
            // removeQueryParams();
            if (editingPhase) {
              edit(id);
            } else {
              createPhase(id);
            }
          }}
          className={`ml-auto bg-[#07074E] dark:bg-darkButton text-white flex flex-row ${
            createPhaseButtonDisabled && 'opacity-25'
          }`}
        >
          Save {phaseLoadingType === PhaseLoadingState.NEW_PHASE && <Spinner color={'white'} />}
        </StepButton>
      </div>
    </div>
  );
};

export default MintSetup;

const validProjectPhaseURL = (phaseUrl) => {
  // if (phaseUrl)
  if (Object.values(ProjectPhases).includes(phaseUrl)) {
    return phaseUrl;
  } else return null;
};

const allMintFunctionsSelected = (obj1, obj2) => {
  if (!obj1 || !obj2 || !obj2.length) return false;
  let allExist = true;
  for (let i = 0; i < obj2.length; i++) {
    if (!obj1.hasOwnProperty(obj2[i].mapping_key)) allExist = false;
    if (obj1.hasOwnProperty(obj2[i].mapping_key) && obj1[obj2[i].mapping_key] === '') allExist = false;
  }
  // return Object.keys(obj1).every(Object.prototype.hasOwnProperty.bind(obj2))
  return allExist;
};
