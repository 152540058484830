import React, { ReactFragment, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Project } from '../../../../../async/projects/projects';
import ErrorMessage from '../../../../../components/ErrorMessage';
import InputText from '../../../../../components/InputText';
import Modal from '../../../../../components/Modal';
import Spinner from '../../../../../components/Spinner';
import SubmitButton from '../../../../../components/SubmitButton';
import { ProjectLoadingState } from '../../../../../store/projectSlice';
import store from '../../../../../store/store';

interface DeleteProjectModalProps {
  modalOpen: boolean;
  setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentProjectData: Project;
  handleDeleteProject: () => Promise<void>;
}

const DeleteProjectModal = ({
  modalOpen,
  setModalOpen,
  currentProjectData,
  handleDeleteProject,
}: DeleteProjectModalProps) => {
  const { id } = useParams();

  const errorType = store((s) => s.projectSlice.errorType);
  const errorMessage = store((s) => s.projectSlice.errorMessage);
  const loadingType = store((s) => s.projectSlice.loadingType);

  const [deleteProjectText, setDeleteProjectText] = useState('');

  return (
    <Modal open={modalOpen} setOpen={setModalOpen}>
      <>
        <div className="p-3 md:p-0">
          <p className="font-flexa text-4xl dark:text-darkText">Delete Project</p>
          <p className="text-sm mb-2 dark:text-darkSecondaryText mt-1">
            Are you sure you want to delete this project? This cannot be undone.
          </p>
          <p className="font-bold text-sm mb-4 dark:text-darkSecondaryText">
            <span className="dark:text-darkText">{`Project: `}</span>
            {currentProjectData?.name}
          </p>

          <InputText
            type="text"
            placeholder="project name"
            value={deleteProjectText}
            onChange={(e) => setDeleteProjectText(e.target.value)}
          />
          {errorType === ProjectLoadingState.DELETE_PROJECT && <ErrorMessage>{errorMessage}</ErrorMessage>}

          <div className="flex flex-row">
            <SubmitButton
              className={`mt-4 ml-auto ${
                loadingType === ProjectLoadingState.DELETE_PROJECT || deleteProjectText !== currentProjectData?.name
                  ? 'opacity-25'
                  : ''
              }`}
              disabled={
                loadingType === ProjectLoadingState.DELETE_PROJECT || deleteProjectText !== currentProjectData?.name
              }
              onClick={() => handleDeleteProject()}
            >
              Delete {loadingType === ProjectLoadingState.DELETE_PROJECT && <Spinner color={'white'} />}
            </SubmitButton>
          </div>
        </div>
      </>
    </Modal>
  );
};

export default DeleteProjectModal;
