import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import {
  Contract,
  DemoContractResponse,
  EditContractResponse,
  IndexContractResponse,
  NewContractResponse,
  ParseABIResponse,
} from '../async/contracts/contracts';
import { ContractFactory, Contract as EtherjsContract } from 'ethers';
import { Project } from '../async/projects/projects';

export enum ContractLoadingState {
  NULL,
  GET_PROJECT_CONTRACT,
  GET_CONTRACTS,
  UPLOAD_ABI,
  RECHECK_UPLOAD_ABI,
  NEW_CONTRACT,
  EDIT_CONTRACT,
  DEPLOY_DEMO_CONTRACT,
  DELETE_CONTRACT,
}

export type GET_ABI = 'abi' | 'address';

export type DemoContractData = {
  contract: EtherjsContract;
  abi: string;
  public_signer_address: string;
  contract_id: string;
};

export interface ContractSlice {
  loadingType: ContractLoadingState;
  loadingMessage: string;
  errorType: ContractLoadingState;
  errorMessage: string;
  projectsContracts: any;
  currentContract: null | Contract;
  abiFunctions: any;
  successMessage: string;
  contractPhaseMappings: any;
  getContracts: (projectId: string) => Promise<any>;
  // getContracts: () => Promise<void>;
  newContract: (project_id: string, address: string, abi: string) => Promise<any>;
  editContract: (contractId: string, address: string, abi: string) => Promise<any>;
  getContractAbi: (input: string, inputType: GET_ABI) => Promise<void>;
  getContractPhaseMappings: () => Promise<void>;
  NewDemoContract: (signer: any, project: Project) => Promise<DemoContractData | null>;
  deleteContract: (contractId: string) => Promise<boolean | null>;
  resetLoading: () => void;
  resetState: () => void;
}

const initalState = {
  loadingType: ContractLoadingState.NULL,
  loadingMessage: '',
  successMessage: '',
  errorType: ContractLoadingState.NULL,
  errorMessage: '',
  projectsContracts: [],
  currentContract: null,
  abiFunctions: null,
  contractPhaseMappings: null,
};

export const contractSlice: ContractSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  getContracts: async (projectId) => {
    getState().resetLoading();
    try {
      const response = await axios.get(`/projects/${projectId}/contracts`);
      if (response && response.status === 200) {
        setState({ projectsContracts: response.data.data });
        return response.data.data;
      }
    } catch (e) {
      return null;
    }
  },
  getContractAbi: async (input, inputType) => {
    getState().resetLoading();
    try {
      let body;
      // endpoint can receive address or abi
      if (inputType === 'address') {
        body = { address: input };
      } else {
        body = { abi: input };
        setState({ loadingType: ContractLoadingState.UPLOAD_ABI });
      }

      const response = await axios.post<ParseABIResponse>(`/contracts/abi`, body);
      if (response && response.status === 200) {
        setState({ abiFunctions: response.data, loadingType: ContractLoadingState.NULL });
      }
    } catch (e) {
      setState({
        errorType: ContractLoadingState.UPLOAD_ABI,
        errorMessage: e.response?.data?.error,
        loadingType: ContractLoadingState.NULL,
      });
      return null;
    }
  },

  newContract: async (project_id, address, abi) => {
    getState().resetLoading();
    setState({ loadingType: ContractLoadingState.NEW_CONTRACT });
    try {
      const response = await axios.post<NewContractResponse>(`/contracts`, {
        project_id,
        address,
        abi,
      });
      if (response && response.status === 200) {
        setState({ currentContract: response.data.data, loadingType: ContractLoadingState.NULL });
        getState().getContracts(project_id);
        return response.data.data;
      }
    } catch (e) {
      setState({
        errorType: ContractLoadingState.NEW_CONTRACT,
        errorMessage: e?.response?.data?.error ?? 'Add contract failed',
        loadingType: ContractLoadingState.NULL,
      });
      return false;
    }
  },
  editContract: async (contractId, address, abi) => {
    getState().resetLoading();
    setState({ loadingType: ContractLoadingState.EDIT_CONTRACT });
    try {
      const response = await axios.put<EditContractResponse>(`/contracts/${contractId}`, {
        address,
        abi,
      });
      if (response && response.status === 200) {
        setState({ currentContract: response.data.data, loadingType: ContractLoadingState.NULL });
        return response;
      }
    } catch (e) {
      let error = '';
      for (var key in e.response?.data?.errors) {
        error += `${key} ${e.response?.data?.errors[key]}`;
        error += '\n';
      }
      setState({
        errorType: ContractLoadingState.EDIT_CONTRACT,
        errorMessage: error,
        loadingType: ContractLoadingState.NULL,
      });
      return null;
    }
  },
  getContractPhaseMappings: async () => {
    try {
      const response = await axios.get(`/contract_phase_mappings`, {});
      if (response && response.status === 200) {
        setState({ contractPhaseMappings: response.data?.func_map, loadingType: ContractLoadingState.NULL });
      }
    } catch (e) {
      console.debug('e: ', e);
    }
  },
  NewDemoContract: async (signer, project) => {
    getState().resetLoading();
    setState({ loadingType: ContractLoadingState.DEPLOY_DEMO_CONTRACT });
    try {
      const response = await axios.post(`/projects/${project.id}/contracts/demo`, {});
      if (response && response.status === 200) {
        // TODO: type
        const { abi, hex_bytecode, public_signer_address, contract_id } = response.data;

        const factory = new ContractFactory(abi, hex_bytecode, signer);
        const contract = await factory.deploy(project.name, 'DEMO', public_signer_address);

        return { contract, abi, public_signer_address, contract_id };
      }
    } catch (e) {
      setState({
        errorType: ContractLoadingState.DEPLOY_DEMO_CONTRACT,
        errorMessage: e.message,
        loadingType: ContractLoadingState.NULL,
      });
      console.debug('getDemoContract error ', e);
      return null;
    }
  },
  deleteContract: async (contract_id) => {
    getState().resetLoading();
    setState({ loadingType: ContractLoadingState.DELETE_CONTRACT });
    try {
      const response = await axios.delete(`/contracts/${contract_id}`);
      if (response && response.status === 200) {
        return true;
      }
    } catch (e) {
      setState({
        errorType: ContractLoadingState.DELETE_CONTRACT,
        errorMessage: e.response?.data?.error,
        loadingType: ContractLoadingState.NULL,
      });
      console.debug('delete contract error ', e);
      return null;
    }
  },

  resetLoading: () => {
    setState({
      loadingType: ContractLoadingState.NULL,
      loadingMessage: '',
      errorType: ContractLoadingState.NULL,
      errorMessage: '',
      successMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
