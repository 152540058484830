import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import SubmitButton from '../../components/SubmitButton';

const OauthError = () => {
  const navigate = useNavigate();
  return (
    <div className="dark:bg-dark bg-white overflow-x-hidden w-screen h-screen max-w-full text-plutoblue-500 dark:text-darkText min-h-full  px-4 py-16 sm:px-6 sm:py-24 md:grid md:place-items-center lg:px-8">
      <p
        className="fixed top-0 left-0 dark:text-darkText text-plutoblue-500 hidden lg:block m-4 text-4xl hover:cursor-pointer md:px-4 lg:px-28"
        onClick={() => navigate('/projects')}
      >
        Mintkit
      </p>

      <div className="mx-auto max-w-max">
        <main className="sm:flex">
          <p className="text-4xl font-bold tracking-tight  sm:text-5xl">404</p>
          <div className="sm:ml-6">
            <div className="sm:border-l sm:border-gray-200 sm:pl-6">
              <h1 className="text-4xl font-bold tracking-tight  sm:text-5xl">Page not found</h1>
              <p className="mt-1 text-base text-gray-500">Please check the URL in the address bar and try again.</p>
            </div>
            <div className="mt-10 flex space-x-3 sm:border-l sm:border-transparent sm:pl-6">
              <Link to={'/projects'}>
                <SubmitButton>Go back home</SubmitButton>
              </Link>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
};

export default OauthError;
