import React, { useState } from 'react';
import { useConnect } from 'wagmi';
import metamaskImg from '../../../assets/images/metamask.svg';
import walletConnectImg from '../../../assets/images/walletConnect.svg';
import coinbaseImg from '../../../assets/images/coinbaseWalletIcon.svg';
import NoWallet from '../../ConnectWallet/noWallet';

const Wallets = () => {
  const { connect, connectors, error, pendingConnector } = useConnect();

  const [noWallets, setNoWallets] = useState(false);

  // If the user has multiple wallet browser extensions installed,
  // the Coinbase Wallet Chrome Extension will construct a "multiprovider"
  // array at window.ethereum.providers. isMetamask is true if MetaMask
  // is part of window.ethereum.providers or if window.ethereum is set to
  // MetaMask. See https://docs.cloud.coinbase.com/wallet-sdk/docs/injected-provider#multiple-injected-providers
  let isMetamask = !!window.ethereum?.providers?.find((eth) => {
    return eth.isMetaMask;
  });
  isMetamask = isMetamask || window.ethereum?.isMetaMask ? true : false;

  // isInjectedCoinbase is true if part of window.ethereum.providers
  // or set via window.ethereum.
  // This is different from the Coinbase Wallet SDK.
  let isInjectedCoinbase = !!window.ethereum?.providers?.find((eth) => {
    return eth.isCoinbaseWallet;
  });
  isInjectedCoinbase = isInjectedCoinbase || window.ethereum?.isCoinbaseWallet ? true : false;

  const wallets = connectors.filter((wallet) => {
    // filter out injected metamask or coinbase extension wallets,
    // because we explicitly provide MetaMaskConnector and CoinbaseWalletConnector.
    if (wallet.id === 'injected' && (isMetamask || isInjectedCoinbase)) {
      return false;
    }
    return true;
  });

  return (
    <div>
      {!noWallets && (
        <>
          {wallets.map((connector) => (
            // Only show wallet if it's supported
            <div key={connector.id}>
              {connector.ready && (
                <div
                  className="grid grid-cols-4 rounded-md p-2 text-center m-3 dark:border dark:border-gray-600  dark:bg-darkSecondary  dark:text-darkText bg-gray-100  dark:hover:bg-darkHover hover:bg-gray-200 hover:cursor-pointer"
                  onClick={() => {
                    connect({ connector });
                  }}
                >
                  <img src={lookupImage(connector.name)} alt="wallet img" className="h-8" />

                  <button className="col-span-2" disabled={!connector.ready} key={connector.id}>
                    {connector.name}
                    {!connector.ready && ' (unsupported)'}
                    {connector.id === pendingConnector?.id && ' (connecting)'}
                  </button>
                </div>
              )}

              {/* Metamask injected but not supported */}
              {!connector.ready && connector.id === 'metaMask' ? (
                <div
                  className="grid grid-cols-4 rounded-md p-2 text-center m-3 bg-gray-100 hover:bg-gray-200 dark:hover:bg-darkHover"
                  onClick={() => setNoWallets(true)}
                >
                  <img src={metamaskImg} alt="wallet img" className="h-8" />
                  <button className="col-span-2">MetaMask</button>
                </div>
              ) : null}
            </div>
          ))}

          <div
            className="p-2 text-center m-3 dark:border dark:border-gray-600 dark:text-darkText bg-gray-100 dark:hover:bg-darkHover dark:bg-darkSecondary hover:bg-gray-200 rounded-md hover:cursor-pointer"
            onClick={() => setNoWallets(true)}
          >
            <button>I don't have a wallet</button>
          </div>
        </>
      )}
      {noWallets && <NoWallet noWallets={noWallets} setNoWallets={setNoWallets} />}

      {error && <div className="px-2 mx-3 text-center italic text-red-500">{error.message}</div>}
    </div>
  );
};

export { Wallets };

const lookupImage = (walletName: string) => {
  switch (walletName) {
    case 'MetaMask':
      return metamaskImg;
    case 'WalletConnect':
      return walletConnectImg;
    case 'Coinbase Wallet':
      return coinbaseImg;
    default:
      return undefined;
  }
};
