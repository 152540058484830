import React from "react"
import { Switch } from "@headlessui/react"

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ")
}

interface ToggleSwitchProps {
  enabled: boolean
  setEnabled: React.Dispatch<React.SetStateAction<boolean>>
}

const ToggleSwitch = ({ enabled, setEnabled }: ToggleSwitchProps) => {
  return (
    <Switch
      checked={enabled}
      onChange={setEnabled}
      className={classNames(
        enabled ? "dark:bg-plutoorange-500 bg-plutoblue-500 " : "dark:bg-darkSecondary bg-gray-200",
        "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 "
      )}>
      <span className='sr-only'>Use setting</span>
      <span
        aria-hidden='true'
        className={classNames(
          enabled ? "translate-x-5" : "translate-x-0",
          "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
        )}
      />
    </Switch>
  )
}

export default ToggleSwitch
