import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { vs2015 } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { FiCopy } from 'react-icons/fi';
import { BsCheck2Circle } from 'react-icons/bs';
import styles from '../marketing.module.css';
import SolidityComponent from './SolidityComponent';

const HTMLSourceCode = `<!-- Mintkit Integration -->​

<script type="text/javascript" 
    src="https://sdk.mintkit.app/sdk.js?k=project_2GxYBZYUt1zIZgBfkAhoECjaleI">
</script>`;

const solidityCode = `import "pluto/mintkit-contracts/contracts/TokenGated.sol";

contract Example is TokenGated {
  function mint(uint256 quantity, SignedToken token) public payable onlyToken(token) {
    // your mint logic
  }
}`;

const MarketingCodeSnippet = () => {
  const [effect, setEffect] = useState(false);
  const [showHTML, setShowHTML] = useState(true);

  return (
    <div className={`mt-4 shadow-lg rounded-xl bg-opacity-5 bg-white mt-8 overflow-y-scroll ${styles.noScrollbar}`}>
      <div className="pt-4 px-4">
        <div className="border-b border-white border-opacity-20 mb-2">
          <div className="pb-4 flex flex-row w-full">
            <button
              onClick={() => setShowHTML(true)}
              className={`mr-4 px-4 py-1.5 rounded-full transition-all ${
                showHTML
                  ? 'bg-[#34AC80] text-black'
                  : 'hover:text-black bg-white bg-opacity-10 text-white hover:text-black hover:bg-opacity-100 hover:bg-[#34AC80]'
              }`}
            >
              HTML
            </button>

            <button
              onClick={() => setShowHTML(false)}
              className={`px-4 py-1.5 rounded-full transition-all ${
                !showHTML
                  ? 'bg-[#34AC80] text-black'
                  : 'text-white hover:text-black bg-white bg-opacity-10 hover:bg-opacity-100 hover:bg-[#34AC80]'
              }`}
            >
              Solidity
            </button>

            <div className="ml-auto text-center self-center flex flex-row items-center">
              {effect && <span className="ml-auto pr-2 text-sm text-gray-300">Copied</span>}
              <div
                className="text-white hover:text-black hover:cursor-pointer bg-white bg-opacity-10 hover:bg-opacity-100 hover:bg-[#34AC80] p-2.5 rounded-full   transition-all"
                onClick={() => {
                  setEffect(true);
                  setTimeout(() => setEffect(false), 1500);
                  navigator.clipboard.writeText(showHTML ? HTMLSourceCode : solidityCode);
                }}
              >
                {effect ? <BsCheck2Circle size={14} /> : <FiCopy size={14} />}
              </div>
            </div>
          </div>
        </div>
        {showHTML ? (
          <SyntaxHighlighter
            className={`rounded-t-lg rounded-b-lg hide-scrollbar`}
            style={vs2015}
            language={'vbscript-html'}
            showLineNumbers={true}
            wrapLines={false}
            customStyle={{
              background: 'rgba(255,255, 255, 0)',
            }}
            lineNumberStyle={{
              color: 'rgba(255,255, 255, 0.20)',
            }}
          >
            {HTMLSourceCode}
          </SyntaxHighlighter>
        ) : (
          <SolidityComponent />
        )}
      </div>
    </div>
  );
};

export default MarketingCodeSnippet;
