import React from 'react';
import { useParams } from 'react-router-dom';
import Button from '../../../../components/Button';
import SubmitButton from '../../../../components/SubmitButton';
import store from '../../../../store/store';

const Pagination = () => {
  const { id } = useParams();
  const getUsers = store((s) => s.userSlice.getUsers);
  const projectUsers = store((s) => s.userSlice.projectUsers);
  const showNextButton = store((s) => s.userSlice.showNextButton);
  const showPreviousButton = store((s) => s.userSlice.showPreviousButton);

  const getNext25 = () => {
    const headers = { ['X-After']: showNextButton };
    getUsers(id, headers);
  };
  const getPrevious25 = () => {
    const headers = { ['X-Before']: showPreviousButton };
    getUsers(id, headers);
  };

  return (
    <div className="flex flex-row gap-4 w-full">
      {showPreviousButton && (
        <Button
          text={'Previous'}
          additionalStyle={'text-white text-xs !px-2 !py-1 mr-auto'}
          onClick={() => getPrevious25()}
        />
      )}
      {showNextButton && (
        <Button text={'Next'} additionalStyle={' text-white text-xs !px-2 !py-1 ml-auto'} onClick={() => getNext25()} />
      )}
    </div>
  );
};

export default Pagination;
