import React, { forwardRef, useCallback } from "react"
import { useDropzone } from "react-dropzone"

interface FileDragDropProps {
  uploadInstructionText: string

  fileTypeDescriptionText?: string

  label?: string

  file: any

  setFile: any

  acceptedFileTypes: "image" | "csv" | "json"
}

/**
 * Insert text at cursor position.
 *
 * @param {string} file - The file being uploaded
 * @param {React.SetState<fileType>} setFile - The state to hold the uploaded file
 * @param {"image" | "csv" | "json"} acceptedFileTypes - Accepted file types - either images "image" or CSV file "csv"
 * @param {string} label - Text label above the drag and drop area
 * @param {string} uploadInstructionText - Instructions telling the user what they should do - ex: "Drag n drop files here to upload"
 * @param {string} fileTypeDescriptionText - Instructions for the file type - ex: "PNG, JPG, and JPEG up to 10MB"
 */
export const FileDragDrop = forwardRef(
  (
    {
      label,
      fileTypeDescriptionText,
      file,
      setFile,
      uploadInstructionText,
      acceptedFileTypes,
    }: FileDragDropProps,
    ref: any
  ) => {
    const onDrop = useCallback(
      (acceptedFiles: any) => {
        const input = acceptedFiles ? acceptedFiles[0] : null

        try {
          if (input && acceptedFileTypes === "image") {
            getBase64(input, (result) => {
              if (result) {
                setFile(result)
              }
            })
          } else {
            setFile(input?.name ? input.name : null)
          }
        } catch {
          console.log("Image upload error")
        }
      },
      [acceptedFileTypes, setFile]
    )

    let acceptedTypes = Object()
    switch (acceptedFileTypes) {
      case "csv":
        acceptedTypes["text/csv"] = [".csv"]
        break
      case "json":
        acceptedTypes["application/json"] = [".json"]
        break
      case "image":
        acceptedTypes["image/*"] = [".jpeg", ".png", ".jpg"]
        break
    }

    const { getRootProps, isDragActive } = useDropzone({
      onDrop,

      // https://react-dropzone.org/#!/Accepting%20specific%20file%20types
      // NOTE FROM DOCS: filetype not always reliable: "Mime type determination is not reliable across platforms. CSV files, for example, are reported as text/plain under macOS but as application/vnd.ms-excel under Windows. In some cases there might not be a mime type set at all."
      accept: acceptedTypes,
    })

    return (
      <div {...getRootProps()} className='dark:bg-darkSecondary bg-gray-100'>
        <label htmlFor={label} className='block text-sm font-medium text-gray-700 mb-1'>
          {label}
        </label>
        <div
          className='relative h-52 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md hover:cursor-pointer'
          onClick={() => {
            ref?.current?.click()
          }}>
          {file ? (
            acceptedFileTypes === "image" ? (
              <img
                src={file}
                alt=''
                className='h-full w-full object-contain absolute top-0 left-0 -z-1'
              />
            ) : (
              <p className='dark:text-darkText text-gray-600'>{file}</p>
            )
          ) : (
            <div className='text-center my-auto'>
              <FileUploadSvg />
              <div className='flex text-sm text-gray-600 text-center'>
                <label
                  htmlFor='file-upload'
                  className='relative  rounded-md font-medium text-center'>
                  <input type='file' className='hidden' />
                </label>
              </div>
              {isDragActive ? (
                <p className='text-gray-500'>Drop file here</p>
              ) : (
                <p className='text-gray-500'>
                  {uploadInstructionText ? uploadInstructionText : "Upload a File"}
                </p>
              )}
              <p className='text-xs text-gray-500'>
                {fileTypeDescriptionText ? fileTypeDescriptionText : "PNG, JPG, JPEG up to 10MB"}
              </p>
            </div>
          )}
          {/* <input
            type='file'
            onChange={(e) => handleChange(e)}
            ref={ref}
            style={{ display: "none" }}
            accept='.csv'
            className='dark:text-darkText'
            {...getInputProps()}
          /> */}
        </div>
      </div>
    )
  }
)

const FileUploadSvg = () => {
  return (
    <svg
      className='mx-auto h-12 w-12 text-gray-400'
      stroke='currentColor'
      fill='none'
      viewBox='0 0 48 48'
      aria-hidden='true'>
      <path
        d='M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02'
        strokeWidth={2}
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}

export default FileDragDrop

const getBase64 = (
  file: Blob,
  cb: {
    (result: any): void
    (result: any): void
    (arg0: string | ArrayBuffer | null): void
  }
) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = function () {
    cb(reader.result)
  }
  reader.onerror = function (error) {
    console.log("Error: ", error)
  }
}
