import tw from 'twin.macro';
import styles from './ProjectImage.module.css';

const ProjectImage = ({ src, containerClassname = '', imageClassname = '', onClick = () => {} }) => {
  return (
    <div className={`h-48 w-48 ${containerClassname} ${styles.container}`} onClick={onClick}>
      <img src={src} className={`${imageClassname} ${styles.blur}`} />
      <img src={src} className={`${imageClassname} ${styles.image}`} />
    </div>
  );
};

export default ProjectImage;
