import React, { useEffect } from 'react';
import './index.css';
import { createStorage, createClient, WagmiConfig } from 'wagmi';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { getDefaultProvider } from 'ethers';
import { BrowserRouter, Routes, Route, useNavigate } from 'react-router-dom';
import ApplicationLayout, { LayoutType } from './layouts/Application';
import Projects from './views/projects';
import NewCommunity from './views/projects/new';
import ConfigureRegistration from './views/projects/configureregistration';
import NewCommunityUser from './views/projects/newuser';
import SdkSetup from './views/projects/sdkSetup';
import ContractSetup from './views/projects/contractSetup';
import MintSetup from './views/projects/mintSetup';
import ProjectId from './views/projects/id';
import Marketing from './views/marketing';
import { DarkModeProvider } from './hooks/hooks';
import WalletModal from './components/WalletModal';
import store from './store/store';
import WalletConnectPage from './views/wallet';
import ScrollToTop from './components/ScrollToTop';
import ProjectHeader from './layouts/Project/Header';
import ManageIntegration from './views/projects/components/ManageIntegration';
import Community from './views/projects/components/CommunityUsers';
import EditProject from './views/projects/components/EditProject';
import OauthError from './views/projects/OauthError';

const client = createClient({
  autoConnect: true,
  provider: getDefaultProvider(),
  connectors: [
    new InjectedConnector(),
    new MetaMaskConnector(),
    new WalletConnectConnector({ options: { qrcode: true } }),
  ],
  storage: createStorage({ storage: window.localStorage }),
});

const App = () => {
  const fetchIsUserSignedIn = store((s) => s.accountSlice.fetchIsUserSignedIn);

  // get initial account and projects information if it exists
  useEffect(() => {
    fetchIsUserSignedIn();
  }, []);

  return (
    <DarkModeProvider>
      <WagmiConfig client={client}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Marketing />} />
            <Route path="/connect" element={<WalletConnectPage />} />
            <Route path="/oauth_error" element={<OauthError />} />
            <Route
              element={<ApplicationLayout pageLayout={LayoutType.Default} headerBgStyle="dark:bg-dark bg-white" />}
            >
              <Route path="/projects" element={<Projects />} />
              <Route path="/projects/new" element={<NewCommunity />} />
              <Route path="/projects/:id/edit" element={<ConfigureRegistration />} />
              <Route path="/projects/:id/users/new" element={<NewCommunityUser />} />
              <Route path="/projects/:id/setup/:phase" element={<SdkSetup />} />
              <Route path="/projects/:id/contractSetup/:phase" element={<ContractSetup />} />
              <Route path="/projects/:id/mintSetup/:phase" element={<MintSetup />} />
              <Route path="*" element={<OauthError />} />
            </Route>
            <Route
              element={
                <ApplicationLayout pageLayout={LayoutType.None} headerBgStyle="dark:bg-darkSecondary bg-gray-100" />
              }
            >
              <Route path="projects" element={<ProjectHeader />}>
                <Route path=":id" element={<ProjectId />} />
                <Route path=":id/users" element={<Community />} />
                <Route path=":id/integration" element={<ManageIntegration />} />
                <Route path=":id/settings" element={<EditProject />} />
              </Route>
            </Route>
          </Routes>
          <WalletModal />
        </BrowserRouter>
      </WagmiConfig>
    </DarkModeProvider>
  );
};

export default App;
