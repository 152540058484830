import React, { useEffect, useState } from 'react';
import DiscordIntegration from './missions/DiscordIntegration';
import TwitterIntegration from './missions/TwitterIntegration';
import WalletIntegration from './missions/WalletIntegration';
import CopyApi from './missions/copyapi';

import { TbExternalLink } from 'react-icons/tb';

import tw from 'twin.macro';
import {
  formatEthereumIdentity,
  formatWalletAddress,
  getContractNetwork,
  getEtherescanLink,
} from '../../../utils/helper';
import store from '../../../store/store';
import { useNavigate, useParams } from 'react-router-dom';
import { ContractLoadingState } from '../../../store/contractSlice';
import Spinner from '../../../components/Spinner';
import Modal from '../../../components/Modal';
import SubmitButton from '../../../components/SubmitButton';
import InputText from '../../../components/InputText';
import TextAreaInput from '../../../components/TextAreaInput';
import { NetworkDbName, NetworkTypes } from '../../../types/shared';
import { RadioGroup } from '@headlessui/react';
import { CheckMark } from './contract/CheckMark';
import ErrorMessage from '../../../components/ErrorMessage';
import EtherscanLink from '../../../components/EtherscanLink';

const PrimaryButton = tw.button`text-xs text-white font-bold px-4 py-2 md:px-8 md:py-3 rounded-full  bg-[#07074E] dark:bg-darkButton`;
const SecondaryButton = tw.button`text-xs text-plutoblue-500 font-bold px-4 py-2 md:px-8 md:py-3 border dark:border dark:text-white dark:bg-transparent bg-white  rounded-full text-center`;
const Label = tw.label`mt-4 dark:text-white font-flexa font-bold text-black`;

const ManageIntegration = () => {
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const getProject = store((s) => s.projectSlice.getProject);
  const newContract = store((s) => s.contractSlice.newContract);
  const loadingType = store((s) => s.contractSlice.loadingType);
  const deleteContract = store((s) => s.contractSlice.deleteContract);
  const getContracts = store((s) => s.contractSlice.getContracts);
  const errorMessage = store((s) => s.contractSlice.errorMessage);
  const errorType = store((s) => s.contractSlice.errorType);

  const [showModal, setShowModal] = useState(false);
  const [contractDeleteText, setContractDeleteText] = useState('');
  const [contractToDelete, setContractToDelete] = useState(null);
  const [contractAddress, setContractAddress] = useState('');
  const [jsonAbi, setJsonAbi] = useState('');
  const [network, setNetwork] = useState(NetworkDbName.ETHEREUM);
  const networks = [
    { name: NetworkTypes.MAINNET, value: NetworkDbName.ETHEREUM },
    { name: NetworkTypes.GOERLI, value: NetworkDbName.GOERLI },
  ];
  const [addContractSuccess, setAddContractSuccess] = useState('');

  const { id } = useParams();

  const currentPhase = projectPhases?.find((phase) => phase.id === currentProjectData?.phaseId);

  const tryDeleteContract = async () => {
    const res = await deleteContract(contractToDelete.id);
    if (res) {
      setContractToDelete(null);
      setShowModal(false);
      getContracts(id);
      setContractDeleteText('');
    }
  };

  const creteContract = async () => {
    const res = await newContract(id, `${network}://${contractAddress}`, jsonAbi);
    if (res) {
      setContractAddress('');
      setJsonAbi('');
      setNetwork(NetworkDbName.ETHEREUM);
      setAddContractSuccess('Contract successfully created!');
      setTimeout(() => {
        setAddContractSuccess('');
      }, 10000);
    }
  };

  useEffect(() => {
    if (!currentProjectData) getProject(id);
    if (!projectPhases.length) getPhases(id);
  }, []);

  return (
    <>
      {showModal && contractToDelete && (
        <Modal open={showModal} setOpen={setShowModal}>
          <div>
            <p className="font-flexa text-4xl dark:text-darkText">{`Delete Contract`}</p>
            <p className="text-sm mb-4 mt-4 dark:text-darkSecondaryText">
              {`Are you sure you want to delete this contract? This cannot be undone.`}
            </p>
            <p className="underline dark:text-darkText font-bold">Contract:</p>
            <EtherscanLink
              href={getEtherescanLink(
                formatEthereumIdentity(contractToDelete.address),
                getContractNetwork(contractToDelete.address)
              )}
              address={formatEthereumIdentity(contractToDelete.address)}
              className="mt-1  text-xs"
            />
            <p className="text-sm mb-4 mt-4 dark:text-darkText">{`Type "DELETE CONTRACT" in the box below to confirm`}</p>

            <InputText
              type="text"
              placeholder="DELETE CONTRACT"
              value={contractDeleteText}
              onChange={(e) => setContractDeleteText(e.target.value)}
            />
            {errorType === ContractLoadingState.DELETE_CONTRACT && <ErrorMessage>{errorMessage}</ErrorMessage>}

            <div className="flex flex-row">
              <SubmitButton
                className={`mt-4 ml-auto ${contractDeleteText !== 'DELETE CONTRACT' && 'opacity-25'}`}
                disabled={
                  loadingType === ContractLoadingState.DELETE_CONTRACT || contractDeleteText !== 'DELETE CONTRACT'
                }
                onClick={() => tryDeleteContract()}
              >
                Delete Contract{loadingType === ContractLoadingState.DELETE_CONTRACT && <Spinner color={'white'} />}
              </SubmitButton>
            </div>
          </div>
        </Modal>
      )}
      <div>
        <h1 className="font-flexa font-bold dark:text-darkText text-3xl mt-8 mb-4">Mintkit Integrations</h1>
        <p className="dark:text-darkSecondaryText mb-8">
          The list of Mintkit integrations created for this project. Manage your API Keys and hosting endpoints. Each
          separate endpoint requires a separate API Key.
        </p>

        <div className="overflow-x-scroll overflow-y-hidden lg:overflow-hidden py-4">
          <table>
            <thead className="border-b border-gray-500 ">
              <tr className="text-left">
                {['API key', 'Hosting Page', 'Status', 'Phase'].map((header) => (
                  <th key={header} className="px-4 mx-16 dark:text-darkText text-md font-flexa font-bold">
                    {header}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="py-4">
                <td className="px-4 dark:text-darkText text-sm">{currentProjectData?.id}</td>
                <td className="px-4 dark:text-darkText text-sm">
                  {currentProjectData?.domains.length > 0 && `${currentProjectData?.domains[0]}`}
                </td>
                <td className="px-4 dark:text-darkText text-sm">
                  {currentProjectData?.componentsLastSeenAt ? (
                    <div className="dark:text-darkSecondaryText -mt-3">
                      <div className="relative">
                        <span className="absolute animate-ping top-1 bg-green-100 h-4 w-4 rounded-full flex items-center justify-center" />
                        <span className="absolute top-2 left-1 l-2 h-2 w-2 bg-green-400 rounded-full flex items-center justify-center" />
                      </div>
                      <p className="text-gray-400 ml-8 mt-3">Mintkit Connected</p>
                    </div>
                  ) : (
                    <div className="dark:text-darkSecondaryText -mt-3">
                      <div className="relative">
                        <span className="absolute animate-ping top-1 bg-red-100 h-4 w-4 rounded-full flex items-center justify-center" />
                        <span className="absolute top-2 left-1 l-2 h-2 w-2 bg-red-400 rounded-full flex items-center justify-center" />
                      </div>
                      <p className="text-gray-400 ml-8 mt-3">Waiting for Connection - Not Connected</p>
                    </div>
                  )}
                </td>
                <td className="px-4 dark:text-darkText text-sm">{currentPhase?.kind || 'none'}</td>
              </tr>
            </tbody>
          </table>
        </div>

        <h1 className="font-flexa font-bold dark:text-darkText text-3xl mt-12 mb-4">Linked Contracts</h1>

        <div className="grid grid-cols-1 xl:grid-cols-3 gap-4">
          {projectsContracts?.map((projectContract, i) => (
            <div key={projectContract.id} className=" pb-8 px-8 dark:bg-slate-800 bg-gray-100 rounded-xl">
              <div className="flex flex-col  justify-between h-full">
                <div className="flex flex-row items-center  mt-4 w-full">
                  <h2 className="mr-4 dark:text-white font-flexa font-bold text-xl">Contract</h2>
                  <EtherscanLink
                    href={getEtherescanLink(
                      formatEthereumIdentity(projectContract.address),
                      getContractNetwork(projectContract.address)
                    )}
                    address={formatWalletAddress(formatEthereumIdentity(projectContract.address))}
                    className="mt-1"
                  />
                  <p
                    className={`${
                      getContractNetwork(projectContract.address) === 'Mainnet' ? 'bg-[#33AEA8]' : 'bg-[#2B89EC]'
                    } px-2 ml-auto rounded-full my-1 text-white text-xs `}
                  >
                    {getContractNetwork(projectContract.address)}
                  </p>
                </div>

                <div className="mt-4">
                  <div className="border border-1 py-2 px-2 rounded-xl border-gray-500">
                    <div className="flex flex-row py-1">
                      <div className="flex flex-col mx-auto border-r border-gray-500 border-1 pr-6 ">
                        <span className="dark:text-white text-xs font-bold font-flexa">Network</span>
                        <span className="dark:text-white text-xs mt-1">
                          {`${getContractNetwork(projectContract.address)}`}
                        </span>
                      </div>
                      <div className="flex flex-col ml-2 mx-auto  border-1 pr-6 overflow-hidden">
                        <span className="dark:text-white text-xs font-bold font-flexa">Linked Functions</span>
                        {projectPhases
                          .filter((phase) => phase.contract_id === projectContract.id)
                          .map((phase) => (
                            <div key={`${phase.id}`}>
                              <p className="dark:text-darkText underline text-sm mb-1">{phase.kind}</p>
                              {Object.keys(phase.contract_func_map).map((keyName, i) => (
                                <p
                                  className="text-[0.75rem] dark:text-darkText "
                                  key={`${phase.id}${keyName}${phase.contract_func_map[keyName]}`}
                                >
                                  <span>{keyName}:</span>{' '}
                                  <span className="dark:text-darkSecondaryText italic">
                                    {phase.contract_func_map[keyName]}
                                  </span>
                                </p>
                              ))}
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="flex flex-row mt-16 xl:mt-auto pt-8">
                  <SecondaryButton
                    onClick={() => {
                      setShowModal(true);
                      setContractToDelete(projectContract);
                    }}
                    className="mr-auto"
                  >
                    Delete
                  </SecondaryButton>
                </div>
              </div>
            </div>
          ))}
          <div className="border border-gray-500 dark:text-darkText px-4 md:px-8 pt-4 pb-8  flex flex-col rounded-lg">
            <h2 className="font-flexa font-bold dark:text-darkText text-xl">Add a Contract</h2>
            <p className="dark:text-darkSecondaryText text-md mt-2">
              Additional contracts can be linked for demo mode, testnet or mainnet.
            </p>
            <Label>Network</Label>
            <RadioGroup
              value={network}
              onChange={(e) => {
                setNetwork(e);
              }}
              className="grid grid-cols-2 gap-4"
            >
              {networks.map((option, i) => (
                <RadioGroup.Option
                  key={option.name}
                  value={option.value}
                  className={`py-1 px-2 border dark:bg-darkSecondary dark:text-white ${
                    network === option.value ? 'dark:border-white border-black' : 'dark:border-gray-600 border-gray-100'
                  } rounded-md hover:cursor-pointer dark:hover:bg-gray-500 hover:bg-lightHover`}
                >
                  <div className="flex justify-end">
                    {network === option.value ? <CheckMark /> : <div className="h-5 w-5 " />}
                  </div>

                  <p className="pt-1">{option.name}</p>
                </RadioGroup.Option>
              ))}
            </RadioGroup>
            <Label>Contract Address</Label>
            <InputText
              type="text"
              placeholder="0xE5af...6956"
              value={contractAddress}
              onChange={(e) => setContractAddress(e.target.value)}
            />

            <Label>Upload Contract ABI</Label>
            <TextAreaInput
              value={jsonAbi}
              rows={4}
              placeholder="paste ABI here"
              onChange={(e) => setJsonAbi(e.target.value)}
            />
            {errorType === ContractLoadingState.NEW_CONTRACT && <ErrorMessage>{errorMessage}</ErrorMessage>}
            <div className="flex flex-row">
              <PrimaryButton
                className={`mt-16 ml-auto flex flex-row items-center bg-[#07074E] dark:bg-darkButton text-white ${
                  loadingType === ContractLoadingState.NEW_CONTRACT && 'opacity-25'
                }`}
                onClick={() => creteContract()}
              >
                Add Contract {loadingType === ContractLoadingState.NEW_CONTRACT && <Spinner color={'white'} />}
              </PrimaryButton>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageIntegration;
