import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { usePrevious } from 'react-use';
import tw from 'twin.macro';
import ErrorMessage from '../../../../components/ErrorMessage';
import InputText from '../../../../components/InputText';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import Spinner from '../../../../components/Spinner';
import SubmitButton from '../../../../components/SubmitButton';
import SuccessMessage from '../../../../components/SuccessMessage';
import TextInput from '../../../../components/TextInput';
import { DiscordMissionLoadingState } from '../../../../store/discordMissionSlice';
import store from '../../../../store/store';
import { LOCAL_STORAGE_TYPES } from '../../../../types/shared';
import DiscordAuth from './DiscordAuth';

const Section = tw.div`my-2`;
const Label = tw.p`mt-4 mb-2`;
const Select = tw.select`dark:bg-darkSecondary mt-1 block pl-3 pr-10 py-2 dark:text-white text-base border-gray-300 sm:text-sm rounded-md border shadow-sm`;

interface DiscordIntegrationProps {
  inline?: boolean;
}

const DiscordIntegration = ({ inline = false }: DiscordIntegrationProps) => {
  const { id } = useParams();
  const guildRoleData = store((s) => s.discordMissionSlice.guildRoleData);
  const editDiscordMission = store((s) => s.discordMissionSlice.editDiscordMission);
  const loadingType = store((s) => s.discordMissionSlice.loadingType);
  const errorType = store((s) => s.discordMissionSlice.errorType);
  const errorMessage = store((s) => s.discordMissionSlice.errorMessage);
  const [discordMissionId, setDiscordMissionId] = useState(null);
  const [discordInviteLink, setDiscordInviteLink] = useState('');

  const [guildID, setGuildID] = useState(guildRoleData ? guildRoleData.guild_id : '');
  const [roleID, setRoleID] = useState(guildRoleData ? guildRoleData.role_id : '');
  const [updateDiscordSuccess, setUpdateDiscordSuccess] = useState('');
  const previousGuildID = usePrevious(guildID);

  // if discord guild selected, select that guilds roles
  const discordGuildRoles = guildRoleData?.guilds?.find((g) => g.id === guildID);

  useEffect(() => {
    try {
      let discord = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id);
      if (discord) {
        setDiscordMissionId(discord);
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (guildRoleData && !discordInviteLink) {
      if (guildRoleData.invite_link) {
        setDiscordInviteLink(guildRoleData.invite_link);
      } else if (discordGuildRoles && discordGuildRoles?.invite_links?.length) {
        setDiscordInviteLink(discordGuildRoles.invite_links[0]);
      }
    }

    if (guildRoleData && guildRoleData.guild_id && !guildID) {
      setGuildID(guildRoleData.guild_id);
    }
    if (guildRoleData && guildRoleData.role_id && !roleID) {
      setRoleID(guildRoleData.role_id);
    }
  }, [guildRoleData, discordGuildRoles]);

  const updateDiscordInformation = async () => {
    if (discordMissionId) {
      const r = await editDiscordMission(
        discordMissionId,
        guildID,
        DiscordMissionLoadingState.EDIT_DISCORD_MISSION,
        roleID,
        discordInviteLink
      );
      if (r) {
        setUpdateDiscordSuccess('Save successful');
      }
      setTimeout(() => {
        setUpdateDiscordSuccess('');
      }, 5000);
    }
  };

  return (
    <div className="dark:text-darkText mb-2 mr-4">
      <div className="rounded-lg p-2 md:p-0">
        {!inline && (
          <Section className="">
            <div className="relative flex">
              <div className="mt-1">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="focus:ring-plutoorange-500 h-4 w-4 text-plutoorange-500 bg-plutoorange-500 border-gray-300 rounded accent-plutoblue-500 dark:accent-plutoorange-500"
                />
              </div>
              <div className="ml-3 text-sm">
                <label htmlFor="comments" className="text-lg dark:text-darkText">
                  Discord
                </label>
                <p id="comments-description" className="text-gray-500">
                  Require someone to verify themselves on Discord before joining your list.
                </p>
              </div>
            </div>

            <hr className="mt-6 mb-4" />
          </Section>
        )}
        <DiscordAuth />
        <>
          {guildRoleData && guildRoleData.guilds.length > 0 ? (
            <>
              <div className="mt-4 p-2 md:p-0">
                <p className="dark:text-darkSecondaryText italic my-2">
                  Select your discord server, role, and invite link. All 3 fields are required for discord integration.
                </p>
                <label>
                  Select Server
                  <RequiredAsterisk />
                </label>
                <div className="flex flex-row items-center">
                  {' '}
                  <Select
                    value={guildID}
                    onChange={(e) => {
                      setGuildID(e.target.value);
                      setRoleID('');
                      setDiscordInviteLink('');
                    }}
                  >
                    <option key={0} value={''}></option>
                    {guildRoleData.guilds.map((option, i) => (
                      <option key={i + 1} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                  </Select>
                  {/* {loadingType === DiscordMissionLoadingState.GUILD_LOADING && <Spinner color="white" />} */}
                </div>

                {discordGuildRoles && discordGuildRoles?.roles?.length > 0 && (
                  <div className="mt-2">
                    <label>
                      Select Role
                      <RequiredAsterisk />
                    </label>
                    <div className="flex flex-row items-center">
                      <Select value={roleID} onChange={(e) => setRoleID(e.target.value)}>
                        <option key={0} value={''}></option>
                        {discordGuildRoles.roles.map((option, i) => (
                          <option key={i + 1} value={option.id}>
                            {option.name}
                          </option>
                        ))}
                      </Select>{' '}
                      {/* {loadingType === DiscordMissionLoadingState.ROLE_LOADING && <Spinner color="white" />} */}
                    </div>
                  </div>
                )}
                <div className="mt-2 md:w-[50%]">
                  <label>
                    Invite Link
                    <RequiredAsterisk />
                  </label>
                  <div className="flex flex-row items-center">
                    <InputText
                      disabled={loadingType === DiscordMissionLoadingState.EDIT_DISCORD_MISSION}
                      type="text"
                      placeholder="https://discord.gg/..."
                      value={discordInviteLink}
                      onChange={(e) => setDiscordInviteLink(e.target.value)}
                      className="w-2/4"
                    />
                    {/* {loadingType === DiscordMissionLoadingState.INVITE_LINK_LOADING && <Spinner color="white" />} */}
                  </div>
                  {discordGuildRoles?.invite_links?.length > 0 ? (
                    <>
                      <p className="text-xs dark:text-darkText italic">Suggestions</p>
                      {discordGuildRoles.invite_links.map((inviteLink) => (
                        <p key={inviteLink} className="text-xs dark:text-darkSecondaryText italic">
                          {inviteLink}
                        </p>
                      ))}
                    </>
                  ) : null}
                </div>
              </div>
              <SubmitButton
                onClick={() => updateDiscordInformation()}
                className="!px-2 !py-1 flex flex-row items-center mt-2"
              >
                Save {loadingType === DiscordMissionLoadingState.EDIT_DISCORD_MISSION && <Spinner color="white" />}
              </SubmitButton>
              {updateDiscordSuccess && <SuccessMessage>{updateDiscordSuccess}</SuccessMessage>}
              {errorType === DiscordMissionLoadingState.EDIT_DISCORD_MISSION && (
                <ErrorMessage>{errorMessage}</ErrorMessage>
              )}
            </>
          ) : null}
        </>
      </div>
    </div>
  );
};

export default DiscordIntegration;
