import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ConnectWallet from '../../components/ConnectWallet'; //'../../../components/ConnectWallet/ConnectWallet/index';
import { DarkModeContext, useDarkMode } from '../../hooks/hooks';
import { MdOutlineLightMode, MdOutlineNightlight } from 'react-icons/md';

interface HeaderProps {
  setSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>;
  headerBgStyle: String;
}

const Header = ({ setSidebarOpen, headerBgStyle }: HeaderProps) => {
  const navigate = useNavigate();
  const [userMenuOpen, setUserMenuOpen] = useState(false);

  const [walletOpen, setWalletOpen] = useState<boolean>(false);

  const darkModeContext = useContext(DarkModeContext);

  const toggleDarkMode = (checked: boolean | ((prevState: boolean) => boolean)) => {
    darkModeContext.setDarkMode(checked);
  };

  return (
    <div className={headerBgStyle + ' sticky top-0 z-20 h-16 w-full flex md:px-4 lg:px-28'}>
      {/* Open Sidebar Button  */}
      <button
        type="button"
        className=" px-4 dark:text-darkText text-gray-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <svg
          className="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="2"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h7" />
        </svg>
      </button>

      <p
        className="dark:text-darkText text-plutoblue-500 hidden lg:block m-4 text-4xl hover:cursor-pointer"
        onClick={() => navigate('/projects')}
      >
        Mintkit
      </p>

      {/* <!-- Top Right Profile dropdown --> */}
      <div className="flex px-4 md:px-0 w-full justify-end">
        <div className="flex items-center ">
          <div className="ml-3 relative">
            <div className="flex flex-row items-center space-x-2">
              {darkModeContext.darkMode ? (
                <MdOutlineNightlight
                  className="dark:text-darkText text-black   h-6 w-6 hover:cursor-pointer transition-all hover:scale-[102.5%]"
                  aria-hidden="true"
                  onClick={() => toggleDarkMode(false)}
                />
              ) : (
                <MdOutlineLightMode
                  className="dark:text-darkText text-black    h-6 w-6 hover:cursor-pointer transition-all hover:scale-[102.5%]"
                  aria-hidden="true"
                  onClick={() => toggleDarkMode(true)}
                />
              )}

              <ConnectWallet />
              {/* <button
                type="button"
                className="max-w-xs flex items-center text-sm rounded-full "
                id="user-menu-button"
                aria-expanded="false"
                aria-haspopup="true"
                onClick={() => setWalletOpen(!walletOpen)}
              >
                <span className="sr-only">Open user menu</span>
                Connect
               
              </button> */}
            </div>

            {/* Top Right Profile Menu  */}
            {userMenuOpen && (
              <div
                className="origin-top-right absolute right-0 mt-2 w-56 rounded-md dark:border dark:border-gray-600 shadow-lg dark:bg-darkSecondary dark:text-darkText bg-white ring-1 ring-black ring-opacity-5 py-1 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
              >
                {/* <!-- Active: "bg-gray-100", Not Active: "" --> */}
                <a
                  href="/"
                  className="block py-2 px-4 text-sm dark:text-darkText text-gray-700"
                  role="menuitem"
                  id="user-menu-item-0"
                >
                  Your Profile
                </a>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;
