import { Switch } from '@headlessui/react';
import React, { useEffect, useState } from 'react';
import Button from '../../components/Button';
import FileDragDrop from '../../components/FileDragDrop';
import SocialIntegration from './components/socialintegration';
import tw from 'twin.macro';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SecondaryButton from '../../components/SecondaryButton';
import store from '../../store/store';
import SubmitButton from '../../components/SubmitButton';
import Spinner from '../../components/Spinner';
import { PhaseLoadingState } from '../../store/phasesSlice';
import { classNames } from '../../utils/helper';
import { ProjectPhases } from '../../store/projectSlice';
import { LOCAL_STORAGE_TYPES, LocationState, MissionTypes, ToggleRegistrationRequirements } from '../../types/shared';
import ErrorMessage from '../../components/ErrorMessage';
import TagMintGating from './components/CommunityUsers/TagMintGating';
import { Tag } from '../../async/tags/tags';
import DateTimePicker from './components/missions/DateTimePicker/DateTimePicker';

const Section = tw.div`my-8`;
const SectionTitle = tw.p`dark:text-darkText font-bold text-base mb-2`;

const ConfigureRegistration = () => {
  // react router
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  let [searchParams, setSearchParams] = useSearchParams();
  // local state
  const [enabled, setEnabled] = useState(false);
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [ethBalance, setEthBalance] = useState('');
  const [toggleRegistrationRequirements, setToggleRegistrationRequirements] = useState<ToggleRegistrationRequirements>({
    eth_balance: false,
    twitter: false,
    discord: false,
  });
  const [twitterMissionId, setTwitterMissionId] = useState('');
  const [discordMissionId, setDiscordMissionId] = useState('');
  const [editingPhase, setEditingPhase] = useState(false);
  // 3 hour bug chase here...on some mounts, component remounts and you lose state set in useEffect...so try initially
  const [currentTags, setCurrentTags] = useState(
    localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id)
      ? JSON.parse(localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id))
      : null
  );
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // Zustand store
  const newPhase = store((s) => s.phaseSlice.newPhase);
  const editPhase = store((s) => s.phaseSlice.editPhase);
  const loadingType = store((s) => s.phaseSlice.loadingType);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const addMissionToPhase = store((s) => s.phaseSlice.addMissionToPhase);
  const deleteMissionFromPhase = store((s) => s.phaseSlice.deleteMissionFromPhase);
  const clearLocalStorage = store((s) => s.phaseSlice.clearLocalStorage);
  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const projectMissions = store((s) => s.projectSlice.projectMissions);
  const newEthBalanceMission = store((s) => s.ethMissionSlice.newEthBalanceMission);
  const editEthBalanceMission = store((s) => s.ethMissionSlice.editEthBalanceMission);
  const getDiscordMission = store((s) => s.discordMissionSlice.getDiscordMission);
  const addTagToPhase = store((s) => s.tagSlice.addTagToPhase);
  const deleteTagsFromPhase = store((s) => s.tagSlice.deleteTagsFromPhase);
  const guildRoleData = store((s) => s.discordMissionSlice.guildRoleData);
  const errorType = store((s) => s.phaseSlice.errorType);
  const errorMessage = store((s) => s.phaseSlice.errorMessage);

  const earlyAccessPhaseData = projectPhases?.find((phase) => phase.kind === ProjectPhases.EARLY_ACCESS);

  // create phase
  const createPhase = async (id) => {
    // create new phase to get phase ID
    const res = await newPhase(
      id,
      'earlyaccess',
      null,
      'TODO: name',
      '',
      null,
      startDate ? startDate.toISOString() : '',
      endDate ? endDate.toISOString() : ''
    );
    let phaseId = res?.data?.data?.id ? res.data.data.id : null;

    // for each mission, add mission to current phase
    if (phaseId) {
      if (currentTags) {
        addTagToPhase(phaseId, currentTags);
      }
      if (toggleRegistrationRequirements.eth_balance) {
        let ethMissionId = await newEthBalanceMission(id, ethBalance);
        if (ethMissionId) {
          let r = await addMissionToPhase(phaseId, ethMissionId);
        }
      }
      if (toggleRegistrationRequirements.discord) {
        let r = await addMissionToPhase(phaseId, localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id));
      }
      if (toggleRegistrationRequirements.twitter) {
        let r = await addMissionToPhase(phaseId, localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id));
      }

      // clear cache and navigate to project page
      try {
        clearLocalStorage(id);
      } catch (e) {}
      navigate(`/projects/${id}`);
    }
  };

  const edit = async (id) => {
    // create new phase to get phase ID
    const res = await editPhase(
      id,
      earlyAccessPhaseData?.id,
      'earlyaccess',
      null,
      'TODO: name',
      '',
      null,
      startDate ? startDate.toISOString() : '',
      endDate ? endDate.toISOString() : ''
    );
    if (res) {
      let phaseId = res.data.data.id ? res.data.data.id : null;

      // check if account previously had mission or not
      let twitterExists = earlyAccessPhaseData?.missions.find((mission) => mission?.key === 'twitter');
      let ethExists = earlyAccessPhaseData?.missions.find((mission) => mission.key === 'eth_balance');
      let discordExists = earlyAccessPhaseData?.missions.find((mission) => mission?.key === 'discord');

      // Eth CASES:
      // Eth_balance previously existed, now being deleted
      // Eth_balance previously existed, value being changed
      // Eth balance didnt exist, now being created
      if (phaseId) {
        // eth mission being deleted
        if (toggleRegistrationRequirements.eth_balance) {
          if (ethExists) {
            let edit = await editEthBalanceMission(ethExists?.id, ethBalance);
          } else {
            let ethMissionId = await newEthBalanceMission(id, ethBalance);
            if (ethMissionId) {
              let r = await addMissionToPhase(phaseId, ethMissionId);
            }
          }
        } else {
          if (ethExists) {
            let r = await deleteMissionFromPhase(phaseId, ethExists?.id);
          }
        }

        // Twitter CASES:
        // twitter previously existed, now being deleted
        // twitter didnt exist before, now being added
        if (toggleRegistrationRequirements.twitter) {
          if (twitterExists) {
            // do nothing: twitter is already connected
          } else {
            let r = await addMissionToPhase(
              phaseId,
              localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id)
            );
          }
        } else {
          if (twitterExists) {
            let r = await deleteMissionFromPhase(phaseId, twitterExists?.id);
          }
        }

        // Discord CASES:
        // discord previously existed, now being deleted
        // discord didnt exist before, now being added
        // discord missions are editied inline, so edit doesnt apply
        if (toggleRegistrationRequirements.discord) {
          if (discordExists) {
            // do nothing: twitter is already connected
          } else {
            let r = await addMissionToPhase(
              phaseId,
              localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id)
            );
          }
        } else {
          if (discordExists) {
            let r = await deleteMissionFromPhase(phaseId, discordExists?.id, true);
          }
        }

        // TAGS
        // no previous tags, user adds all new tags (create ids)
        // user adds an additional tag to current tags (only create changed ids)
        // user deletes a tag and tags still remain (only delete changed ids)
        /// user deletes all tags (remove all ids)
        if (currentTags && currentTags.length > 0) {
          let newTags = currentTags.filter((newTag) => !earlyAccessPhaseData.tags.some((tag) => tag.id === newTag.id));
          let removedTags = earlyAccessPhaseData.tags.filter(
            (newTag) => !currentTags.some((tag) => tag.id === newTag.id)
          );
          if (newTags && newTags.length > 0) {
            let r = await addTagToPhase(phaseId, newTags);
          }
          if (removedTags && removedTags.length > 0) {
            let r = await deleteTagsFromPhase(phaseId, removedTags);
          }
        } else {
          // previously had tags, deleted them
          if (earlyAccessPhaseData.tags.length > 0) {
            let r = await deleteTagsFromPhase(phaseId, earlyAccessPhaseData.tags);
          }
        }
      }

      // clear cache and navigate to project page
      try {
        clearLocalStorage(id);
      } catch (e) {
        console.log('e');
      }
      navigate(`/projects/${id}`);
    }
  };

  useEffect(() => {
    if (projectPhases.length < 1) {
      getPhases(id);
    }
    if (projectMissions.length < 1) {
      getProjectMissions(id);
    }
  }, [id]);

  // due to redirect, check if missions had been worked on previously and load if yes
  useEffect(() => {
    try {
      let editing = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_EDITING_PHASE + id);
      let discord = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id);
      let twitter = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id);
      let eth = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_ETHEREUM_MISSION + id);
      let phaseTags = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id);
      if (editing) {
        setEditingPhase(true);
      }
      setToggleRegistrationRequirements({
        discord: discord ? true : false,
        twitter: twitter ? true : false,
        eth_balance: eth ? true : false,
      });
      setEthBalance(eth ? eth : '');
      if (discord) {
        setDiscordMissionId(discord);
        getDiscordMission(discord);
      }
      if (twitter) {
        setTwitterMissionId(twitter);
      }
      if (phaseTags) {
        setCurrentTags(JSON.parse(phaseTags));
      }

      let startDate = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + id);
      let endDate = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + id);
      startDate && setStartDate(new Date(startDate));
      endDate && setEndDate(new Date(endDate));
    } catch (e) {}
  }, []);

  const findCurrentTwitterMission = twitterMissionId
    ? projectMissions.find((mission) => mission?.id === twitterMissionId)
    : null;

  const disableIfNoEthBalance = toggleRegistrationRequirements.eth_balance && !ethBalance;

  // disable if twitter auth isnt complete
  const disableIfTwitterNone =
    toggleRegistrationRequirements.twitter &&
    (!findCurrentTwitterMission || (findCurrentTwitterMission && findCurrentTwitterMission.ready === false));

  // disable if discord options arent selected
  const disableIfDiscordNone =
    toggleRegistrationRequirements.discord &&
    (!guildRoleData ||
      (guildRoleData && (!guildRoleData.guild_id || !guildRoleData.role_id || !guildRoleData.invite_link)));

  // user must have completed necessary steps for their configuration
  const disableCreateButton = disableIfNoEthBalance || disableIfTwitterNone || disableIfDiscordNone;

  return (
    <div className="max-w-xl mx-auto min-w-xl">
      {/* TITLE CONTENT */}

      <p className="text-6xl mb-4 dark:text-darkText font-flexa font-bold">
        Configure <br />
        registration
      </p>

      {/* TODO cinfigure registration description text if we get copy */}
      <p className=" dark:text-darkSecondaryText">
        Add a Mint Phase to link Mintkit's Component. Mint phases can be used to help mint NFTs through your contract.
        Use them to add constraints to your mint, such as joining the discord, following on twitter or being a part of a
        community list. They can also be used to guard your mints against bots.
      </p>

      <Section>
        <SectionTitle className="!mb-1">Registration Time</SectionTitle>
        <p className="text-sm dark:text-darkSecondaryText mb-3">
          Choose a start and end time for your users to register
        </p>
        <DateTimePicker startDate={startDate} setStartDate={setStartDate} endDate={endDate} setEndDate={setEndDate} />
      </Section>

      {/* WALLET/SOCIAL INTEGRATION  */}
      <Section>
        <SectionTitle>Registration Requirements</SectionTitle>
        <p className="text-sm dark:text-darkSecondaryText">Connect with...</p>
        <SocialIntegration
          toggleRegistrationRequirements={toggleRegistrationRequirements}
          setToggleRegistrationRequirements={setToggleRegistrationRequirements}
          ethBalance={ethBalance}
          setEthBalance={setEthBalance}
        />
      </Section>

      {/* <TagMintGating currentTags={currentTags} setCurrentTags={setCurrentTags} /> */}

      <hr className="mb-10 mt-10" />
      {errorType === PhaseLoadingState.NEW_PHASE && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {errorType === PhaseLoadingState.EDIT_PHASE && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <Section className="flex flex-row w-full justify-between">
        <SecondaryButton
          text={'Back'}
          additionalStyle={'my-2'}
          onClick={() => {
            if (editingPhase) {
              clearLocalStorage(id);
            }
            navigate(-1);
          }}
        />
        <SubmitButton
          disabled={disableCreateButton || loadingType === PhaseLoadingState.NEW_PHASE}
          className={`${disableCreateButton || loadingType === PhaseLoadingState.NEW_PHASE ? 'opacity-25' : ''}`}
          onClick={() => {
            if (editingPhase) {
              edit(id);
            } else {
              createPhase(id);
            }
          }}
        >
          Save {loadingType === PhaseLoadingState.NEW_PHASE && <Spinner color={'white'} />}
        </SubmitButton>
      </Section>
    </div>
  );
};

export default ConfigureRegistration;
