import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import plutoLogo from '../../assets/images/pluto-community-logo.png';
import Button from '../../components/Button';
import ConnectWallet from '../../components/ConnectWallet';
import { useDarkMode } from '../../hooks/hooks';
import store from '../../store/store';
import ProjectList from './components/ProjectList';

const Projects = () => {
  const navigate = useNavigate();
  const { address } = useAccount();
  const [toggleCommunities, setToggleCommunities] = useState(true);

  const allProjectData = store((s) => s.projectSlice.allProjectData);
  const getAllProjects = store((s) => s.projectSlice.getAllProjects);
  const accountData = store((s) => s.accountSlice.accountData);
  const openWalletModal = store((s) => s.walletModalSlice.openWalletModal);

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div className="">
      <div className="flex flex-col mb-4 ">
        <div className="flex flex-row">
          <div className="flex md:flex-row items-center w-full">
            <span className="text-5xl md:text-6xl  dark:text-darkText text-black font-bold font-flexa">
              My projects{' '}
              {allProjectData && (
                <span className="font-bold relative px-2 py-1 text-sm md:text-base bg-plutoblue-500 text-white dark:bg-plutoorange-500 rounded-md align-middle">
                  {allProjectData.length}
                </span>
              )}
            </span>
          </div>
        </div>

        <p className="text-md mt-4 font-flexa dark:text-darkSecondaryText text-black">
          A project represents an integration with Mintkit. Use a project to manage a website and contract integration,
          the users in a community and the gating methods used for minting.
        </p>
        {allProjectData && allProjectData.length > 0 && (
          <Button
            text={'New Project'}
            additionalStyle={' bg-plutoblue-500 text-white ml-auto mb-0 mt-4'}
            onClick={() => navigate('/projects/new')}
          />
        )}
        <hr className="my-10" />
      </div>
      {!accountData || !allProjectData ? (
        <div className="h-[25vh] rounded-xl dark:bg-darkSecondary bg-gray-100  p-12 mx-auto flex flex-col place-content-center items-center space-y-6">
          <div>
            <p className="dark:text-darkText text-center mb-1 font-flexa ">Please sign in to create a project.</p>
          </div>

          <Button text={address ? 'Sign Message' : 'Connect Wallet'} onClick={() => openWalletModal()} />
        </div>
      ) : null}

      {accountData && (
        <>
          {!allProjectData || allProjectData.length < 1 ? (
            <div className="h-[25vh] rounded-xl dark:bg-darkSecondary bg-gray-100  p-12 mx-auto flex flex-col place-content-center items-center space-y-6">
              <div>
                <p className="dark:text-darkText text-center mb-1 font-flexa ">You don't have any projects</p>
              </div>

              <Button text={'Create a Project'} onClick={() => navigate('/projects/new')} />
            </div>
          ) : (
            <div className="mb-10">{allProjectData && <ProjectList projects={allProjectData} />}</div>
          )}
        </>
      )}
    </div>
  );
};

export default Projects;
