import React, { useContext, useEffect, useState } from 'react';
import twitter from '../../assets/images/twitter.png';
import discord from '../../assets/images/discord.png';
import opensea from '../../assets/images/opensea.png';
import twitterDark from '../../assets/images/twitter-dark.png';
import discordDark from '../../assets/images/discord-dark.png';
import openseaDark from '../../assets/images/opensea-dark.png';
import { DarkModeContext, useDarkMode } from '../../hooks/hooks';
import store from '../../store/store';
import { Link, Outlet, useLocation } from 'react-router-dom';
import plutoLogo from '../../assets/images/pluto-community-logo.png';

// import { ProjectViews } from './id';
import { useParams } from 'react-router-dom';
import { IoCompassOutline } from 'react-icons/io5';
import ProjectImageComponent from '../../components/ProjectImageUpload/ProjectImage';
// import { useNavigate } from 'react-router';

// interface ProjectHeaderProps {
//   viewOptions: ProjectViews[];
//   showProjectDetails: ProjectViews;
//   setShowProjectDetails: React.Dispatch<React.SetStateAction<ProjectViews>>;
// }

// { viewOptions, showProjectDetails, setShowProjectDetails }: ProjectHeaderProps

export type ProjectViews = 'Overview' | 'Community' | 'Settings' | 'Manage Integration';

const ProjectHeader = () => {
  const { id } = useParams();
  //   const navigate = useNavigate();
  const darkModeContext = useContext(DarkModeContext);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const projectMissions = store((s) => s.projectSlice.projectMissions);
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const projectUsers = store((s) => s.userSlice.projectUsers);

  const getProject = store((s) => s.projectSlice.getProject);
  const getContracts = store((s) => s.contractSlice.getContracts);
  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const getUsers = store((s) => s.userSlice.getUsers);

  const location = useLocation();

  const socials = [
    {
      name: 'Discord',
      image: darkModeContext.darkMode ? discordDark : discord,
    },
    {
      name: 'Twitter',
      image: darkModeContext.darkMode ? twitterDark : twitter,
    },
    {
      name: 'OpenSea',
      image: darkModeContext.darkMode ? openseaDark : opensea,
    },
  ];

  //   const viewOptions: ProjectViews[] = ['Overview', 'Community', 'Manage Integration'];
  const viewOptions = [
    { name: 'Overview', route: `/projects/${id}` },
    { name: 'Community', route: `/projects/${id}/users` },
    { name: 'Manage Integration', route: `/projects/${id}/integration` },
  ];

  useEffect(() => {
    getProject(id);
    getContracts(id);
    getProjectMissions(id);
    getPhases(id);
    getUsers(id);
  }, [id]);

  return (
    <div className="relative">
      {currentProjectData && (
        <div className="flex flex-col lg:flex-row lg:px-32 px-4 pt-16 dark:bg-darkSecondary bg-gray-100 md:space-x-8">
          <div className="md:absolute  ">
            <ProjectImageComponent
              src={currentProjectData?.imageUrl}
              containerClassname=" border-4 border-white shadow-lg rounded-lg"
            />
          </div>

          <Link
            to={`/projects/${id}/settings`}
            className="hidden md:block md:absolute lg:right-32 right-4 lg:mt-[7.5rem] text-white px-8 py-3 rounded-full font-bold bg-plutoblue-500 dark:bg-darkButton  hover:bg-plutoblue-400 dark:hover:bg-darkButtonHover transition-all"
          >
            Edit Project
          </Link>

          <div className="md:pl-48 w-full">
            <div className="space-x-4 mt-4 md:mt-0 mb-12">
              {/*  TODO remove social integration until we have api implementation */}
              {/* {socials.map((social, i) => (
              <img key={i} src={social.image} className="hover:cursor-pointer h-6 w-6 inline-block" alt="social" />
            ))} */}
            </div>
            <div className="flex flex-row items-center">
              {currentProjectData?.name && (
                <p className="dark:text-darkText text-6xl font-flexa font-bold">{`${currentProjectData?.name}`}</p>
              )}
              <Link
                to={`/projects/${id}/settings`}
                className="block ml-auto md:hidden text-white px-2 py-1 text-sm rounded-full font-bold  dark:bg-darkButton bg-[#07074E]"
              >
                Edit Project
              </Link>
            </div>

            <div className="space-x-4 pt-4 ">
              {viewOptions.map((view, i) => (
                <Link
                  key={i}
                  className={`text-sm md:text-base pt-4 dark:text-darkText border-b-8   dark:hover:border-darkText hover:border-plutoblue-500 transition-all  ${
                    location.pathname === view.route
                      ? 'font-bold  dark:border-darkText border-plutoblue-500'
                      : 'dark:border-darkSecondary border-gray-100'
                  }`}
                  to={view.route}
                >
                  {view.name}
                </Link>
              ))}
            </div>
          </div>
        </div>
      )}
      <div className="px-4 lg:px-32 pt-16 pb-48 flex flex-col">
        <Outlet />
      </div>
    </div>
  );
};

export default ProjectHeader;
