import React, { useState } from 'react';
import FileDragDrop from '../../../../components/FileDragDrop';
import SubmitButton from '../../../../components/SubmitButton';

interface ImportCsvProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const ImportCsv = ({ setOpen }: ImportCsvProps) => {
  const [csvFile, setCsvFile] = useState('');
  const submitCsv = async () => {
    // const r = await newUser(id, name, bypass);
    // if (r) setOpen(false);
  };

  return (
    <div className="">
      <p className="font-flexa text-4xl dark:text-darkText">Import Allowlist</p>
      <p className="text-sm mb-4 dark:text-darkSecondaryText">Import your selected list of users.</p>
      <FileDragDrop
        label={''}
        fileTypeDescriptionText=" "
        uploadInstructionText={'Drag and drop list'}
        acceptedFileTypes={'csv'}
        setFile={setCsvFile}
        file={csvFile}
      />
      <div className="mt-8">
        <SubmitButton
          className="mt-8"
          onClick={() => {
            setOpen(false);
          }}
        >
          Import
        </SubmitButton>
      </div>
    </div>
  );
};

export default ImportCsv;
