import { lens } from '@dhmk/zustand-lens';

export interface WalletModalSlice {
  open: boolean;
  openWalletModal: () => void;
  closeWalletModal: () => void;
}

const initialState = {
  open: false,
};

export const walletModalSlice: WalletModalSlice = lens(
  (setState, getState) => ({
    ...initialState,
    openWalletModal: () => setState({ open: true }),
    closeWalletModal: () => setState({ open: false }),
  })
);
