import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import Button from '../../../../components/Button';
import DebugButton from '../../../../components/DebugButton';
import FileDragDrop from '../../../../components/FileDragDrop';
import Modal from '../../../../components/Modal';
import { ProjectPhases } from '../../../../store/projectSlice';
import CommunityUsersTable, { ModalTypes } from './CommunityUsersTable';
import COPY from '../../text.yaml';
import AddUser from './AddUser';
import ImportCsv from './ImportCsv';
import store from '../../../../store/store';
import CommunityTags from './CommunityTags';

const SecondaryButton = tw.button`text-xs text-plutoblue-500 font-bold px-4 py-2 md:px-8 md:py-3 border dark:border dark:text-white dark:bg-transparent bg-white  rounded-full text-center`;
const PrimaryButton = tw.button`text-xs text-white font-bold px-4 py-2 md:px-8 md:py-3 rounded-full  bg-[#07074E] dark:bg-darkButton`;

const Community = () => {
  const { id, phase } = useParams();
  const [showCommunityUsers, setShowCommunityUsers] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<ModalTypes | null>(null);
  const [userView, setUserView] = useState(true);
  const projectUsers = store((s) => s.userSlice.projectUsers);
  const getUsers = store((s) => s.userSlice.getUsers);

  useEffect(() => {
    if (projectUsers.length < 1) getUsers(id);
  }, [id]);

  return (
    <>
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <>
          {currentModal === ModalTypes.ADD_USER && <AddUser setOpen={setModalOpen} />}
          {currentModal === ModalTypes.IMPORT_CSV && <ImportCsv setOpen={setModalOpen} />}
        </>
      </Modal>
      {/* <DebugButton
        text={`${showCommunityUsers ? 'Hide' : 'Show'}  details`}
        onClick={() => setShowCommunityUsers(!showCommunityUsers)}
        additionalStyle="ml-auto"
      /> */}
      {projectUsers.length < 1 ? (
        <>
          <h1 className="font-flexa font-bold dark:text-darkText text-3xl mt-4 mb-4">{COPY.users.title}</h1>
          <p className="dark:text-darkSecondaryText mb-8">{COPY.users.description}</p>

          <div className="border border-gray-500 dark:text-darkText text-gray-500 flex flex-col p-4 rounded-lg">
            <div className="flex flex-col my-4 md:my-48 mx-auto md:w-1/3">
              <h2 className="font-flexa text-center font-bold dark:text-darkText text-xl">{COPY.users.noMembers}</h2>
              <p className="dark:text-darkSecondaryText text-center text-md mt-8">{COPY.users.instructions}</p>
              <p className="dark:text-darkSecondaryText text-center text-md mt-8">
                If you already have existing users, you can import them.
              </p>
              <div className="flex flex-row items-center mt-12 gap-10 mx-auto">
                {/* <SecondaryButton
                  className=""
                  onClick={() => {
                    setModalOpen(true);
                    setCurrentModal(ModalTypes.IMPORT_CSV);
                  }}
                >
                  Import CSV
                </SecondaryButton> */}
                <PrimaryButton
                  onClick={() => {
                    setModalOpen(true);
                    setCurrentModal(ModalTypes.ADD_USER);
                  }}
                >
                  Add User
                </PrimaryButton>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="flex flex-col lg:flex-row justify-between lg:items-end mb-4">
            <div className="flex flex-col">
              <h1 className="font-flexa font-bold dark:text-darkText text-3xl mt-8 mb-4">Community</h1>
              <p className="dark:text-darkSecondaryText mb-4">View and edit your users and community tags below.</p>
            </div>
            <button
              onClick={() => setUserView(!userView)}
              className="text-plutoblue-500 dark:text-darkText text-xs lg:text-sm text-white font-bold px-3 py-1  rounded-full  border border-plutoblue-500 dark:border-white self-end lg:self-start mt-auto "
            >
              {`View ${userView ? 'Tags' : 'Users'}`}
            </button>
          </div>

          {userView ? <CommunityUsersTable /> : <CommunityTags />}
        </>
      )}
    </>
  );
};

export default Community;
