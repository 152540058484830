import { lens } from '@dhmk/zustand-lens';
import { StoreApi } from 'zustand';
import { Store } from './store';

export interface UniversalSlice {
  resetAppState: () => Promise<void>;
}

export const universalSlice: UniversalSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  resetAppState: async () => {
    api.getState().accountSlice.resetState();
    api.getState().projectSlice.resetState();
    api.getState().discordMissionSlice.resetState();
    api.getState().ethMissionSlice.resetState();
    api.getState().contractSlice.resetState();
    api.getState().phaseSlice.resetState();
    api.getState().userSlice.resetState();
    api.getState().tagSlice.resetState();
  },
}));
