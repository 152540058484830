import React, { useContext, useEffect, useState } from 'react';
import { RiGasStationFill } from 'react-icons/ri';
import { TbExternalLink } from 'react-icons/tb';
import SubmitButton from '../../../components/SubmitButton';
import store from '../../../store/store';
import {
  formatEthereumIdentity,
  formatWalletAddress,
  getContractNetwork,
  getEtherescanLink,
} from '../../../utils/helper';
import EarlyAccess from './EarlyAccessPhase';
import COPY from '../text.yaml';
import twitter from '../../../assets/images/twitter.png';
import discord from '../../../assets/images/discord.png';
import twitterDark from '../../../assets/images/twitter-dark.png';
import discordDark from '../../../assets/images/discord-dark.png';
import ethereumLogo from '../../../assets/images/eth-logo.png';
import { DarkModeContext } from '../../../hooks/hooks';
import Modal from '../../../components/Modal';
import { ProjectPhases } from '../../../store/projectSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { PhaseLoadingState } from '../../../store/phasesSlice';
import Spinner from '../../../components/Spinner';
import { DeleteButton } from './phases/DeleteButton';
import { SecondaryButton } from './phases/SecondaryButton';
import DeletePhaseModal from './phases/DeletePhaseModal/DeletePhaseModal';
import { LOCAL_STORAGE_TYPES } from '../../../types/shared';
import PhaseDates from './phases/PhaseDates';
import styled from 'styled-components';
import tw from 'twin.macro';
import DomainWarning from './phases/DomainWarning';
import { IoTrashOutline } from 'react-icons/io5';

const Col = tw.div`pr-2 `;
const Section = styled.section`
  display: flex;
  layout: relative;
  flex-direction: column;
  margin-top: 1rem;
`;

const Grid = tw.div`grid auto-rows-min gap-1`;
const GridItem = tw.div``;

const PrivateMintPhase = () => {
  const [showLaunchModal, setShowLaunchModal] = useState(false);

  const darkModeContext = useContext(DarkModeContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const projectPhases = store((s) => s.phaseSlice.projectPhases);
  const loadingType = store((s) => s.phaseSlice.loadingType);
  const editProject = store((s) => s.projectSlice.editProject);
  const getPhases = store((s) => s.phaseSlice.getPhases);
  const deletePhase = store((s) => s.phaseSlice.deletePhase);
  const getEthBalanceMission = store((s) => s.ethMissionSlice.getEthBalanceMission);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [phaseDeleteText, setPhaseDeleteText] = useState('');

  const privatePhaseData = projectPhases?.find((phase) => phase.kind === 'privatemint');
  const privateMintActive = currentProjectData?.phaseId === privatePhaseData?.id;
  const currentContract =
    privatePhaseData && projectsContracts.length > 0
      ? projectsContracts.find((contract) => contract.id === privatePhaseData.contract_id)
      : null;

  const socials = [
    {
      name: 'Eth',
      image: ethereumLogo,
      active: privatePhaseData?.missions.find((mission) => mission?.key === 'eth_balance'),
    },
    {
      name: 'Discord',
      image: darkModeContext.darkMode ? discordDark : discord,
      active: privatePhaseData?.missions.find((mission) => mission?.key === 'discord'),
    },
    {
      name: 'Twitter',
      image: darkModeContext.darkMode ? twitterDark : twitter,
      active: privatePhaseData?.missions.find((mission) => mission?.key === 'twitter'),
    },
  ];

  const deletePrivateMint = async () => {
    if (privatePhaseData && privatePhaseData.id) {
      const r = await deletePhase(privatePhaseData.id);
      if (r) {
        setShowDeleteModal(false);
        setPhaseDeleteText('');
        getPhases(id);
      }
    }
  };

  // setup localStorage for editing private mint phase
  const setupPrivateMintPhase = async () => {
    if (privatePhaseData) {
      try {
        let twitterExists = privatePhaseData.missions.find((mission) => mission.key === 'twitter');
        let discordExists = privatePhaseData.missions.find((mission) => mission.key === 'discord');
        let ethBalanceExists = privatePhaseData.missions.find((mission) => mission.key === 'eth_balance');

        if (twitterExists) {
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_TWITTER_MISSION + id, twitterExists.id);
        }
        if (discordExists) {
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id, discordExists.id);
        }
        if (ethBalanceExists) {
          const r = await getEthBalanceMission(ethBalanceExists.id);
          if (r) {
            localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_ETHEREUM_MISSION + id, r?.min_eth_balance_in_ether);
          }
        }

        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id, privatePhaseData?.contract_id);
        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_PRICE + id, privatePhaseData?.price_in_ether);
        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_MINT_QUANTITY + id, privatePhaseData?.max_quantity_per_user);
        localStorage.setItem(
          LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id,
          JSON.stringify(privatePhaseData?.contract_func_map)
        );

        if (privatePhaseData.tags.length > 0) {
          localStorage.setItem(
            LOCAL_STORAGE_TYPES.PLUTO_CURRENT_PHASE_TAGS + id,
            JSON.stringify(privatePhaseData.tags)
          );
        }
        privatePhaseData?.starts_at &&
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + id, privatePhaseData?.starts_at);
        privatePhaseData?.ends_at &&
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + id, privatePhaseData?.ends_at);

        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_EDITING_PHASE + id, 'true');
        navigate(`/projects/${id}/mintSetup/${privatePhaseData?.kind}`);
      } catch (e) {}
    }
  };

  return (
    <>
      {showLaunchModal && (
        <Modal open={showLaunchModal} setOpen={setShowLaunchModal}>
          <div>
            <p className="font-flexa text-4xl dark:text-darkText">Launch Mint Phase</p>
            <p className="text-sm mb-4 mt-4 dark:text-darkSecondaryText">
              Launching will transition Mintkit to the private mint phase. Press launch to continue.
            </p>

            {!currentProjectData?.domains?.length && <DomainWarning />}

            <div className="flex flex-row">
              <SubmitButton
                className="mt-4 ml-auto"
                disabled={loadingType === PhaseLoadingState.SET_PHASE}
                onClick={() => {
                  editProject(
                    id,
                    currentProjectData?.teamId,
                    currentProjectData?.name,
                    privatePhaseData?.id,
                    currentProjectData?.domains,
                    currentProjectData?.imageUrl,
                    () => setShowLaunchModal(false)
                  );
                }}
              >
                Launch {loadingType === PhaseLoadingState.SET_PHASE && <Spinner color={'white'} />}
              </SubmitButton>
            </div>
          </div>
        </Modal>
      )}
      {showDeleteModal && (
        <DeletePhaseModal
          open={showDeleteModal}
          setOpen={setShowDeleteModal}
          phase={'Private Mint'}
          deletePhase={deletePrivateMint}
          phaseDeleteText={phaseDeleteText}
          setPhaseDeleteText={setPhaseDeleteText}
        />
      )}
      <div>
        {currentContract && privatePhaseData ? (
          <div className="h-full px-4 pb-4 xl:px-8 dark:bg-slate-800 bg-gray-100 rounded-xl">
            <div className="flex flex-col h-full ">
              <Grid>
                <GridItem>
                  <div className="flex flex-row items-start mt-4">
                    <h2 className="dark:text-white font-flexa font-bold text-xl">Private Mint</h2>
                    <p
                      className={`${
                        privateMintActive ? 'bg-green-500' : 'bg-plutoblue-500 dark:bg-plutoorange-500'
                      } px-2 ml-auto rounded-full my-1 text-white text-xs`}
                    >
                      {privateMintActive ? 'Active' : 'Inactive'}
                    </p>
                  </div>
                </GridItem>

                <GridItem>
                  <Section className="">
                    <PhaseDates phaseData={privatePhaseData} />
                  </Section>
                </GridItem>
                {/* Settings */}
                <GridItem>
                  <div className="mt-4 border border-1 rounded-xl border-gray-500 text-center">
                    <div className="grid grid-cols-2   border-gray-500">
                      <div className="py-2 px-2 flex flex-col border-r border-gray-500 border-1 ">
                        <span className="dark:text-white text-xs font-bold font-flexa">Network</span>
                        <span className="dark:text-white text-xs mt-1">
                          {`${getContractNetwork(currentContract.address)}`}
                        </span>
                      </div>

                      <div className="py-2 px-2 flex flex-col">
                        <span className="dark:text-white text-xs font-bold font-flexa">Price</span>
                        <span className="dark:text-white">
                          <RiGasStationFill size={14} className=" inline dark:text-white" />
                          <span className=" text-xs dark:text-white">+ {`${privatePhaseData.price_in_ether} Ξ`}</span>
                        </span>
                      </div>
                    </div>
                    {/* <div className="grid grid-cols-2 ">
                      <div className="flex flex-col border-r border-gray-500 border-1 py-2 px-2 text-center ">
                        <span className="dark:text-white text-xs font-bold font-flexa">Community Tags</span>
                        {privatePhaseData.tags.length > 0 ? (
                          <div className="flex flex-row text-center place-content-center">
                            {privatePhaseData.tags.map((tag, i) => (
                              <span key={i} className="dark:text-darkText text-xs mt-1">
                                {tag.name}
                                {i < privatePhaseData.tags.length - 1 ? <span>,&nbsp;</span> : ''}
                              </span>
                            ))}
                          </div>
                        ) : (
                          <span className="dark:text-white text-xs mt-1">None</span>
                        )}
                      </div>
                      <div className="flex flex-col text-center py-2 px-2 ">
                        <span className="dark:text-white text-xs font-bold font-flexa">Requirements</span>
                        {privatePhaseData.length < 1 || privatePhaseData.missions?.length < 1 ? (
                          <p className="dark:text-darkText text-xs mt-1">None</p>
                        ) : (
                          <div className="flex flex-row items-center place-content-center">
                            {socials.map((social, i) =>
                              social.active ? (
                                <img
                                  key={i}
                                  src={social.image}
                                  className="mt-1 mx-2 h-4 w-4 inline-block"
                                  alt="social"
                                />
                              ) : null
                            )}
                          </div>
                        )}
                      </div>
                    </div> */}
                  </div>
                </GridItem>

                <GridItem>
                  <div className="flex flex-row justify-between items-center">
                    <div className="pt-4 flex flex-col ">
                      <span className="mr-auto dark:text-white font-bold">
                        {`${getContractNetwork(currentContract.address)} Contract`}
                      </span>

                      <a
                        target="_blank"
                        href={getEtherescanLink(
                          formatEthereumIdentity(currentContract.address),
                          getContractNetwork(currentContract.address)
                        )}
                        className="text-sm   dark:text-white flex flex-row items-center hover:cursor-pointer hover:underline self-start"
                        rel="noreferrer"
                      >
                        {formatWalletAddress(formatEthereumIdentity(currentContract.address))}
                        <TbExternalLink className="text-gray-500 text-gray-400 ml-1 hover:underline" />
                      </a>
                    </div>

                    <div className="flex flex-col  pt-4 ">
                      <span className="dark:text-white text-xs font-bold ">Community Tags</span>
                      {privatePhaseData.tags.length > 0 ? (
                        <div className="flex flex-row mt-1">
                          {privatePhaseData.tags.map((tag, i) => (
                            <span
                              key={i}
                              className="dark:text-darkText text-xs py-0.5 px-2 mr-1 bg-gray-200 dark:bg-gray-600 text-plutoblue-500 rounded-full"
                            >
                              {tag.name}
                              {i < privatePhaseData.tags.length - 1 ? <span>,&nbsp;</span> : ''}
                            </span>
                          ))}
                        </div>
                      ) : (
                        <span className="dark:text-white text-xs mt-1"></span>
                      )}
                    </div>
                  </div>
                </GridItem>
              </Grid>

              <div className="flex flex-col-reverse xl:flex-row pt-8 gap-x-1 items-stretch  xl:items-end mt-auto">
                <div className="space-x-2 mb-2 mt-4 xl:mt-0 flex justify-between items-center">
                  {/* <DeleteButton onClick={() => setShowDeleteModal(true)}>
                    {' '} */}
                  <IoTrashOutline
                    className="text-plutoblue-500 dark:text-white text-xl hover:scale-110 hover:cursor-pointer mr-1 transition-all"
                    onClick={() => setShowDeleteModal(true)}
                  />
                  {/* </DeleteButton> */}
                  <SecondaryButton onClick={() => setupPrivateMintPhase()} className="mr-auto">
                    Edit
                  </SecondaryButton>
                </div>
                {!privateMintActive && (
                  <SubmitButton className="ml-auto hover:scale-[102.5%]" onClick={() => setShowLaunchModal(true)}>
                    Launch
                  </SubmitButton>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="h-full pb-8 px-8 dark:bg-slate-800 bg-gray-100 rounded-xl text-center md:text-left">
            <div className="flex flex-col h-full justify-between">
              <h2 className="dark:text-white text-4xl mt-8">Engage your community with a Private Mint</h2>
              <p className="dark:text-white text-md mt-8">
                Embed the Mintkit component on your mint website to create gated and bot protected mints. gated mint can
                be used as an alternative to collecting wallet IDs. Add users to your community with their email,
                twitter or discord and they can mint flexibly from any wallet.
              </p>
              <SubmitButton
                className="ml-auto !text-xs !font-bold !px-4 !py-2 md:!px-8 md:!py-3 !mb-0 !mt-8 !lg:mt-0"
                onClick={() => {
                  if (currentProjectData?.componentsLastSeenAt) {
                    navigate(`/projects/${id}/contractSetup/${ProjectPhases.PRIVATE_MINT}`);
                  } else {
                    navigate(`/projects/${id}/setup/${ProjectPhases.PRIVATE_MINT}`);
                  }
                }}
              >
                Set up Private Mint
              </SubmitButton>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default PrivateMintPhase;
