import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import { IndexTagResponse, Tag } from '../async/tags/tags';
import { AddTagToPhaseRequest, DeleteTagFromPhaseRequest } from '../async/phases/phases';
import { DeleteTagFromUserRequest } from '../async/users/users';

export enum TagLoadingState {
  NULL,
  NEW_TAG,
  GET_TAGS,
  DELETE_TAG_FROM_USER,
  ADD_TAG_TO_USER,
  ADD_TAG_TO_PHASE,
  DELETE_TAGS_FROM_PHASE,
  DELETE_TAG,
}

export interface TagSlice {
  loadingType: TagLoadingState;
  loadingMessage: string;
  errorType: TagLoadingState;
  errorMessage: string;
  tags: Tag[];
  resetState: () => void;
  resetLoading: () => void;
  newTag: (project_id: string, name: string) => Promise<any>;
  getTags: (project_id: string) => Promise<any>;
  deleteTagFromUser: (user_id: string, tag_id: string, project_id: string) => Promise<any>;
  addTagsToUser: (user_id: string, tag_ids: string, project_id: string) => Promise<any>;
  addTagToPhase: (phase_id: string, tag_ids: Tag[]) => Promise<any>;
  deleteTagsFromPhase: (phase_id: string, tag_ids: Tag[]) => Promise<any>;
  deleteTag: (tag_id: string, project_id: string) => Promise<boolean>;
}

const initalState = {
  loadingType: TagLoadingState.NULL,
  loadingMessage: '',
  errorType: TagLoadingState.NULL,
  errorMessage: '',
  tags: [],
};

export const tagSlice: TagSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newTag: async (project_id, name) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.NEW_TAG });
      const response = await axios.post(`/tags`, {
        name,
        project_id,
      });
      if (response && response.status === 200) {
        setState({ loadingType: TagLoadingState.NULL });
        // refresh new tags
        getState().getTags(project_id);
        return response.data;
      }
    } catch (e) {
      setState({
        loadingType: TagLoadingState.NULL,
        loadingMessage: '',
        errorType: TagLoadingState.NEW_TAG,
        errorMessage: e?.response?.data?.error,
      });
    }
  },
  getTags: async (project_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.GET_TAGS });
      const response = await axios.get<IndexTagResponse>(`/projects/${project_id}/tags`);
      if (response && response.status === 200) {
        setState({ tags: response.data.data, loadingType: TagLoadingState.NULL });
        return response.data;
      }
    } catch (e) {
      console.log('getTags error: ', e);
    }
  },
  addTagsToUser: async (user_id, tag_ids, project_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.ADD_TAG_TO_USER });
      const response = await axios.post(`/users/${user_id}/tag`, { tag_ids });
      if (response && response.status === 200) {
        api.getState().userSlice.getUsers(project_id);
        setState({ loadingType: TagLoadingState.NULL });
        return true;
      }
    } catch (e) {
      console.log('addTagToUser error: ', e);
      return null;
    }
  },
  addTagToPhase: async (phase_id, tag_ids) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.ADD_TAG_TO_PHASE });

      // API requests only tag Ids
      const tagIds = tag_ids ? tag_ids?.map((x) => x.id) : [];

      const response = await axios.post(`/phases/${phase_id}/tag`, { tag_ids: tagIds });
      if (response && response.status === 200) {
        setState({ loadingType: TagLoadingState.NULL });
        return true;
      }
    } catch (e) {
      console.log('addTagToUser error: ', e);
      return null;
    }
  },
  deleteTagsFromPhase: async (phase_id, tag_ids) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.DELETE_TAGS_FROM_PHASE });

      // API requests only tag Ids
      const tagIds = tag_ids ? tag_ids?.map((x) => x.id) : [];

      const response = await axios.post(`/phases/${phase_id}/delete_tag`, { tag_ids: tagIds });
      if (response && response.status === 200) {
        setState({ loadingType: TagLoadingState.NULL });
        return true;
      }
    } catch (e) {
      console.log('addTagToUser error: ', e);
      return null;
    }
  },
  deleteTagFromUser: async (user_id, tag_id, project_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.DELETE_TAG_FROM_USER });
      const body = DeleteTagFromUserRequest.create({ tagIds: [tag_id] });
      const response = await axios.post(`/users/${user_id}/delete_tag`, body);
      if (response && response.status === 200) {
        api.getState().userSlice.getUsers(project_id);
        setState({ loadingType: TagLoadingState.NULL });
        return true;
      }
    } catch (e) {
      console.log('deleteTagFromUser error: ', e);
      return null;
    }
  },
  deleteTag: async (tag_id, project_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: TagLoadingState.DELETE_TAG });
      const response = await axios.delete(`/tags/${tag_id}`);
      if (response && response.status === 200) {
        // refresh tags and users data
        getState().getTags(project_id);
        api.getState().userSlice.getUsers(project_id);
        setState({ loadingType: TagLoadingState.NULL });
        return true;
      }
    } catch (e) {
      setState({ loadingType: TagLoadingState.NULL });
      console.debug('deleteTag error: ', e);
      return false;
    }
  },
  resetLoading: () => {
    setState({
      loadingType: TagLoadingState.NULL,
      loadingMessage: '',
      errorType: TagLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
