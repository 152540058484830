import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import { Tag } from '../../../../async/tags/tags';

import CheckBox from '../../../../components/CheckBox';
import ErrorMessage from '../../../../components/ErrorMessage';
import SubmitButton from '../../../../components/SubmitButton';
import TextInput from '../../../../components/TextInput';
import store from '../../../../store/store';
import { TagLoadingState } from '../../../../store/tagSlice';

interface AddUserProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  currentTags?: null | Tag[];
  setCurrentTags?: React.Dispatch<React.SetStateAction<Tag[]>>;
}

const CreateTag = ({ setOpen, currentTags, setCurrentTags }: AddUserProps) => {
  const { id } = useParams();
  const [tag, setTag] = useState('');
  const [bypass, setBypass] = useState(false);
  const newUser = store((s) => s.userSlice.newUser);
  const newTag = store((s) => s.tagSlice.newTag);
  const errorType = store((s) => s.tagSlice.errorType);
  const errorMessage = store((s) => s.tagSlice.errorMessage);
  const submitTag = async () => {
    const r = await newTag(id, tag);
    if (r) {
      if (setCurrentTags) {
        let newTags = currentTags ? [...currentTags, r.data] : [r.data];
        setCurrentTags(newTags);
      }
      setOpen(false);
    }
  };

  return (
    <div className="p-3 md:p-0">
      <p className="font-flexa text-4xl dark:text-darkText">Create Tag</p>
      <p className="text-sm mb-4 dark:text-darkSecondaryText mt-1">
        Create tags so you can group different users in your community.
      </p>

      <TextInput inputPlaceholder={'tag'} value={tag} onChange={setTag} />
      {errorType === TagLoadingState.NEW_TAG && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <div className="mt-8">
        <SubmitButton className="mt-4" onClick={() => submitTag()}>
          Create
        </SubmitButton>
      </div>
    </div>
  );
};

export default CreateTag;
