import { Menu, Transition } from '@headlessui/react';
import React, { Fragment, useContext } from 'react';
import avatar from '../../assets/images/avatar-dark.png';
import avatarLight from '../../assets/images/avatar-white.png';
import downArrow from '../../assets/images/down-arrow.png';
// import { useLogoutUserMutation, useUserQuery } from '../../store/plutoapi';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import Spinner from '../Spinner';
import { Wallets } from '../wallets';
import SignMessage from '../SignMessage';
import store from '../../store/store';
import { useNavigate } from 'react-router-dom';
import tw from 'twin.macro';
import WalletMenuButton from './WalletMenuButton';
import { DarkModeContext } from '../../hooks/hooks';

const ConnectWallet = () => {
  const { address } = useAccount();

  const { disconnect } = useDisconnect();

  const { isLoading: signatureLoading } = useSignMessage({});

  const accountData = store((state) => state.accountSlice.accountData);
  const signOut = store((state) => state.accountSlice.signOut);
  const darkModeContext = useContext(DarkModeContext);

  const navigate = useNavigate();

  return (
    <div className="flex flex-row space-x-4 items-center relative mx-1">
      {/* Wallet Widget */}
      <div className="relative flex flex-row  dark:text-darkText text-plutoblue-500  items-center hover:cursor-pointer">
        <Menu as="div" className="relative inline-block text-left px-4">
          {/*  */}
          {/* Wallet connected and cookie */}
          {/* {address && data ? ( */}
          {address && accountData ? (
            <WalletMenuButton>
              <>
                {' '}
                <div className="flex flex-col">
                  <span className="text-sm hidden md:block text-center">
                    {/* {data && data.name ? data.name : 'anon'} */}
                    {'anon'}
                  </span>
                  <span className=" text-sm hidden md:block leading-3">
                    {`0x${address.toUpperCase().substring(2, 6)}...${address
                      .toUpperCase()
                      .substring(address.length - 4, address.length)}`}
                  </span>
                </div>
                <img
                  className="h-6 lg:h-8 mx-1 rounded-full"
                  alt="profile pic"
                  // src={data && data.image_url ? data.image_url : avatar}
                  src={darkModeContext.darkMode ? avatarLight : avatar}
                  onError={({ currentTarget }) => {
                    currentTarget.onerror = null;
                    currentTarget.src = avatar;
                  }}
                />
                <img className="h-3" src={downArrow} alt="down arrow" />
              </>
            </WalletMenuButton>
          ) : null}

          {/* Wallet connected but No Cookie */}
          {address && !accountData ? (
            <WalletMenuButton>
              <>
                {signatureLoading && <Spinner color={'white'} />}
                {signatureLoading ? 'Check your wallet' : 'Sign Message'}
              </>
            </WalletMenuButton>
          ) : null}

          {/* No Wallet and No Cookie */}
          {!address ? <WalletMenuButton>Connect</WalletMenuButton> : null}

          {/* Dropdown Menu */}
          <Transition
            enter="transition ease-out duration-50"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-50"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items
              className={`z-50  text-black fixed absolute -right-4 mt-2 ${
                accountData ? 'w-56' : 'w-72'
              } max-w-100 rounded-md dark:bg-darkSecondary bg-white shadow-lg  focus:outline-none`}
            >
              {/* Wallet connected and cookie */}
              {address && accountData ? (
                <div className="p-2 text-center flex flex-col justify-center ">
                  {/* <Menu.Item>
                    {({ active }) => (
                      <Link
                        className='p-2 text-center hover:bg-gray-200 group flex rounded-md items-center text-sm '
                        to='/user/edit'>
                        Account
                      </Link>
                    )}
                  </Menu.Item> */}
                  {address && (
                    <li
                      className={`p-2 text-center dark:text-darkText dark:hover:bg-darkHover hover:bg-lightHover group rounded-md items-center text-sm   group flex rounded-md hover:cursor-pointer  text-sm`}
                      onClick={() => {
                        signOut(() => navigate('/projects'));
                        disconnect();
                      }}
                    >
                      Disconnect
                    </li>
                  )}
                </div>
              ) : null}

              {address && !accountData ? (
                <div className="p-4 md:p-6">
                  <div className="flex flex-row">
                    <span
                      className="m-1 dark:bg-darkSecondary bg-green-100 h-4 w-4 rounded-full flex items-center justify-center"
                      aria-hidden="true"
                    >
                      <span className="bg-green-400 h-2 w-2 rounded-full" />
                    </span>
                    <p className="mb-2 font-bold dark:text-darkText">
                      {`0x${address.toUpperCase().substring(2, 6)}...${address
                        .toUpperCase()
                        .substring(address.length - 4, address.length)}`}
                    </p>
                  </div>

                  <p className="mb-4 mx-2 text-gray-500">
                    To verify your account and sign in, please sign the message in your wallet
                  </p>

                  <div className="mx-2 grid grid-cols-2 gap-2 md:gap-4">
                    <button
                      onClick={() => {
                        disconnect();
                      }}
                      className="mr-auto px-4 py-2 text-center justify-center dark:bg-dark bg-gray-300 text-white justify-end rounded-full items-center text-sm"
                    >
                      Back
                    </button>

                    <SignMessage />
                  </div>
                </div>
              ) : null}
              {/* No wallet connected */}
              {!address ? <Wallets /> : null}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>
    </div>
  );
};

export default ConnectWallet;
