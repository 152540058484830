import React, { createRef, useContext, useEffect, useState } from 'react';
import tw from 'twin.macro';
import Button from '../../../components/Button';
import opensea from '../../../assets/images/opensea.png';
import { useNavigate, useParams } from 'react-router-dom';
import SecondaryButton from '../../../components/SecondaryButton';
import { DarkModeContext } from '../../../hooks/hooks';
import ProjectImageUpload from '../../../components/ProjectImageUpload';
import openseaDark from '../../../assets/images/opensea-dark.png';
import store from '../../../store/store';
import InputText from '../../../components/InputText';
// import { ProjectViews } from '../id';
import { ProjectLoadingState } from '../../../store/projectSlice';
import ErrorMessage from '../../../components/ErrorMessage';
import DateTimePicker from './missions/DateTimePicker/DateTimePicker';
import SubmitButton from '../../../components/SubmitButton';
import Modal from '../../../components/Modal';
import Spinner from '../../../components/Spinner';
import DeleteProjectModal from './settings/DeleteProjectModal';

const Section = tw.div`my-4 flex flex-col`;
const Label = tw.label`my-2 dark:text-darkText`;

const EditProject = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const darkModeContext = useContext(DarkModeContext);

  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const deleteProject = store((s) => s.projectSlice.deleteProject);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);
  const editProject = store((s) => s.projectSlice.editProject);
  const errorType = store((s) => s.projectSlice.errorType);
  const errorMessage = store((s) => s.projectSlice.errorMessage);
  const loadingType = store((s) => s.projectSlice.loadingType);

  const [modalOpen, setModalOpen] = useState(false);
  const [projectName, setProjectName] = useState(currentProjectData?.name ?? '');
  const [projectDomains, setProjectDomains] = useState(currentProjectData?.domains?.join(', ') ?? '');
  const [image, setImage] = useState(currentProjectData?.imageUrl ?? '');

  const handleImageUpload = (imageUrl) => {
    setImage(imageUrl);
  };

  useEffect(() => {
    getProjectMissions(id);
  }, []);

  const socials = [
    {
      name: 'OpenSea',
      image: darkModeContext.darkMode ? openseaDark : opensea,
      connected: false,
    },
  ];

  const submitEditProject = () => {
    const domains = projectDomains ? projectDomains.replace(/\s+/g, '').split(',') : [];
    editProject(
      id,
      currentProjectData?.teamId,
      projectName,
      currentProjectData?.phaseId,
      domains,
      image ?? currentProjectData?.imageUrl,
      () => navigate(`/projects/${id}`)
    );
  };

  const handleDeleteProject = async () => {
    const response = await deleteProject(id);
    if (response) {
      setModalOpen(false);
      navigate('/projects');
    }
  };

  useEffect(() => {
    if (currentProjectData) {
      setProjectName(currentProjectData.name);
      setImage(currentProjectData.imageUrl);
    }
  }, [currentProjectData]);

  const imageRef = createRef<HTMLInputElement>();

  return (
    <div>
      <DeleteProjectModal
        modalOpen={modalOpen}
        setModalOpen={setModalOpen}
        currentProjectData={currentProjectData}
        handleDeleteProject={handleDeleteProject}
      />
      <h1 className="mt-10 text-xl font-medium dark:text-darkText">Project Settings</h1>
      <p className="mt-4 dark:text-darkSecondaryText">View and edit your project's settings</p>
      <Section>
        <Label>Project name</Label>
        <InputText
          type="text"
          placeholder="0xE5af...6956"
          value={projectName}
          onChange={(e) => setProjectName(e.target.value)}
        />
      </Section>
      <Section>
        <Label>Project domains</Label>
        <InputText
          type="text"
          placeholder="yourproject.xyz"
          value={projectDomains}
          onChange={(e) => setProjectDomains(e.target.value)}
        />
      </Section>
      {/* TODO project description, avatar, social links */}
      {/* <Section>
        <Label>Project description</Label>

        <textarea
          name="url"
          id="url"
          rows={4}
          className="p-2  border border-grey-100 dark:bg-darkSecondary border border-gray-200 dark:border-gray-600 focus:outline-none  focus:border-gray-300  dark:focus:border-gray-300 rounded-md dark:caret-darkText"
          placeholder="Project description"
        ></textarea>
      </Section> */}
      <Section>
        <ProjectImageUpload image={image} onImageUpload={handleImageUpload} ref={imageRef} />
      </Section>
      {/* TODO social links if we add them  */}
      {/* <Section>
        <Label>Add your social links</Label>
        {socials.map((social, i) => (
          <div
            key={social.name}
            className="flex flex-row border dark:border-gray-600 rounded-md px-4 py-4 my-2 items-center"
          >
            <img className="h-5" src={social.image} alt="social" />
            <p className="ml-2 font-bold dark:text-darkText">{social.name}</p>
            {social.connected ? (
              <p className="ml-auto font-bold dark:!text-white  text-plutoblue-500 py-1 px-3">Connected</p>
            ) : (
              <SecondaryButton
                text={'Link'}
                additionalStyle="ml-auto font-bold dark:!border-none dark:!text-white dark:!bg-darkButton text-plutoblue-500 py-1 px-3"
              />
            )}
          </div>
        ))}
      </Section> */}
      <hr className="mt-10 dark:border-gray-600" />
      {errorType === ProjectLoadingState.EDIT_PROJECT && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <div className="mb-10" />
      <div className="flex flex-row justify-between ">
        <Button text={'Back'} additionalStyle="self-start" onClick={() => navigate(`/projects/${id}`)} />
        <SubmitButton
          disabled={loadingType === ProjectLoadingState.EDIT_PROJECT}
          className="bg-plutoblue-500 text-white"
          onClick={() => submitEditProject()}
        >
          Save {loadingType === ProjectLoadingState.EDIT_PROJECT && <Spinner color={'white'} />}
        </SubmitButton>
      </div>
      <p className="dark:text-darkText text-2xl  mt-12 mb-1">Danger Zone</p>
      <div className="border border-red-700  p-4 md:p-10 rounded-md">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="flex flex-col">
            <p className="dark:text-darkText mb-1 font-bold">Delete this Project</p>
            <p className="dark:text-darkText text-sm md:text-base">
              Once you delete a project, there is no going back. Please be certain.
            </p>
          </div>

          <SubmitButton
            className="border border-red-700 !bg-transparent hover:!bg-red-700 hover:!text-white !text-red-700 !mt-8 md:mt-0 transition-all"
            onClick={() => setModalOpen(true)}
          >
            Delete Project
          </SubmitButton>
        </div>
      </div>
    </div>
  );
};

export default EditProject;
