import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';

export enum EthMissionLoadingState {
  NULL,
  NEW_ETH_BALANCE,
  EDIT_ETH_BALANCE,
}

export interface EthMissionSlice {
  ethBalanceMissions: any;
  loadingType: EthMissionLoadingState;
  loadingMessage: string;
  errorType: EthMissionLoadingState;
  errorMessage: string;
  resetState: () => void;
  resetLoading: () => void;
  newEthBalanceMission: (project_id: string, min_eth_balance_in_ether: string) => Promise<string>;
  getEthBalanceMission: (mission_id: string) => Promise<any>;
  editEthBalanceMission: (mission_id: string, min_eth_balance_in_ether: string) => Promise<any>;
}

const initalState = {
  loadingType: EthMissionLoadingState.NULL,
  loadingMessage: '',
  errorType: EthMissionLoadingState.NULL,
  errorMessage: '',
  ethBalanceMissions: null,
};

export const ethMissionSlice: EthMissionSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newEthBalanceMission: async (project_id, min_eth_balance_in_ether) => {
    getState().resetLoading();
    try {
      setState({ loadingType: EthMissionLoadingState.NEW_ETH_BALANCE });
      const response = await axios.post(`/missions/eth_balance`, {
        project_id,
        min_eth_balance_in_ether,
      });
      if (response && response.status === 200) {
        setState({ loadingType: EthMissionLoadingState.NULL });
        return response.data.mission_id;
      }
    } catch (e) {
      console.log('newEthBalanceMission error: ', e);
    }
  },
  getEthBalanceMission: async (mission_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: EthMissionLoadingState.NEW_ETH_BALANCE });
      const response = await axios.get(`/missions/eth_balance/${mission_id}`);
      if (response && response.status === 200) {
        setState({ ethBalanceMissions: response.data, loadingType: EthMissionLoadingState.NULL });
        return response.data;
      }
    } catch (e) {
      console.log('getEthBalanceMission error: ', e);
      return null;
    }
  },
  editEthBalanceMission: async (mission_id, min_eth_balance_in_ether) => {
    getState().resetLoading();
    try {
      setState({ loadingType: EthMissionLoadingState.EDIT_ETH_BALANCE });
      const response = await axios.put(`/missions/eth_balance/${mission_id}`, { min_eth_balance_in_ether });
      if (response && response.status === 200) {
        setState({ loadingType: EthMissionLoadingState.NULL });
        return response;
      }
    } catch (e) {
      console.log('getEthBalanceMission error: ', e);
      return null;
    }
  }, // api.Put("/missions/eth_balance/:id", Edit)
  resetLoading: () => {
    setState({
      loadingType: EthMissionLoadingState.NULL,
      loadingMessage: '',
      errorType: EthMissionLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
