import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import Button from '../../../../components/Button';
import RequiredAsterisk from '../../../../components/RequiredAsterisk';
import TextInput from '../../../../components/TextInput';
import { LOCAL_STORAGE_TYPES } from '../../../../types/shared';

const Section = tw.div`my-2`;
const Label = tw.label`my-4`;
const InfoLabel = tw.label`mb-2 text-xs`;
const Input = tw.input`dark:bg-darkSecondary p-2 rounded-md mb-4 border border-gray-500  focus:ring-gray-500  focus:border-gray-500`;

interface WalletIntegrationProps {
  inline?: boolean;
  ethBalance: string;
  setEthBalance: React.Dispatch<React.SetStateAction<string>>;
}

const WalletIntegration = ({ inline = false, ethBalance, setEthBalance }: WalletIntegrationProps) => {
  const { id } = useParams();
  const updateLocalStorage = (e) => {
    localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_ETHEREUM_MISSION + id, e.target.value);
    setEthBalance(e.target.value);
  };
  return (
    <div className="dark:text-darkText mr-4">
      <div className="rounded-lg p-1 md:p-0">
        <Section className="flex flex-col md:w-[50%]">
          <p className="text-lg mb-3">
            {`Required ETH Balance`}
            <RequiredAsterisk />
          </p>
          <input
            type="number"
            name="url"
            id="url"
            min="0"
            onWheel={(e) => e.currentTarget.blur()}
            value={ethBalance}
            className="truncate dark:text-darkText dark:placeholder:text-gray-500 dark:bg-darkSecondary p-2 border border-gray-200 rounded-md mb-1.5 dark:border-gray-600 focus:outline-none focus:border-gray-300  dark:focus:border-gray-300 dark:caret-darkText"
            placeholder="0.5 ETH"
            onChange={(e) => updateLocalStorage(e)}
          />
          <label className="text-xs text-darkSecondaryText">
            You can require users who register to hold a minimum amount of ETH in their wallet.
          </label>
        </Section>
        {!inline && <hr className="mt-6 mb-4" />}
      </div>
    </div>
  );
};

export default WalletIntegration;
