import { Navigate, Outlet } from 'react-router-dom';
import { Footer } from './footer';
import Header from './header';
import Sidebar from './sidebar';
import plutoLogo from '../../assets/images/logo-black.png';
import { useEffect, useState } from 'react';
import store from '../../store/store';
import { useNavigate } from 'react-router-dom';
import { AccountLoadingState } from '../../store/accountSlice';

enum LayoutType {
  Default,
  None,
}

interface AppProps {
  pageLayout: LayoutType;
  headerBgStyle: String;
}

const ApplicationLayout = ({ pageLayout, headerBgStyle }: AppProps) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const fetchIsUserSignedIn = store((s) => s.accountSlice.fetchIsUserSignedIn);
  const isUserSignedIn = store((s) => s.accountSlice.isUserSignedIn);
  // none layout removes all layout styles
  let bodyStyles = '';

  const navigate = useNavigate();

  useEffect(() => {
    // This is serving as auth "protected route"
    const awaitUserSignIn = async () => {
      const authed = await fetchIsUserSignedIn();
      if (!authed) {
        navigate('/connect');
      }
    };
    // only call if user is not signed in
    if (!isUserSignedIn) {
      awaitUserSignIn();
    }
  }, []);

  // default layout has basic layout styles
  if (pageLayout === LayoutType.Default) {
    bodyStyles = 'dark:bg-dark mt-20 lg:max-w-6xl md:mx-4 lg:mx-28 flex flex-col px-4 py-4';
  }

  let globalStyles = 'dark:bg-dark bg-white overflow-x-hidden w-screen h-screen max-w-full';

  return (
    <div className={globalStyles} id="appContainer">
      <Header setSidebarOpen={setSidebarOpen} headerBgStyle={headerBgStyle} />
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={() => setSidebarOpen(!sidebarOpen)} />
      <div className={bodyStyles}>
        {/* PAGE CONTENT */}
        <Outlet />
      </div>
    </div>
  );
};

export { LayoutType };
export default ApplicationLayout;
