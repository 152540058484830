import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import { Tag } from '../../../../async/tags/tags';
import Modal from '../../../../components/Modal';
import SubmitButton from '../../../../components/SubmitButton';
import store from '../../../../store/store';
import CreateTag from './CreateTag';
import Select from 'react-select';
import { multiSelectStyles } from './MultiSelectStyle';
import { LOCAL_STORAGE_TYPES } from '../../../../types/shared';
import { DarkModeContext } from '../../../../hooks/hooks';

const Label = tw.label`mt-4 dark:text-white font-bold font-flexa text-black`;

interface TagMintGatingProps {
  currentTags: null | Tag[];
  setCurrentTags: React.Dispatch<React.SetStateAction<Tag[]>>;
}

const TagMintGating = ({ currentTags, setCurrentTags }) => {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [linkCommunityOpen, setLinkCommunityOpen] = useState(false);
  const tags = store((s) => s.tagSlice.tags);
  const getTags = store((s) => s.tagSlice.getTags);

  const darkModeContext = useContext(DarkModeContext);

  useEffect(() => {
    getTags(id);
  }, [id]);

  return (
    <>
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <CreateTag setOpen={setModalOpen} currentTags={currentTags} setCurrentTags={setCurrentTags} />
      </Modal>{' '}
      <Label>Mint Gating</Label>
      <p className="dark:text-white text-gray-700">
        Users from your community database can be used to gate access to your mint. Select an existing tagged user group
        or import from a CSV.
      </p>
      {tags.length > 0 || (currentTags && currentTags.length > 0) ? (
        <div className="w-3/4 mt-2">
          <Select
            maxMenuHeight={150}
            isMulti
            value={currentTags}
            defaultValue={currentTags}
            onChange={setCurrentTags}
            options={tags}
            isOptionDisabled={() => currentTags?.length >= 10}
            getOptionLabel={(tag) => tag.name}
            getOptionValue={(tag) => tag.name}
            styles={darkModeContext.darkMode && multiSelectStyles}
          />
        </div>
      ) : null}
      <div className="flex flex-row mb-8">
        {/* {(!currentTag || currentTag.length < 1) && tags.length > 0 && (
          <div className="relative">
            <button
              onClick={() => setLinkCommunityOpen(!linkCommunityOpen)}
              className="text-xs px-3 py-2 md:px-3 md:py-2 rounded-full font-bold mr-4 text-white bg-[#07074E] dark:bg-darkButton dark:border-none mt-4"
            >
              Link an Existing Community
            </button>

            {linkCommunityOpen && (
              <div
                className="absolute mt-2 w-80 rounded-md shadow-lg dark:bg-gray-700 dark:border dark:border-white bg-white ring-1 dark:ring-gray-500 ring-black ring-opacity-5 py-1 focus:outline-none"
                role="menu"
                aria-orientation="vertical"
                aria-labelledby="user-menu-button"
              >
                <div className="">
                  <p className="font-2xl mt-2 mb-4 px-4 dark:text-white font-flexa">Community Tags</p>
                  {tags.map((tag, i) => (
                    <a
                      key={i}
                      onClick={() => {
                        setLinkCommunityOpen(!linkCommunityOpen);
                        setCurrentTag([tag]);
                      }}
                      className="cursor-pointer hover:bg-gray-800 px-4 block py-2 dark:text-white text-sm text-gray-700"
                      role="menuitem"
                      id="user-menu-item-0"
                    >
                      <div className="flex flex-row">
                        <span className="mr-auto">{tag.name}</span>
                        <span className="ml-auto">245 Members</span>
                      </div>
                    </a>
                  ))}
                </div>
              </div>
            )}
          </div>
        )} */}

        <button
          onClick={() => setModalOpen(true)}
          className="text-xs px-3 py-2 md:px-3 md:py-2 rounded-full font-bold bg-white text-black dark:border-none border border-1 mt-4"
        >
          Create Tag
        </button>

        {/* {currentTag && currentTag.length ? (
          <div className="flex flex-col">
            <Label className="underline">Community</Label>
      

            <div className="flex flex-row text-center ">
              {currentTag.map((tag, i) => (
                <span key={i} className="dark:text-white text-sm">
                  {tag.name}
                  {i < currentTag.length - 1 ? <span>,&nbsp;</span> : ''}
                </span>
              ))}
            </div>
            <SubmitButton
              className="self-start !py-1 !px-2"
              onClick={() => {
                setCurrentTag(null);
              }}
            >
              Remove
            </SubmitButton>
          </div>
        ) : null} */}
      </div>
    </>
  );
};

export default TagMintGating;
