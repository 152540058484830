import { UniversalSlice, universalSlice } from './universalSlice';
import create from 'zustand';
import debounce from 'lodash/debounce';
import { withLenses } from '@dhmk/zustand-lens';
import { accountSlice, AccountSlice } from './accountSlice';
import { projectSlice, ProjectSlice } from './projectSlice';
import { walletModalSlice, WalletModalSlice } from './walletModalSlice';
import { discordMissionSlice, DiscordMissionSlice } from './discordMissionSlice';
import { contractSlice, ContractSlice } from './contractSlice';
import { phaseSlice, PhaseSlice } from './phasesSlice';
import { ethMissionSlice, EthMissionSlice } from './ethMissionSlice';
import { twitterMissionSlice, TwitterMissionSlice } from './twitterMissionSlice';
import { userSlice, UserSlice } from './userSlice';
import { tagSlice, TagSlice } from './tagSlice';

export type Store = {
  universalSlice: UniversalSlice;
  accountSlice: AccountSlice;
  projectSlice: ProjectSlice;
  walletModalSlice: WalletModalSlice;
  discordMissionSlice: DiscordMissionSlice;
  contractSlice: ContractSlice;
  phaseSlice: PhaseSlice;
  ethMissionSlice: EthMissionSlice;
  twitterMissionSlice: TwitterMissionSlice;
  userSlice: UserSlice;
  tagSlice: TagSlice;
};

const store = create<Store>(
  withLenses({
    universalSlice,
    accountSlice,
    projectSlice,
    walletModalSlice,
    discordMissionSlice,
    contractSlice,
    phaseSlice,
    ethMissionSlice,
    twitterMissionSlice,
    userSlice,
    tagSlice,
  })
);

store.subscribe(
  debounce((state) => {
    (window as any).PL = state;
  }, 1000)
);
(window as any).PL = store.getState();

export default store;
