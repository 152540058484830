import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SelectDropdown } from '../../../components/SelectDropdown';
import { ProjectPhases } from '../../../store/projectSlice';
import store from '../../../store/store';
import { LOCAL_STORAGE_TYPES } from '../../../types/shared';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import { Button, Tooltip } from '@mantine/core';
import { DarkModeContext } from '../../../hooks/hooks';
import { IconContext } from 'react-icons';

interface MintFunctionsProps {
  currentContract: any;
  projectPhase: ProjectPhases;
  phaseFunctions: any;
  setPhaseFunctions: React.Dispatch<React.SetStateAction<any>>;
}

const MintFunctions = ({ currentContract, projectPhase, phaseFunctions, setPhaseFunctions }: MintFunctionsProps) => {
  const { id } = useParams();
  const contractPhaseMappings = store((s) => s.contractSlice.contractPhaseMappings);
  const getContractPhaseMappings = store((s) => s.contractSlice.getContractPhaseMappings);
  const darkModeContext = useContext(DarkModeContext);

  useEffect(() => {
    if (!contractPhaseMappings) {
      getContractPhaseMappings();
    }
  }, []);

  let currentContractPhaseMappings =
    contractPhaseMappings && contractPhaseMappings?.filter((func) => func?.phase_kind === projectPhase);

  // filter list of contract functions for only functions for this phase
  let phaseContractFunctions =
    currentContract && currentContract.func_map?.filter((func) => func?.phase_kind === projectPhase);

  let showMintFunctions =
    currentContractPhaseMappings &&
    currentContractPhaseMappings.length > 0 &&
    phaseContractFunctions &&
    phaseContractFunctions.length > 0;

  return (
    <>
      {showMintFunctions ? (
        <div className="flex flex-col">
          {/* <p className="text-white mb-4">Select Contract Functions</p> */}
          {currentContractPhaseMappings.map((phase, i) => (
            <div key={i} className="flex flex-row mb-2 items-center">
              <span className="grow shrink-0 dark:text-darkText min-w-[25%] items-center">
                {phase?.mapping_key}{' '}
                <Tooltip label={phase?.description}>
                  <Button className="!text-white !m-0 !p-0 hover:!bg-transparent">
                    {/* <IconContext.Provider value={{ color: darkModeContext.darkMode ? 'blue' : 'black' }}> */}
                    <IoMdInformationCircleOutline size={16} fill={darkModeContext.darkMode ? 'white' : 'black'} />
                    {/* </IconContext.Provider> */}
                  </Button>
                </Tooltip>
              </span>
              <SelectDropdown
                value={phaseFunctions.hasOwnProperty(phase?.mapping_key) ? phaseFunctions[phase?.mapping_key] : ''}
                onChange={(e) => {
                  setPhaseFunctions({ ...phaseFunctions, [phase?.mapping_key]: e.target.value });
                  localStorage.setItem(
                    LOCAL_STORAGE_TYPES.PLUTO_PHASE_FUNCTIONS + id,
                    JSON.stringify({ ...phaseFunctions, [phase?.mapping_key]: e.target.value })
                  );
                }}
              >
                <option key={0} value={''}></option>
                {phaseContractFunctions
                  ?.filter((o) => o.mapping_key === phase?.mapping_key)
                  ?.map((func, i) => (
                    <option key={i + 1} value={func.function}>
                      {func.function}
                    </option>
                  ))}
              </SelectDropdown>
            </div>
          ))}
        </div>
      ) : null}
    </>
  );
};

export default MintFunctions;
