import React from 'react';
import { useLocation, useParams } from 'react-router-dom';
import tw from 'twin.macro';
import TextInput from '../../../../components/TextInput';
import TwitterAuth from './TwitterAuth';

const Section = tw.div`my-2`;
const Label = tw.label`my-4`;
const InfoLabel = tw.label`mb-2 text-xs`;
const Input = tw.input`dark:bg-darkSecondary p-2 rounded-md mb-4 border border-gray-500  focus:ring-gray-500  focus:border-gray-500`;

interface TwitterProps {
  inline?: boolean;
}

const TwitterIntegration = ({ inline = false }: TwitterProps) => {
  let location = useLocation();

  return (
    <div className="dark:text-darkText mb-2 mr-4">
      <TwitterAuth slug={location.pathname} />
      {/* <div className="rounded-lg  p-2 md:p-2">
        {!inline && (
          <Section className="">
            <div className="relative flex">
              <div className="mt-1">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  className="focus:ring-plutoorange-500 h-4 w-4 text-plutoorange-500 bg-plutoorange-500 border-gray-300 rounded accent-plutoblue-500 dark:accent-plutoorange-500"
                />
              </div>

              <div className="ml-3 text-sm">
                <label
                  htmlFor="comments"
                  className="text-lg dark:text-darkText"
                >
                  Twitter Requirements
                </label>
                <p id="comments-description" className="text-gray-500">
                  Require users to sign into Twitter before registering for your
                  list.
                </p>
              </div>
            </div>

            <hr className="mt-6 mb-4" />
          </Section>
        )}
        <div className="flex flex-col md:flex-row">
          <p className="mr-1">
            {`Require user to have a specific role on the above server? (Both fields required)`}{' '}
            <span className="underline text-blue-500 hover:cursor-pointer">
              {' '}
              Click here for instructions
            </span>
          </p>
        </div>
        <Section className="md:w-[50%]">
          <label
            htmlFor="company-website"
            className="block text-sm text-gray-500 "
          >
            Must Follow Account(s)
          </label>
          <div className="mt-1 flex rounded-md shadow-sm">
            <span className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-200 dark:border-gray-500 dark:bg-gray-600 bg-gray-50 ">
              @
            </span>
            <input
              type="text"
              name="company-website"
              id="company-website"
              className="truncate dark:placeholder:text-gray-500 dark:bg-darkSecondary p-2 border border-gray-200  dark:border-gray-500 focus:outline-none  focus:border-gray-300  dark:focus:border-gray-300 w-full rounded-r-md"
              placeholder="devloper"
            />
          </div>
        </Section>
        <Section className="md:w-[50%]">
          <p className="mt-4 mb-2">Must have tagged user in a tweet</p>
          <TextInput inputPlaceholder={'@devloper'} />
        </Section>
        {inline && <hr className="mt-6 mb-4" />}
      </div> */}
    </div>
  );
};

export default TwitterIntegration;
