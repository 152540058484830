import React from 'react';
import styles from '../marketing.module.css';

const info = [
  {
    title: 'Mintkit UI is flexible and full of features',
    description: `Mintkit's customizable UI component includes the best practices for launching NFT communities. Launch a
  gated mint, protect your mint from bots, add your friends and family with their twitter or discord
  handles, and more.`,
  },
  {
    title: 'Bots vs Machine Learning',
    description: `     Our custom built machine learning model blocks bots from all stages of your mint. Mintkit uses on-chain
  and off-chain signals to validate wallet history and ensure your community is legitimate.`,
  },
  {
    title: 'Your brand, website and contract',
    description: `   The Mintkit SDK simplifies the process of building an NFT mint, while giving you the flexibility to
  build your own brand, website and contract mechanics. Get creative.`,
  },
  {
    title: 'Private minting without collecting wallet IDs',
    description: `     Simply collect an email, twitter or discord from fans, friends and family. When it comes time to mint,
  they can authenticate with any Web2 method and use any wallet address.`,
  },
  {
    title: 'Works with the tools you already use',
    description: `Already have a Premint community? Mintkit works great with
  Premint! Easily import your audience and start your mint. Already have a smart contract? Easily add Mintkit's 5 line of
  code integration to your contract.`,
  },
  {
    title: 'Flexible Open Source Integration',
    description: ` Use what you need and skip the rest. No lock-in. Integrate with 5 lines of javascript and 5 lines of
  solidity.`,
  },
];

const TediousProblemsSection = () => {
  return (
    <div className="bg-marketingBg text-white flex flex-col items-center place-content-center pt-18 pb-36">
      {/* max-w-6xl p-2 lg:p-6 */}
      <div className="px-8 xl:px-64">
        <h2 className="text-center text-4xl md:text-6xl  mb-12 ">
          Skip the tedious parts,
          <br className="hidden md:block" /> start <span>minting.</span>
        </h2>
        <h2 className="text-center text-marketingText text-xl mb-8 md:mb-16 ">
          Launch your integration on testnet in minutes and mint your first token.
        </h2>
        <ul role="list" className="grid grid-cols-1 gap-6 md:gap-12 sm:grid-cols-2 md:grid-cols-3">
          {info.map((text) => (
            <li
              key={text.title}
              className="col-span-1 flex flex-col border border-white border-opacity-10 rounded-xl bg-white bg-opacity-5 text-center hover:bg-[rgb(245, 245, 245)] hover:-translate-y-2 hover:shadow-md transition-all"
            >
              <div className="text-left flex flex-1 flex-col p-8">
                <p className="mb-4 text-lg font-semibold leading-5">{text.title}</p>
                <p className="text-sm">{text.description}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TediousProblemsSection;
