import React, { Ref, useContext, useEffect, useRef, useState } from 'react';
import Button from '../../../../components/Button';
import ethereumLogo from '../../../assets/images/eth-logo.png';
import SecondaryButton from '../../../../components/SecondaryButton';
import Modal from '../../../../components/Modal';
import FileDragDrop from '../../../../components/FileDragDrop';
import { TiDelete } from 'react-icons/ti';
import TextInput from '../../../../components/TextInput';
import tw from 'twin.macro';
import '../../projects.css';
import store from '../../../../store/store';
import AddUser from './AddUser';
import ImportCsv from './ImportCsv';
import { useParams } from 'react-router-dom';
import CreateTag from './CreateTag';
import EditUser from './EditUser';
import DeleteUser from './DeleteUser';
import { Table, Tooltip, Button as MButton } from '@mantine/core';
import DeleteUserTag from './DeleteUserTag';
import { FaRegTimesCircle } from 'react-icons/fa';
import AddTagsToUser from './AddTagsToUser';
import Pagination from './Pagination';
import { formatEthereumIdentity, formatProvider, formatWalletAddress } from '../../../../utils/helper';
import { DarkModeContext } from '../../../../hooks/hooks';
import { IoMdInformationCircleOutline } from 'react-icons/io';

const Row = tw.td`text-xs p-2 border-b text-left  dark:border-b-darkSecondaryText`;

export enum ModalTypes {
  IMPORT_CSV,
  ADD_USER,
  CREATE_TAG,
  EDIT_USER,
  DELETE_USER,
  DELETE_TAG,
  ADD_TAG_TO_USER,
}

const CommunityUsers = () => {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState(false);
  const [currentModal, setCurrentModal] = useState<ModalTypes | null>(null);

  const projectUsers = store((s) => s.userSlice.projectUsers);
  const getTags = store((s) => s.tagSlice.getTags);
  const getUsers = store((s) => s.userSlice.getUsers);

  const [file, setFile] = useState('');
  const fileRef = useRef();

  const columns: Array<string> = ['Name', 'Wallet', 'Discord', 'Twitter', 'Tags', ''];
  const [allRowsChecked, setAllRowsChecked] = useState(false);
  // const [data, setData] = useState([]);
  // User is currently on this page
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedTag, setSelectedTag] = useState(null);

  const darkModeContext = useContext(DarkModeContext);
  // TODO - uncomment when we eventually add in checkbox functionality
  // // Check/ UnCheck All Table Items
  // const checkAll = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   let tempList = data;

  //   tempList.map((user) => (user.checked = e.target.checked));

  //   setAllRowsChecked(e.target.checked);
  //   setData(tempList);
  // };

  // TODO - uncomment when we eventually add in checkbox functionality
  // // Update List Item's state and Master Checkbox State
  // const onItemCheck = (e: React.ChangeEvent<HTMLInputElement>, item) => {
  //   let tempList = data;

  //   let newList = tempList.map((row) => {
  //     if (row.id === item.id) {
  //       row.checked = e.target.checked;
  //     }
  //     return row;
  //   });

  //   //To Control Master Checkbox State
  //   const totalItems = data.length;
  //   const totalCheckedItems = newList.filter((e) => e.checked).length;

  //   setAllRowsChecked(totalItems === totalCheckedItems);
  //   setData(newList);
  // };

  // TODO - uncomment when we eventually add in checkbox functionality
  // const deleteRow = (selected) => {
  //   let tempList = data;
  //   let newList = tempList.filter((row) => row.id !== selected.id);
  //   setData(newList);
  // };

  // TODO - uncomment when we eventually add in checkbox functionality
  // useEffect(() => {
  //   if (projectUsers.length > 0) {
  //     let addChecked = projectUsers.map((u) => ({ ...u, checked: false }));
  //     setData(addChecked);
  //   }
  // }, [projectUsers]);

  console.log('projectUsers:', projectUsers);

  const getIdentity = (userData, provider) => {
    switch (provider) {
      case 'ethereum':
        let eth = userData.identities.find((el) => el.provider === 'ethereum');
        return eth ? formatWalletAddress(formatEthereumIdentity(eth.id)) : '';
      case 'twitter':
        let twitter = userData.identities.find((el) => el.provider === 'twitter');
        return twitter ? formatProvider(twitter.display_name) : '';
      case 'discord':
        let discord = userData.identities.find((el) => el.provider === 'discord');
        return discord ? formatProvider(discord.display_name) : '';
      default:
        return false;
    }
  };

  const getVerified = (userData, provider) => {
    switch (provider) {
      case 'twitter':
        let twitter = userData.identities.find((el) => el.provider === 'twitter');
        return twitter ? twitter.verified : '';
      case 'discord':
        let discord = userData.identities.find((el) => el.provider === 'discord');
        return discord ? discord.verified : '';
      default:
        return false;
    }
  };

  useEffect(() => {
    getTags(id);
    getUsers(id);
  }, [id]);

  return (
    <div>
      <Modal open={modalOpen} setOpen={setModalOpen}>
        <>
          {currentModal === ModalTypes.ADD_USER && <AddUser setOpen={setModalOpen} />}
          {currentModal === ModalTypes.IMPORT_CSV && <ImportCsv setOpen={setModalOpen} />}
          {currentModal === ModalTypes.CREATE_TAG && <CreateTag setOpen={setModalOpen} />}
          {currentModal === ModalTypes.EDIT_USER && <EditUser setOpen={setModalOpen} selectedUser={selectedUser} />}
          {currentModal === ModalTypes.DELETE_USER && (
            <DeleteUser setOpen={setModalOpen} selectedUser={selectedUser} projectId={id} />
          )}
          {currentModal === ModalTypes.ADD_TAG_TO_USER && (
            <AddTagsToUser setOpen={setModalOpen} selectedUser={selectedUser} projectId={id} />
          )}
          {currentModal === ModalTypes.DELETE_TAG && (
            <DeleteUserTag
              setOpen={setModalOpen}
              selectedUser={selectedUser}
              selectedTag={selectedTag}
              projectId={id}
            />
          )}
        </>
      </Modal>
      <div className="flex items-center mb-4">
        <div className="ml-auto space-x-4">
          {/* TODO when functionality is added */}
          {/* <Button
            text={'Export allowlist'}
            additionalStyle={'place-self-end ml-auto mt-4  bg-plutoblue-500 text-white font-bold'}
          />
          <SecondaryButton
            text={'Import CSV'}
            onClick={() => {
              setModalOpen(true);
              setCurrentModal(ModalTypes.IMPORT_CSV);
            }}
          /> */}
          <Button
            text={'Create Tag'}
            additionalStyle={'bg-plutoblue-500 text-white font-bold'}
            onClick={() => {
              setModalOpen(true);
              setCurrentModal(ModalTypes.CREATE_TAG);
            }}
          />
          <Button
            text={'Add User'}
            additionalStyle={'bg-plutoblue-500 text-white font-bold'}
            onClick={() => {
              setModalOpen(true);
              setCurrentModal(ModalTypes.ADD_USER);
            }}
          />
        </div>
      </div>

      {/* TABLE */}
      <div className="overflow-x-scroll hide-scrollbar">
        <Table className="border-b dark:border-b-darkSecondaryText  dark:text-darkText w-full ">
          <thead>
            <tr>
              {/* <th className="p-2 text-bold border-b   dark:border-b-darkSecondaryText  text-lg items-center place-content-center">
                <input
                  type="checkbox"
                  className="dark:accent-plutoorange-500 accent-plutoblue-500"
                  checked={allRowsChecked}
                  onChange={(e) => checkAll(e)}
                />
              </th> */}
              {columns.map((header, i) => (
                <th key={i} className="p-2 text-bold border-b  text-left dark:border-b-darkSecondaryText text-lg">
                  {header}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {projectUsers.map((user, i) => (
              <tr key={i} className=" group dark:hover:bg-darkSecondary hover:bg-lightHover">
                {/* <td className="p-2 border-b   dark:border-b-darkSecondaryText text-center  ">
                  <input
                    type="checkbox"
                    checked={user.checked}
                    className="dark:accent-plutoorange-500 accent-plutoblue-500 hover:cursor-pointer"
                    onChange={(e) => onItemCheck(e, user)}
                  />
                </td> */}
                <Row>{user.name}</Row>
                <Row>{getIdentity(user, 'ethereum')}</Row>
                <Row>
                  <div className="flex flex-row items-center">
                    {getIdentity(user, 'discord')}
                    {getIdentity(user, 'discord') && (
                      <>
                        {!getVerified(user, 'discord') && (
                          <Tooltip label="unverified">
                            <MButton className="!text-white !m-0 !p-0 hover:!bg-transparent">
                              <IoMdInformationCircleOutline size={16} fill="red" className="ml-0.5" />
                            </MButton>
                          </Tooltip>
                        )}
                      </>
                    )}
                  </div>
                </Row>
                <Row>
                  <div className="flex flex-row items-center">
                    {getIdentity(user, 'twitter')}
                    {getIdentity(user, 'twitter') && (
                      <Tooltip label={getVerified(user, 'twitter') ? 'verified' : 'unverified'}>
                        <MButton className="!text-white !m-0 !p-0 hover:!bg-transparent">
                          <IoMdInformationCircleOutline
                            size={16}
                            fill={getVerified(user, 'twitter') ? 'green' : 'red'}
                            className="ml-0.5"
                          />
                        </MButton>
                      </Tooltip>
                    )}
                  </div>
                </Row>
                <Row>
                  {user.tags.length > 0 &&
                    user.tags.map((tag, i) => (
                      <span
                        key={i}
                        className="inline-flex items-center rounded-full mr-1 dark:bg-gray-600 bg-gray-200 py-0.5 pl-2 pr-0.5 text-xs font-medium dark:text-darkText  hover:cursor-pointer"
                      >
                        {tag.name}
                        <button
                          type="button"
                          className="ml-0.5 inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full dark:text-darkSecondaryText hover:bg-red-500 dark:hover:text-white  focus:text-white focus:outline-none"
                          onClick={() => {
                            setSelectedTag(tag);
                            setSelectedUser(user);
                            setCurrentModal(ModalTypes.DELETE_TAG);
                            setModalOpen(true);
                          }}
                        >
                          <span className="sr-only">Remove small option</span>
                          <svg className="h-2 w-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                            <path strokeLinecap="round" strokeWidth="1.5" d="M1 1l6 6m0-6L1 7" />
                          </svg>
                        </button>
                      </span>
                    ))}
                  <span className="inline-flex items-center rounded-full mr-1 bg-gray-200 dark:bg-gray-600 py-0.5 px-0.5 text-xs font-medium dark:text-darkText  hover:cursor-pointer">
                    <button
                      type="button"
                      className=" inline-flex h-4 w-4 flex-shrink-0 items-center justify-center rounded-full dark:text-darkSecondaryText   focus:text-white focus:outline-none"
                      onClick={() => {
                        setSelectedUser(user);
                        setCurrentModal(ModalTypes.ADD_TAG_TO_USER);
                        setModalOpen(true);
                      }}
                    >
                      <span className="sr-only">add tags to user</span>
                      <svg
                        className={`${darkModeContext.darkMode ? 'fill-white' : 'fill-black'}`}
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 24 24"
                      >
                        <path d="M24 9h-9v-9h-6v9h-9v6h9v9h6v-9h9z" />
                      </svg>
                    </button>
                  </span>
                </Row>
                <Row>
                  <div className="flex flex-col space-y-1 lg:float-right lg:block">
                    <SecondaryButton
                      text={'Edit'}
                      additionalStyle={'invisible group-hover:visible !text-xs !font-normal  !py-1 !px-3'}
                      onClick={() => {
                        setModalOpen(true);
                        setCurrentModal(ModalTypes.EDIT_USER);
                        setSelectedUser(user);
                      }}
                    />

                    <button
                      className="invisible group-hover:visible ml-2 bg-red-500 text-white text-xs  !py-1 !px-3 rounded-full"
                      onClick={() => {
                        setSelectedUser(user);
                        setCurrentModal(ModalTypes.DELETE_USER);
                        setModalOpen(true);
                      }}
                    >
                      Delete
                    </button>
                  </div>
                </Row>
              </tr>
            ))}
          </tbody>
          {/* </table> */}
        </Table>
      </div>
      <Pagination />
    </div>
  );
};

export default CommunityUsers;
