import { classNames } from '../../../utils/helper';

const PhaseData = [
  {
    phase: 'early',
    name: 'Early Access',
    color: 'bg-plutoblue-500',
    projectId: process.env.EARLY_ACCESS_PROJECT_ID,
    projectName: 'mintkit-early-access',
  },
  {
    phase: 'private',
    name: 'Private Mint',
    color: 'bg-plutoorange-500',
    projectId: process.env.PRIVATE_MINT_PROJECT_ID,
    projectName: 'mintkit-private-mint',
  },
  {
    phase: 'public',
    name: 'Public Mint',
    color: 'bg-white',
    projectId: process.env.PUBLIC_MINT_PROJECT_ID,
    projectName: 'mintkit-public-mint',
  },
];

const ProjectPhaseSelector = ({ currentPhase, updatePhase }) => {
  return (
    <div>
      <h3 className=" mb-6 text-lg font-semibold">PHASE</h3>
      <div className="flex flex-col">
        <ul role="list" className="-mb-8">
          {PhaseData.map((phase, itemIdx) => (
            <li key={phase.name}>
              <div className="relative pb-8">
                {itemIdx !== PhaseData.length - 1 ? (
                  <span
                    className={`absolute top-4 left-3 md:left-4 -ml-px h-full w-0.5 bg-marketingSecondary`}
                    aria-hidden="true"
                  />
                ) : null}
                <div className="relative flex space-x-3 hover:cursor-pointer" onClick={() => updatePhase(phase)}>
                  <div>
                    {/* hover:outline  hover:outline-offset-2 hover:outline-white ring-8  ring-marketingBg  */}
                    <span
                      className={classNames(
                        `bg-marketingSecondary h-6 w-6 md:h-8 md:w-8 rounded-full flex items-center justify-center active:scale-90 hover:ring-white ring-offset-2 ring-offset-marketingBg ring-2 hover:cursor-pointer transition-all 
                        ${currentPhase === phase?.phase ? 'ring-white' : 'ring-marketingBg'}`
                      )}
                    ></span>
                  </div>
                  <div className="flex min-w-0 flex-1 justify-between space-x-4 hover:scale-105 transition-all">
                    <p className="text-sm  md:text-lg ">{phase.name}</p>
                  </div>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ProjectPhaseSelector;
