import React, { useEffect, useState } from 'react';
import { FaCheck } from 'react-icons/fa';
import { useLocation, useParams } from 'react-router-dom';
import { useStore } from 'zustand';
import store from '../../../../store/store';
import { LOCAL_STORAGE_TYPES } from '../../../../types/shared';

const DiscordAuth = () => {
  const newDiscordMission = store((s) => s.discordMissionSlice.newDiscordMission);
  const { id } = useParams();

  const [discordAuthed, setDiscordAuthed] = useState(false);
  const projectMissions = store((s) => s.projectSlice.projectMissions);
  const getProjectMissions = store((s) => s.projectSlice.getProjectMissions);
  const guildRoleData = store((s) => s.discordMissionSlice.guildRoleData);

  useEffect(() => {
    const checkDiscordStatus = async () => {
      try {
        let discord = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + id);
        if (discord) {
          let r = projectMissions && projectMissions.length > 0 ? projectMissions : await getProjectMissions(id);
          if (r) {
            let completedAuth = r.find((mission) => mission.id === discord);
            if (completedAuth && completedAuth.ready) {
              setDiscordAuthed(true);
            }
          }
        }
      } catch (e) {}
    };

    checkDiscordStatus();
  }, []);

  const connected = discordAuthed || (guildRoleData && guildRoleData.guilds.length > 0);

  return (
    <>
      {connected ? (
        <div className="flex flex-row items-center">
          <FaCheck className="text-green-500 mr-2" /> <span>Connected</span>
        </div>
      ) : (
        <button
          onClick={() => newDiscordMission(id, window.location.href.split('?')[0])}
          className={`text-xs lg:text-sm dark:border-none border dark:bg-darkButton dark:darkButtonText bg-plutoblue-500 text-white px-6 py-2 rounded-full text-center`}
        >
          Connect
        </button>
      )}
    </>
  );
};

export default DiscordAuth;
