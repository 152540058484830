import React from 'react';
import { WagmiConfig, createClient, defaultChains, configureChains } from 'wagmi';
import { InjectedConnector } from 'wagmi/connectors/injected';
import { MetaMaskConnector } from 'wagmi/connectors/metaMask';
import { WalletConnectConnector } from 'wagmi/connectors/walletConnect';
import { CoinbaseWalletConnector } from 'wagmi/connectors/coinbaseWallet';
import { alchemyProvider } from 'wagmi/providers/alchemy';

const alchemyId = 'eW01hYxRpRNewnqkS92AP40W9CFPJS8D'; // TODO make .env variable

const { chains, provider, webSocketProvider } = configureChains(defaultChains, [
  alchemyProvider({ apiKey: alchemyId }),
]);

const client = createClient({
  autoConnect: true,

  connectors: [
    new InjectedConnector({
      chains,
      options: {
        shimDisconnect: true,
        shimChainChangedDisconnect: true,
      },
    }),
    new MetaMaskConnector({
      options: {
        shimDisconnect: true,
        shimChainChangedDisconnect: true,
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        qrcode: true,
      },
    }),
    new CoinbaseWalletConnector({
      options: {
        appName: 'mintkit.app',
        jsonRpcUrl: 'https://eth-mainnet.alchemyapi.io/v2/' + alchemyId,
      },
    }),
  ],
  provider,
  webSocketProvider,
});

type ProviderProps = {
  children?: React.ReactNode;
};

const Provider = ({ children }: ProviderProps) => {
  return <WagmiConfig client={client}>{children}</WagmiConfig>;
};

export { Provider };
