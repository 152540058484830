import React, { useState } from 'react';
import { FaRegCopy } from 'react-icons/fa';
import { BsCheck2Circle } from 'react-icons/bs';

interface CopyApiProps {
  text: string;
}
const CopyApi = ({ text }: CopyApiProps) => {
  const [effect, setEffect] = useState(false);
  return (
    <div className="flex flex-col items-start">
      <span className="bg-white dark:bg-darkSecondary border border-gray-600 text-black dark:text-white px-4 py-2 rounded-md flex flex-row items-center">
        {text}

        {effect ? (
          <div className="flex flex-row items-center">
            <BsCheck2Circle size={20} className={`ml-6`} />
            <p className="ml-2 text-sm">Copied!</p>
          </div>
        ) : (
          <div
            className="flex flex-row items-center hover:cursor-pointer"
            onClick={() => {
              setEffect(true);
              setTimeout(() => setEffect(false), 1500);
              navigator.clipboard.writeText(text);
            }}
          >
            <FaRegCopy size={20} className={`ml-6`} />
            <p className="ml-2 text-sm">Copy</p>
          </div>
        )}
      </span>
    </div>
  );
};

export default CopyApi;
