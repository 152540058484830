import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckBox from '../../../../components/CheckBox';
import SubmitButton from '../../../../components/SubmitButton';
import TextInput from '../../../../components/TextInput';
import store from '../../../../store/store';
import Select, { StylesConfig } from 'react-select';

interface AddUserProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: any;
  projectId: string;
}

const DeleteUser = ({ setOpen, selectedUser, projectId }: AddUserProps) => {
  const deleteUser = store((s) => s.userSlice.deleteUser);
  const handleDelete = async () => {
    if (selectedUser.id) {
      const r = await deleteUser(selectedUser.id, projectId);
      if (r) setOpen(false);
    }
  };

  return (
    <div className="p-3 md:p-0">
      <p className="font-flexa text-4xl dark:text-darkText">Delete User</p>
      <p className="text-sm mb-2 dark:text-darkSecondaryText mt-1">Are you sure you want to delete this user?</p>
      <p className="font-bold text-sm mb-4 dark:text-darkSecondaryText">
        {' '}
        <span className="dark:text-darkText">{`User: `}</span>
        {selectedUser?.name}
      </p>

      <div className="mt-8">
        <SubmitButton className="mt-4" onClick={() => handleDelete()}>
          Delete
        </SubmitButton>
      </div>
    </div>
  );
};

export default DeleteUser;
