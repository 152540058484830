import React, { useState } from 'react';
import InputText from '../../../../../components/InputText';
import Modal from '../../../../../components/Modal';
import Spinner from '../../../../../components/Spinner';
import SubmitButton from '../../../../../components/SubmitButton';
import { PhaseLoadingState } from '../../../../../store/phasesSlice';
import { ProjectPhases } from '../../../../../store/projectSlice';
import store from '../../../../../store/store';

interface DeletePhaseModal {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  deletePhase: () => Promise<void>;
  phaseDeleteText: string;
  setPhaseDeleteText: React.Dispatch<React.SetStateAction<string>>;
}
const DeletePhaseModal = ({ open, setOpen, phase, deletePhase, phaseDeleteText, setPhaseDeleteText }) => {
  const loadingType = store((s) => s.phaseSlice.loadingType);

  return (
    <Modal open={open} setOpen={setOpen}>
      <div>
        <p className="font-flexa text-4xl dark:text-darkText">{`Delete Phase`}</p>
        <p className="text-sm mb-4 mt-4 dark:text-darkSecondaryText">
          {`Are you sure you want to delete the ${phase} phase? This cannot be undone.`}
        </p>
        <p className="text-sm mb-4 mt-4 dark:text-darkSecondaryText">{`Type "${phase}" in the box below to confirm`}</p>

        <InputText
          type="text"
          placeholder="phase name"
          value={phaseDeleteText}
          onChange={(e) => setPhaseDeleteText(e.target.value)}
        />

        <div className="flex flex-row">
          <SubmitButton
            className={`mt-4 ml-auto ${
              loadingType === PhaseLoadingState.DELETE_PHASE || phaseDeleteText !== phase ? 'opacity-25' : ''
            }`}
            disabled={loadingType === PhaseLoadingState.DELETE_PHASE || phaseDeleteText !== phase}
            onClick={() => deletePhase()}
          >
            Delete {loadingType === PhaseLoadingState.DELETE_PHASE && <Spinner color={'white'} />}
          </SubmitButton>
        </div>
      </div>
    </Modal>
  );
};

export default DeletePhaseModal;
