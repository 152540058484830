import React, { useEffect, useState } from 'react';
import tw from 'twin.macro';
import copyLogo from '../../assets/images/copy-logo.svg';
import CopyApi from './components/missions/copyapi';
import CodeSnippetComponent from '../../components/CodeSnippetComponent';
import FileDragDrop from '../../components/FileDragDrop';
import { useNavigate, useParams } from 'react-router-dom';

import { HiExternalLink } from 'react-icons/hi';
import { RadioGroup } from '@headlessui/react';
import TextInput from '../../components/TextInput';
import TextAreaInput from '../../components/TextAreaInput';
import store from '../../store/store';
import InputText from '../../components/InputText';
import SubmitButton from '../../components/SubmitButton';
import { SelectDropdown } from '../../components/SelectDropdown';
import { formatEthereumIdentity, formatWalletAddress, getContractNetwork, getEtherescanLink } from '../../utils/helper';
import Spinner from '../../components/Spinner';
import { ContractLoadingState } from '../../store/contractSlice';
import ErrorMessage from '../../components/ErrorMessage';
import { LOCAL_STORAGE_TYPES, NetworkDbName, NetworkTypes } from '../../types/shared';
import { Contract } from '../../async/contracts/contracts';
import { TbExternalLink } from 'react-icons/tb';
import RequiredAsterisk from '../../components/RequiredAsterisk';
import DemoContract from './components/contract/DemoContract';
import { CheckMark } from './components/contract/CheckMark';
import {
  allChains,
  defaultChains, // mainnet, rinkeby, etc.
  defaultL2Chains, // arbitrum, optimism
} from 'wagmi';

const Section = tw.div`mt-8`;
const Label = tw.label`mt-8 dark:text-white font-flexa font-bold text-black`;
const StepButton = tw.button`text-xs px-4 py-2 md:px-8 md:py-3 rounded-full font-bold `;
const InstructionText = tw.p`text-xs md:text-base dark:text-white text-gray-700 mb-4`;

enum ContractTypes {
  NEW_CONTRACT = 'New Contract',
  LINKED_CONTRACT = 'Linked Contract',
  DEMO_CONTRACT = 'Demo Contract',
}

const ContractSetup = () => {
  const navigate = useNavigate();
  const { id, phase } = useParams();

  const soliditySourceCode = `<script type="text/javascript"
src="https://mintkit.app/sdk.js?k=${id}">
</script>`;

  const [network, setNetwork] = useState(NetworkDbName.ETHEREUM);
  const networks = [
    { name: NetworkTypes.MAINNET, value: NetworkDbName.ETHEREUM },
    { name: NetworkTypes.GOERLI, value: NetworkDbName.GOERLI },
  ];
  const [contractType, setContractType] = useState(ContractTypes.NEW_CONTRACT);

  const [currentlyActiveContract, setCurrentlyActiveContract] = useState('');
  const [contractAddress, setContractAddress] = useState('');
  const [jsonAbi, setJsonAbi] = useState('');

  // Contract Functions
  const [supply, setSupplyFunction] = useState('');
  const [totalSupply, setTotalSupplyFunction] = useState('');
  const [mintFunction, setMintFunction] = useState('');
  const [linkContractOpen, setLinkContractOpen] = useState(false);
  const [currentContract, setCurrentContract] = useState<null | Contract>(null);

  // Demo Contract

  const [demoContractDeployed, setDemoContractDeployed] = useState(false);

  // Store
  const projectsContracts = store((s) => s.contractSlice.projectsContracts);
  const newContract = store((s) => s.contractSlice.newContract);
  const editContract = store((s) => s.contractSlice.editContract);
  const getContractAbi = store((s) => s.contractSlice.getContractAbi);
  const getContracts = store((s) => s.contractSlice.getContracts);
  const abiFunctions = store((s) => s.contractSlice.abiFunctions);
  const loadingType = store((s) => s.contractSlice.loadingType);
  const errorType = store((s) => s.contractSlice.errorType);
  const errorMessage = store((s) => s.contractSlice.errorMessage);

  let func_map = {
    supply: supply,
    totalSupply: totalSupply,
    mint: mintFunction,
  };

  // enable next button if theyve previously added a contract, or if all the requirements are set
  let nextButtonEnabled =
    ((contractAddress && jsonAbi) || currentContract ? true : false) && loadingType === ContractLoadingState.NULL;

  // Create OR Edit contract
  const createContract = async () => {
    try {
      let contractID = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id);
      // if contractID in localStorage, it means the contract has already been created, so theyre editing
      if (contractID) {
        const r = await editContract(contractID, `${network}://${contractAddress}`, jsonAbi);
        if (r) {
          navigate(`/projects/${id}/mintSetup/${phase}`);
        }
      } else {
        const res = await newContract(id, `${network}://${contractAddress}`, jsonAbi);

        if (res) {
          await navigate(`/projects/${id}/mintSetup/${phase}`);
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id, res.id);
        }
      }
    } catch (e) {}
  };

  const selectContract = (projectContract) => {
    setLinkContractOpen(!linkContractOpen);
    setCurrentContract(projectContract);
    localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id, projectContract.id);
  };

  const navigateNextButton = () => {
    if (contractType === ContractTypes.DEMO_CONTRACT || currentContract) {
      getContracts(id);
      setContractAddress('');
      setJsonAbi('');
      setDemoContractDeployed(false);
      navigate(`/projects/${id}/mintSetup/${phase}`);
    } else {
      createContract();
    }
  };

  // get current project contracts and check if theyre editing one
  useEffect(() => {
    if (projectsContracts.length < 1) {
      getContracts(id);
    } else {
      try {
        let contractID = localStorage.getItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id);

        // contractId in localstorage - this means user navigated back to the page, maybe with back button
        if (contractID) {
          let current = projectsContracts.find((projectContract) => projectContract.id === contractID);
          if (current) {
            setContractAddress(formatEthereumIdentity(current?.address));
            setMintFunction(current?.func_map?.mint);
            setSupplyFunction(current?.func_map?.supply);
            setTotalSupplyFunction(current?.func_map?.totalSupply);
            setJsonAbi(current?.abi);
            getContractAbi(current?.abi, 'abi');
          }
        }
      } catch (e) {}
    }
  }, []);

  useEffect(() => {
    if (projectsContracts.length > 0) {
      setContractType(ContractTypes.LINKED_CONTRACT);
    }
  }, [projectsContracts.length]);

  return (
    <div className="md:mx-auto max-w-xl">
      <h2 className="text-6xl font-flexa font-bold dark:text-darkText mb-4">Link a Contract</h2>
      <p className="dark:text-darkText">
        Write any contract functionality then add the Mintkit integration to enable powerful access control
        capabilities.
      </p>

      {/* {contractData && contractData.length > 0 ? (
        <>
          <p className="text-white mb-4 mt-8">Select Contract to edit</p>
          <div className="flex flex-row mb-2 items-center">
            <SelectDropdown
              value={currentlyActiveContract}
              onChange={(e) => setCurrentlyActiveContract(e.target.value)}
            >
              <option key={0} value={''}></option>
              {contractData.map((func, i) => (
                <option key={i + 1} value={func.id}>
                  {formatEthereumIdentity(func.address)}
                </option>
              ))}
            </SelectDropdown>
          </div>
        </>
      ) : null} */}

      <div className=" mt-4 flex flex-col">
        <Section>
          <Label>Choose your Network</Label>
          <InstructionText>
            We recommend deploying the integration on Goerli Testnet for the first time. You can update your integration
            to Mainnet after verifying on Goerli. Need Goerli eth? Check our{' '}
            <a
              href="https://docs.pluto.xyz"
              target="_blank"
              className="text-white whitespace-nowrap bg-gray-700 pb-[2px] px-2 rounded-full"
            >
              guide.
              <HiExternalLink size={16} className="ml-[1px] pb-[2px] inline" />
            </a>
          </InstructionText>
          <RadioGroup
            value={network}
            onChange={(e) => {
              setNetwork(e);
              if (e === NetworkDbName.ETHEREUM && contractType === ContractTypes.DEMO_CONTRACT) {
                setContractType(ContractTypes.NEW_CONTRACT);
              }
            }}
            className="grid grid-cols-2 md:grid-cols-3 gap-10"
          >
            {networks.map((option, i) => (
              <RadioGroup.Option
                key={option.name}
                value={option.value}
                className={`py-3 px-3 border dark:bg-darkSecondary dark:text-white ${
                  network === option.value ? 'dark:border-white border-black' : 'dark:border-gray-600 border-gray-100'
                } rounded-md hover:cursor-pointer dark:hover:bg-gray-500 hover:bg-gray-200`}
              >
                {network === option.value ? <CheckMark /> : <div className="h-5 w-5 " />}
                <p className="pt-10">{option.name}</p>
              </RadioGroup.Option>
            ))}
          </RadioGroup>
        </Section>
        <Section>
          <Label>What type of contract?</Label>
          <p className="dark:text-white text-gray-700 mb-4">
            Mintkit has an example contract deployed on testnet that can be used to test your integration. If you're
            just getting started, choose the demo contract. If you already have your contract written, you can link it
            now.
          </p>
          <RadioGroup
            value={contractType}
            onChange={setContractType}
            className="grid grid-cols-2 md:grid-cols-3 gap-10"
          >
            {Object.values(ContractTypes).map((option) =>
              (network === NetworkDbName.ETHEREUM && option === ContractTypes.DEMO_CONTRACT) ||
              (option === ContractTypes.LINKED_CONTRACT && !projectsContracts.length) ? null : (
                <div key={option}>
                  <RadioGroup.Option
                    value={option}
                    className={`py-3 px-3 border dark:bg-darkSecondary dark:text-white ${
                      contractType === option
                        ? 'dark:border-white border-black'
                        : 'dark:border-gray-600 border-gray-100'
                    } rounded-md hover:cursor-pointer dark:hover:bg-gray-500 hover:bg-gray-200`}
                  >
                    {contractType === option ? <CheckMark /> : <div className="h-5 w-5 " />}
                    <p className="pt-10">{option}</p>
                  </RadioGroup.Option>
                </div>
              )
            )}
          </RadioGroup>
        </Section>

        {contractType === ContractTypes.NEW_CONTRACT ? (
          <div>
            <Section>
              <Label>Solidity Integration</Label>
              <InstructionText>
                Add the following solidity to your contract prior to deployment. See our{' '}
                <a
                  href="https://docs.pluto.xyz"
                  className=" text-white whitespace-nowrap bg-gray-700 pb-[2px] px-2 rounded-full"
                >
                  guide
                  <HiExternalLink size={16} className="ml-[1px] pb-[2px] inline" />
                </a>{' '}
                for an in-depth tutorial.
              </InstructionText>
              <CodeSnippetComponent language="vbscript-html" codeString={soliditySourceCode} />
            </Section>

            <Section>
              <Label>Contract Address</Label>
              <InstructionText>
                The address of your deployed contract. Add your contract address once you've completed your integration
                and deployed your contract.
              </InstructionText>
              <InputText
                type="text"
                placeholder="0xE5af...6956"
                value={contractAddress}
                onChange={(e) => setContractAddress(e.target.value)}
              />
              {/* TO DO -  When API accepts addresses, uncomment this */}
              {/* <SubmitButton
              onClick={() => getContractAbi(contractAddress, 'address')}
            >
              Fetch Contract
            </SubmitButton> */}
              {/* TO DO -  check if the contract is actually deployed */}
              {/* <div className="flex flex-row items-center mt-4">
              <div className="mr-auto dark:text-darkSecondaryText">
                <div className="relative mt-3">
                  <span className="absolute animate-ping top-1 bg-red-100 h-4 w-4 rounded-full flex items-center justify-center" />
                  <span className="absolute top-2 left-1 l-2 h-2 w-2 bg-red-400 rounded-full flex items-center justify-center" />
                </div>
                <p className="text-gray-400 ml-8 mt-3">
                  Awaiting contract deployment - Not deployed
                </p>
              </div>
              <button className="text-xs px-2 py-1 md:px-2 md:py-1 rounded-full font-bold ml-auto bg-white text-black border border-1 mt-4">
                Re-check deployment
              </button>
            </div> */}
            </Section>
            <Section>
              <Label>Upload Contract ABI</Label>
              <InstructionText>
                Upload your ABI to link Mintkit with a mint function. You can choose any compatible function.
              </InstructionText>
              <TextAreaInput
                value={jsonAbi}
                rows={4}
                placeholder="paste ABI here"
                onChange={(e) => setJsonAbi(e.target.value)}
              />
            </Section>
          </div>
        ) : null}
        {contractType === ContractTypes.LINKED_CONTRACT && (
          <>
            <Label>
              Link a Contract
              <RequiredAsterisk />
            </Label>
            <p className="dark:text-white text-gray-700">
              If you'd like to use a contract you've already added, link that contract here.
            </p>
            {currentContract ? (
              <>
                <div className="flex flex-col dark:text-darkText">
                  <p className="dark:text-darkText mt-4 font-bold underline">Linked Contract:</p>
                  <div className="flex flex-row dark:text-darkText">
                    <span className="mr-auto">{`${getContractNetwork(currentContract.address)} Contract`}</span>
                    <a
                      target="_blank"
                      href={getEtherescanLink(
                        formatEthereumIdentity(currentContract.address),
                        getContractNetwork(currentContract.address)
                      )}
                      className="ml-auto flex flex-row items-center hover:cursor-pointer hover:underline"
                      rel="noreferrer"
                    >
                      {formatWalletAddress(formatEthereumIdentity(currentContract.address))}
                      <TbExternalLink className="text-gray-500 text-gray-400 ml-1 hover:underline" />
                    </a>
                  </div>
                  <SubmitButton
                    className="self-start !py-1 !px-2"
                    onClick={() => {
                      setCurrentContract(null);
                      localStorage.removeItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id);
                    }}
                  >
                    Unlink
                  </SubmitButton>
                </div>
              </>
            ) : (
              <div className="flex flex-row mb-8">
                <div className="relative">
                  {projectsContracts.length > 0 && (
                    <button
                      onClick={() => setLinkContractOpen(!linkContractOpen)}
                      className="text-xs px-3 py-2 md:px-3 md:py-2 rounded-full font-bold mr-4 text-white bg-[#07074E] dark:bg-darkButton dark:border-none mt-4"
                    >
                      Link an Existing Contract
                    </button>
                  )}
                  {linkContractOpen && projectsContracts.length > 0 && (
                    <div
                      className="absolute mt-2 w-80 rounded-md shadow-lg dark:bg-gray-700 dark:border dark:border-white bg-white ring-1 dark:ring-gray-500 ring-black ring-opacity-5 py-1 focus:outline-none transition-all"
                      role="menu"
                      aria-orientation="vertical"
                      aria-labelledby="user-menu-button"
                    >
                      <div>
                        <p className="font-2xl mt-2 mb-4 px-4 dark:text-white font-flexa">Contracts</p>

                        {projectsContracts.map((projectContract, i) => (
                          <a
                            onClick={() => {
                              selectContract(projectContract);

                              // if user selects a contract from a network thats not selected, switch the network
                              switchNetwork(network, getContractNetwork(projectContract.address), setNetwork);
                            }}
                            className="cursor-pointer hover:bg-lightHover dark:hover:bg-gray-800 px-4 block py-2 dark:text-white text-sm text-gray-700 transition-all"
                            role="menuitem"
                            id="user-menu-item-0"
                            key={projectContract.id}
                          >
                            <div className="flex flex-row">
                              <span className="mr-auto">
                                {`${getContractNetwork(projectContract.address)} Contract`}
                              </span>
                              <span className="ml-auto">
                                {formatWalletAddress(formatEthereumIdentity(projectContract.address))}
                              </span>
                            </div>
                          </a>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {contractType === ContractTypes.DEMO_CONTRACT && (
          <DemoContract
            contractAddress={contractAddress}
            setContractAddress={setContractAddress}
            jsonAbi={jsonAbi}
            setJsonAbi={setJsonAbi}
            demoContractDeployed={demoContractDeployed}
            setDemoContractDeployed={setDemoContractDeployed}
          />
        )}
      </div>
      {/* ERROR MESSAGES */}
      {errorType === ContractLoadingState.NEW_CONTRACT && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {errorType === ContractLoadingState.EDIT_CONTRACT && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {errorType === ContractLoadingState.UPLOAD_ABI && <ErrorMessage>{errorMessage}</ErrorMessage>}
      <div className="flex flex-row md:mt-48 mt-24">
        <StepButton onClick={() => navigate(-1)} className="mr-auto bg-white text-black border border-1">
          Back
        </StepButton>
        <StepButton
          disabled={!nextButtonEnabled}
          onClick={() => {
            navigateNextButton();
          }}
          className={`flex flex-row items-center ml-auto bg-[#07074E] dark:bg-darkButton text-white ${
            !nextButtonEnabled && 'opacity-25'
          }`}
        >
          Next {loadingType === ContractLoadingState.NEW_CONTRACT && <Spinner color={'white'} />}
        </StepButton>
      </div>
    </div>
  );
};

export default ContractSetup;

const switchNetwork = (currentNetwork, contractNetwork, setNetwork) => {
  switch (currentNetwork) {
    case NetworkDbName.ETHEREUM:
      if (contractNetwork !== 'Mainnet') {
        setNetwork(NetworkDbName.GOERLI);
      }
      return null;
    case NetworkDbName.GOERLI:
      if (contractNetwork !== 'goerli') {
        setNetwork(NetworkDbName.ETHEREUM);
      }
      return null;
    default:
      return null;
  }
};
