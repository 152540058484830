import React, { useState } from 'react';

export type ThemeTypes = 'Pluto' | 'Tea' | 'FrogOnALog' | 'KoolAid' | 'Chrome' | 'Sunshine';

const ThemeData = [
  { name: 'Tea', color: 'bg-[#C6D39C]' },
  { name: 'Pluto', color: 'bg-[#FFB0C1]' },
  { name: 'FrogOnALog', color: 'bg-[#BFBB98]' },
  { name: 'KoolAid', color: 'bg-[#DCABEB]' },
  { name: 'Chrome', color: 'bg-[#DEDEDE]' },
  { name: 'Sunshine', color: 'bg-[#FFE231]' },
];

const ThemeSelector = () => {
  const [currentTheme, setCurrentTheme] = useState<ThemeTypes>('Tea');
  const updateTheme = (theme) => {
    setCurrentTheme(theme);
    window.Pluto?.updateBaseTheme(theme);
  };

  return (
    <div>
      <h3 className="mb-6 text-lg font-semibold">THEME</h3>
      <div className="grid grid-cols-3 gap-6">
        {ThemeData.map((theme) => (
          <button
            key={theme.name}
            onClick={() => updateTheme(theme.name)}
            className={`${theme.color} ${
              currentTheme === theme.name ? 'ring-white' : 'ring-marketingBg'
            }  h-6 md:h-8 rounded-full px-3 md:px-4 active:scale-90 hover:ring-white ring-offset-2 ring-offset-marketingBg ring-2 hover:cursor-pointer transition-all`}
          />
        ))}
      </div>
    </div>
  );
};

export default ThemeSelector;
