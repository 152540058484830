import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import { NavigateFunction } from 'react-router';
import { EditUserRequest, IndexUserResponse, NewUserRequest, User } from '../async/users/users';

export enum UserLoadingState {
  NULL,
  NEW_USER,
  GET_USERS,
  DELETE_USER,
  EDIT_USER,
}

export interface UserSlice {
  loadingType: UserLoadingState;
  loadingMessage: string;
  errorType: UserLoadingState;
  errorMessage: string;
  projectUsers: User[];
  showNextButton: string;
  showPreviousButton: string;
  resetState: () => void;
  resetLoading: () => void;
  newUser: (
    project_id: string,
    name: string,
    tags: any[],
    eth_wallet_address?: string,
    twitter_screen_name?: string,
    discord_username?: string
  ) => Promise<any>;
  getUsers: (project_id: string, headers?: any) => Promise<any>;
  deleteUser: (user_id: string, project_id: string) => Promise<boolean>;
  editUser: (project_id: string, user_id: string, name: string) => Promise<any>;
}

const initalState = {
  loadingType: UserLoadingState.NULL,
  loadingMessage: '',
  errorType: UserLoadingState.NULL,
  errorMessage: '',
  projectUsers: [],
  showNextButton: '',
  showPreviousButton: '',
};

export const userSlice: UserSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newUser: async (projectId, name, tagIds, ethWalletAddress, twitterScreenName, discordUsername) => {
    getState().resetLoading();
    try {
      setState({ loadingType: UserLoadingState.NEW_USER });

      const body = NewUserRequest.create({
        projectId,
        name,
        tagIds,
        ethWalletAddress,
        twitterScreenName,
        discordUsername,
      });
      const response = await axios.post(`/users`, body);
      if (response && response.status === 200) {
        setState({ loadingType: UserLoadingState.NULL });
        getState().getUsers(projectId);

        return response.data;
      }
    } catch (e) {
      setState({
        loadingType: UserLoadingState.NULL,
        loadingMessage: '',
        errorType: UserLoadingState.NEW_USER,
        errorMessage: e.response?.data?.error,
      });
      console.debug('newUser error: ', e);
    }
  },
  getUsers: async (project_id, headers) => {
    getState().resetLoading();
    try {
      setState({ loadingType: UserLoadingState.GET_USERS });
      const response = await axios.get(`/projects/${project_id}/users`, {
        headers,
      });
      if (response && response.status === 200) {
        const data: IndexUserResponse = response.data;
        setState({
          projectUsers: data.data,
          showNextButton: data.after,
          showPreviousButton: data.before,
          loadingType: UserLoadingState.NULL,
        });
      }
    } catch (e) {
      setState({ loadingType: UserLoadingState.NULL });
      console.debug('getUsers error: ', e);
    }
  },
  deleteUser: async (user_id, project_id) => {
    getState().resetLoading();
    try {
      setState({ loadingType: UserLoadingState.DELETE_USER });
      const response = await axios.delete(`/users/${user_id}`);
      if (response && response.status === 200) {
        getState().getUsers(project_id);
        setState({ loadingType: UserLoadingState.NULL });
        return true;
      }
    } catch (e) {
      console.debug('deleteUser error: ', e);
      return false;
    }
  },
  editUser: async (project_id, user_id, name) => {
    getState().resetLoading();
    try {
      setState({ loadingType: UserLoadingState.EDIT_USER });
      // body
      const body = EditUserRequest.create({ projectId: project_id, name });
      const response = await axios.put(`/users/${user_id}`, body);
      if (response && response.status === 200) {
        getState().getUsers(project_id);
        setState({ loadingType: UserLoadingState.NULL });
        return response;
      }
    } catch (e) {
      console.debug('editUser error: ', e);
      return null;
    }
  },
  resetLoading: () => {
    setState({
      loadingType: UserLoadingState.NULL,
      loadingMessage: '',
      errorType: UserLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
