import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import { LOCAL_STORAGE_TYPES } from '../types/shared';
import { PhaseLoadingState } from './phasesSlice';

export enum OAuthErrors {
  OAUTH_FAILED_OAUTH = 'failed_oauth',
  OAUTH_DISCORD_GUILD_ID_MISSING = 'discord_guild_id_missing',
  OAUTH_DISCORD_ROLE_ID_MISSING = 'discord_role_id_missing',
  OAUTH_DISCORD_FAILED_TO_ASSIGN_ROLE = 'discord_failed_to_assign_role',
}

export enum DiscordMissionLoadingState {
  NULL,
  GUILD_LOADING,
  ROLE_LOADING,
  INVITE_LINK_LOADING,
  EDIT_DISCORD_MISSION,
}

export interface DiscordMissionSlice {
  discordMissions: any;
  guildRoleData: any;
  newDiscordMission: (slug: string, redirect_url: string) => Promise<void>;
  getDiscordMission: (discordMissionId: string) => Promise<void>;
  editDiscordMission: (
    discordMissionId: string,
    guildId: string,
    type: DiscordMissionLoadingState,
    roleId?: string,
    invite_link?: string
  ) => Promise<boolean>;
  clearMissionData: () => void;
  loadingType: DiscordMissionLoadingState;
  loadingMessage: string;
  errorType: DiscordMissionLoadingState;
  errorMessage: string;
  resetState: () => void;
  resetLoading: () => void;
}

const initalState = {
  discordMissions: [],
  guildRoleData: null,
  loadingType: DiscordMissionLoadingState.NULL,
  loadingMessage: '',
  errorType: DiscordMissionLoadingState.NULL,
  errorMessage: '',
};

export const discordMissionSlice: DiscordMissionSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  newDiscordMission: async (projectId, redirect_url) => {
    getState().resetLoading();
    try {
      const response = await axios.post(`/missions/discord`, {
        project_id: projectId,
        final_redirect_url: redirect_url,
      });
      if (response && response.status === 200) {
        try {
          localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_DISCORD_MISSION + projectId, response.data.mission_id);
        } catch (e) {}
        window.location.replace(response.data.redirect_url);
      }
    } catch (e) {
      return null;
    }
  },
  getDiscordMission: async (discordMissionId) => {
    getState().resetLoading();
    try {
      const response = await axios.get(`/missions/discord/${discordMissionId}`);
      if (response && response.status === 200) {
        setState({ guildRoleData: response.data });
      }
    } catch (e) {
      console.log(e);
    }
  },
  editDiscordMission: async (discordMissionId, guild_id, loadingType, role_id, invite_link) => {
    getState().resetLoading();

    try {
      setState({
        loadingType,
      });
      const response = await axios.put(`/missions/discord/${discordMissionId}`, {
        guild_id,
        role_id,
        invite_link,
      });
      if (response && response.status === 200) {
        setState({ loadingType: DiscordMissionLoadingState.NULL });
        getState().getDiscordMission(discordMissionId);
        return true;
      }
    } catch (e) {
      setState({
        errorMessage: e.response?.data?.error ?? '',
        errorType: DiscordMissionLoadingState.EDIT_DISCORD_MISSION,
        loadingType: DiscordMissionLoadingState.NULL,
      });
      console.log(e);
      return false;
    }
  },
  clearMissionData: () => {
    setState({
      guildRoleData: null,
    });
  },
  resetLoading: () => {
    setState({
      loadingType: DiscordMissionLoadingState.NULL,
      loadingMessage: '',
      errorType: DiscordMissionLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: () => {
    setState(initalState);
  },
}));
