import React from 'react';
import MarketingCodeSnippet from './MarketingCodeSnippet';
import { classNames } from '../../../utils/helper';
import { MarketingButton } from './MarketingButton';
import { useNavigate } from 'react-router-dom';

const timeline = [
  {
    id: 1,
    content: 'Get a project ID',
    description: 'Create an account and retrieve a project ID',
  },
  {
    id: 2,
    content: 'Install the Pluto NPM package',
    description: 'Add code to your environment for automatic updates',
  },
  {
    id: 3,
    content: 'Setup and customize the React component',
    description: `Drop the react component on your page and start a community. When you're ready to mint, our simple
    solidity integration guide enables you to mint privately and with bot protection.`,
  },
];

const GetStartedCodeSection = () => {
  const navigate = useNavigate();
  return (
    <div className="bg-marketingBg text-white">
      <div className="pt-20 pb-32  md:pt-36 xl:pb-52 px-8 xl:px-48 2xl:px-64">
        <h2 className=" text-5xl md:text-6xl text-center md:text-left">Setup Mintkit in minutes</h2>
        <p className="text-marketingText pt-4 md:pb-4 text-center md:text-left">
          It takes just minutes to create a project and add rich functionality to your community.
        </p>
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:space-x-36">
          <div className="mb-8 md:mb-0">
            <MarketingCodeSnippet />
          </div>
          <div className="mt-6 flow-root">
            <ul role="list" className="-mb-8">
              {timeline.map((item, itemIdx) => (
                <li key={item.id}>
                  <div className="relative pb-8">
                    {itemIdx !== timeline.length - 1 ? (
                      <span
                        className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-marketingSecondary"
                        aria-hidden="true"
                      />
                    ) : null}
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            'h-8 w-8 rounded-full flex items-center justify-center ring-8 bg-marketingSecondary ring-marketingBg'
                          )}
                        >
                          {item.id}
                        </span>
                      </div>
                      <div className="flex min-w-0 flex-1 justify-between space-x-4 ">
                        <div>
                          <p className="text-xl text-white">{item.content}</p>
                          <p className="text-marketingText">{item.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="w-full flex flex-row mt-8">
          <MarketingButton className="ml-auto" onClick={() => navigate('/projects')}>
            Try the demo
          </MarketingButton>
        </div>
      </div>
    </div>
  );
};

export default GetStartedCodeSection;
