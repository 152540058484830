import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import lightFavicon from './assets/images/light-favicon.ico';
import favicon from './assets/images/favicon.ico';

// dynamically set favicon
let icon = document.querySelector('link[rel="icon"]');

if (!icon) {
  icon = document.createElement('link');
  icon.rel = 'icon';
  document.getElementsByTagName('head')[0].appendChild(icon);
}

window.matchMedia('(prefers-color-scheme: light)').addEventListener('change', (event) => {
  const lightMode = event.matches ? 'light' : 'dark';
  if (lightMode) {
    icon.setAttribute('href', lightFavicon);
  } else {
    icon.setAttribute('href', favicon);
  }
});

// add plausible script
if (process.env.NODE_ENV === 'production') {
  const plausibleScript = document.createElement('script');
  plausibleScript.src = `https://plausible.io/js/script.js`;
  plausibleScript['data-domain'] = 'mintkit.app';
  plausibleScript.defer = true;
  document.head.appendChild(plausibleScript);
}

const sdkScript = document.createElement('script');
sdkScript.type = 'text/javascript';
sdkScript.src = `${process.env.SDK_SCRIPT_URL}?k=${process.env.EARLY_ACCESS_PROJECT_ID}`;
document.head.appendChild(sdkScript);
sdkScript.onload = () => {
  const root = ReactDOM.createRoot(document.getElementById('root'));

  root.render(<App />);
};
