import React, { forwardRef, useContext } from 'react';
import stockAvatar from '../../assets/images/avatar.png';
import darkStockAvatar from '../../assets/images/avatar-dark.png';
import { DarkModeContext } from '../../hooks/hooks';
import tw from 'twin.macro';
import Button from '../Button';
import ProjectImageComponent from './ProjectImage';

const Label = tw.label`my-2 dark:text-darkText`;
const ImageContainer = tw.div`flex flex-col  rounded-md`;
interface FileDragDropProps {
  image: string;

  onImageUpload: (imageURL: string) => void;
}

const acceptedFileTypes = ['image/jpg', 'image/jpeg', 'image/png'];

const ProjectImageUpload = forwardRef(({ image, onImageUpload }: FileDragDropProps, ref: any) => {
  const darkModeContext = useContext(DarkModeContext);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let acceptedFiles = e.target.files;
    const file = acceptedFiles ? acceptedFiles[0] : null;

    if (file && acceptedFileTypes.includes(file['type'])) {
      getBase64(file, (result) => {
        if (result) {
          onImageUpload(result);
        }
      });
    }
  };

  return (
    <div className="flex flex-col">
      <Label>Project Image</Label>

      <ImageContainer>
        <ProjectImageComponent
          src={image}
          containerClassname="hover:cursor-pointer"
          onClick={() => ref?.current?.click()}
        />

        <input
          type="file"
          onChange={(e) => handleChange(e)}
          ref={ref}
          style={{ display: 'none' }}
          accept={'image/*'}
          className=" dark:text-darkText"
        />
        <Button
          text={'Upload'}
          additionalStyle={`font-bold self-start text-sm mt-4 py-1 px-3 disabled:opacity-50`}
          onClick={() => ref?.current?.click()}
        />
      </ImageContainer>
    </div>
  );
});

export default ProjectImageUpload;

const getBase64 = (
  file: Blob,
  cb: {
    (result: any): void;
    (result: any): void;
    (arg0: string | ArrayBuffer | null): void;
  }
) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = function () {
    cb(reader.result);
  };
  reader.onerror = function (error) {
    console.log('Error: ', error);
  };
};
