import React, { useEffect, useState } from 'react';
import { TbExternalLink } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import { useNetwork, useSigner } from 'wagmi';
import ErrorMessage from '../../../../components/ErrorMessage';
import EtherscanLink from '../../../../components/EtherscanLink';
import InputText from '../../../../components/InputText';
import Spinner from '../../../../components/Spinner';
import SubmitButton from '../../../../components/SubmitButton';
import TextAreaInput from '../../../../components/TextAreaInput';
import { ContractLoadingState, DemoContractData } from '../../../../store/contractSlice';
import store from '../../../../store/store';
import { LOCAL_STORAGE_TYPES, NetworkDbName } from '../../../../types/shared';
import {
  formatEthereumIdentity,
  formatWalletAddress,
  getContractNetwork,
  getEtherescanLink,
} from '../../../../utils/helper';

const Section = tw.div`mt-8`;
const Label = tw.label`mt-8 dark:text-white font-flexa font-bold text-black`;
const InstructionText = tw.p`text-xs md:text-base dark:text-white text-gray-700 mb-4`;

interface DemoContractProps {
  contractAddress: string;
  setContractAddress: React.Dispatch<React.SetStateAction<string | null>>;
  jsonAbi: string;
  setJsonAbi: React.Dispatch<React.SetStateAction<string | null>>;
  demoContractDeployed: boolean;
  setDemoContractDeployed: React.Dispatch<React.SetStateAction<boolean>>;
}

const DemoContract = ({
  contractAddress,
  setContractAddress,
  jsonAbi,
  setJsonAbi,
  demoContractDeployed,
  setDemoContractDeployed,
}: DemoContractProps) => {
  const { id } = useParams();
  const { chain } = useNetwork();
  const { data: signer } = useSigner();
  const NewDemoContract = store((s) => s.contractSlice.NewDemoContract);
  const errorType = store((s) => s.contractSlice.errorType);
  const errorMessage = store((s) => s.contractSlice.errorMessage);
  const loadingType = store((s) => s.contractSlice.loadingType);
  const resetLoading = store((s) => s.contractSlice.resetLoading);
  const editContract = store((s) => s.contractSlice.editContract);
  const allProjectData = store((s) => s.projectSlice.allProjectData);
  const getAllProjects = store((s) => s.projectSlice.getAllProjects);

  const currentProject = allProjectData.find((project) => project.id === id);

  const tryDemoDeploy = async () => {
    if (!chain || chain.id !== 5 || !currentProject) return;
    const { contract, abi, public_signer_address, contract_id }: DemoContractData = await NewDemoContract(
      signer,
      currentProject
    );
    if (contract) {
      setContractAddress(contract.address);
      setJsonAbi(abi);
      const deploying = await contract.deployTransaction.wait();
      const r = await editContract(contract_id, `${NetworkDbName.GOERLI}://${contract.address}`, abi);
      if (r) {
        localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_CURRENT_CONTRACT + id, contract_id);
        setDemoContractDeployed(true);
        resetLoading();
      }
    }
  };

  useEffect(() => {
    getAllProjects();
  }, []);

  return (
    <div className="mt-8">
      <Label>Demo Contract</Label>

      <p className="dark:text-white text-gray-700">
        Use a demo contract for testing before you deploy your own contract
      </p>
      {chain?.id !== 5 && (
        <div className="text-red-500 text-sm italic mt-2 mb-6">
          {`Currently connected to ${
            chain?.network === 'homestead' ? 'mainnet' : chain?.network
          }. Please switch to the Goerli test network in your wallet to deploy the demo contract.`}
        </div>
      )}
      {!demoContractDeployed && (
        <SubmitButton
          className={`!mt-8 ${chain?.id !== 5 && 'opacity-25'}`}
          disabled={chain?.id !== 5 || loadingType === ContractLoadingState.DEPLOY_DEMO_CONTRACT}
          onClick={() => tryDemoDeploy()}
        >
          {loadingType === ContractLoadingState.DEPLOY_DEMO_CONTRACT ? (
            <>
              <span> Deploying...</span>
              <Spinner color={'white'} />
            </>
          ) : (
            <span> Deploy</span>
          )}
        </SubmitButton>
      )}
      {loadingType === ContractLoadingState.DEPLOY_DEMO_CONTRACT && (
        <p className="italic mt-2 text-sm dark:text-darkSecondaryText">
          Deploying... check your wallet for current status
        </p>
      )}
      {errorType === ContractLoadingState.DEPLOY_DEMO_CONTRACT && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {demoContractDeployed && (
        <p className="text-green-500 mb-2 mt-8 font-bold">
          Congratulations! The demo contract has been successfully deployed.
        </p>
      )}
      {demoContractDeployed && (
        <>
          <Section>
            <Label>Deployed Contract Address</Label>
            <InputText className="dark:!text-darkSecondaryText" type="text" value={contractAddress} readOnly={true} />
          </Section>
          <EtherscanLink
            href={`https://goerli.etherscan.io/address/${contractAddress}`}
            address="View on etherscan"
            className="mt-1"
          />
        </>
      )}
    </div>
  );
};

export default DemoContract;
