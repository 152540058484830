import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = () => {
  const location = useLocation();

  useEffect(() => {
    const ele = document.getElementById('appContainer');
    if (!ele) {
      setTimeout(() => {
        document.getElementById('appContainer')?.scrollTo(0, 0);
      }, 50);
    } else {
      ele.scrollTo(0, 0);
    }
  }, [location.pathname]);

  return null;
};

export default ScrollToTop;
