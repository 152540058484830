import React, { useState } from 'react';
import ProjectPhaseSelector from './ProjectPhaseSelector';
import ThemeSelector from './ThemeSelector';

const MintkitComponent = () => {
  const [currentProject, setCurrentProject] = useState(process.env.EARLY_ACCESS_PROJECT_ID);
  const [currentPhase, setCurrentPhase] = useState('early');

  const updatePhase = (phase) => {
    setCurrentProject(phase.projectId);
    setCurrentPhase(phase.phase);
    window?.Pluto?.updateProjectID(phase.projectId);
  };

  return (
    <div
      className={`text-black bg-marketingBg text-white mt-24 flex flex-col  pb-36 md:pb-48 px-8 lg:px-24 2xl:px-36 `}
    >
      <div className="grid grid-cols-12 gap-6 relative ">
        <div className="col-span-12 lg:col-span-6 mt-12 lg:mt-16  drop-shadow-xl flex place-content-evenly">
          <div id="mintkit-frame" />
        </div>
        <div className="col-span-12 lg:col-span-6  mt-16">
          <div>
            <div className="mb-8 text-center ">
              <p className="text-6xl  mb-12 tracking-[0.005em]">Shoot your shot</p>

              <p className="text-lg text-marketingText lg:px-16 2xl:px-36 mb-12 lg:mb-0">
                Launching your NFT project doesn’t have to be a challenge, Mintkit takes care of the boilerplate to get
                you up and running in minutes rather than months.
              </p>
            </div>
            <div className="flex flex-row justify-evenly sm:justify-around lg:justify-evenly">
              <ProjectPhaseSelector currentPhase={currentPhase} updatePhase={updatePhase} />
              <ThemeSelector />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MintkitComponent;
