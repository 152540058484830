import React, { useEffect, useRef, useState } from 'react';
import tw from 'twin.macro';
import copyLogo from '../../assets/images/copy-logo.svg';
import CopyApi from './components/missions/copyapi';
import CodeSnippetComponent from '../../components/CodeSnippetComponent';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import store from '../../store/store';
import Spinner from '../../components/Spinner';
import { useInterval } from '../../hooks/hooks';
import { ProjectPhases } from '../../store/projectSlice';
import ErrorMessage from '../../components/ErrorMessage';

const domSourceCode = `
  <div id="mintkit-frame"></div>
`;

const Section = tw.div`mt-4 flex flex-col`;
const Label = tw.label`mt-4 dark:text-white font-bold font-flexa text-black`;
const InstructionText = tw.p`text-xs md:text-base dark:text-white text-gray-700 mb-4`;
const StepButton = tw.button`text-xs px-4 py-2 md:px-8 md:py-3 rounded-full font-bold `;

const ProjectSetup = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { id, phase } = useParams();
  const [integrationMessage, setIntegrationMessage] = useState('');
  const ref = useRef(null);

  const getProject = store((s) => s.projectSlice.getProject);
  const currentProjectData = store((s) => s.projectSlice.currentProjectData);

  const importSourceCode = `<script type="text/javascript"
src="https://pluto.xyz/sdk.js?k=${id}">
</script>`;

  useEffect(() => {
    getProject(id);
  }, [id]);

  // Poll to see if plutokit integration status has updated
  useInterval(() => {
    getProject(id);
  }, 5000);

  const routeNavigation = (id, phase) => {
    switch (phase) {
      case ProjectPhases.EARLY_ACCESS:
        return `/projects/${id}/edit`;
      case ProjectPhases.PRIVATE_MINT:
        return `/projects/${id}/contractSetup/${phase}`;
      case ProjectPhases.PUBLIC_MINT:
        return `/projects/${id}/mintSetup/${phase}`;
      default:
        return `/projects/${id}/edit`;
    }
  };

  const refreshConnection = async () => {
    setIntegrationMessage('');
    const r = await getProject(id);
    if (r && !currentProjectData.componentsLastSeenAt) setIntegrationMessage('Mintkit widget not connected');
    setTimeout(() => {
      setIntegrationMessage('');
    }, 3000);
  };

  return (
    <div className="md:mx-auto max-w-xl">
      <h2 className="text-6xl font-flexa font-bold dark:text-darkText mb-4" ref={ref}>
        Setup Mintkit
      </h2>
      <p className="dark:text-darkText md:text-base">
        Get your integration setup and working. You can setup the widget on localhost or a live domain. Don’t worry,
        your wallet is the only wallet that can interact with the widget before you launch.
      </p>
      <Section>
        <Label>API Key</Label>
        <InstructionText>Copy and paste the below API into your widget integration to get connected.</InstructionText>
        <CopyApi text={id} />
      </Section>
      <Section>
        {/* <Label>Domain</Label>
        <InstructionText>
          The domain that will host your widget. Required to enable twitter and discord authentication redirects. The
          widget is compatible with localhost by default.
        </InstructionText>
        <TextInput inputPlaceholder={'https://www.google.com'} /> */}
        <Section>
          <Label>Javascript Integration</Label>
          <InstructionText>
            Use the following code to load the Mintkit javascript SDK on your site. Your personal API key is already
            included in the URL.
          </InstructionText>
          <CodeSnippetComponent language="vbscript-html" codeString={importSourceCode} />
        </Section>
        <div className="mt-16">
          <Label className="mt-16">Integration Status</Label>
          <InstructionText>
            Once you've completed your integration, refresh the connection to guarantee everything is working.
          </InstructionText>
          {currentProjectData && currentProjectData.componentsLastSeenAt ? (
            <div className="flex flex-row items-center">
              <div className="mr-auto mt-4 dark:text-darkSecondaryText">
                <div className="relative">
                  <span className="absolute animate-ping top-1 bg-green-100 h-4 w-4 rounded-full flex items-center justify-center" />
                  <span className="absolute top-2 left-1 l-2 h-2 w-2 bg-green-400 rounded-full flex items-center justify-center" />
                </div>
                <p className="text-gray-400 ml-8">Mintkit Connected</p>
              </div>
            </div>
          ) : (
            <>
              <div className="flex flex-row items-center">
                <div className="mr-auto mt-4 dark:text-darkSecondaryText">
                  <div className="relative">
                    <span className="absolute animate-ping top-1 bg-red-100 h-4 w-4 rounded-full flex items-center justify-center" />
                    <span className="absolute top-2 left-1 l-2 h-2 w-2 bg-red-400 rounded-full flex items-center justify-center" />
                  </div>
                  <p className="text-gray-400 ml-8">Looking for Connection - Not Connected</p>
                </div>
                <button
                  onClick={() => refreshConnection()}
                  className="text-xs px-2 py-1 md:px-2 md:py-1 rounded-full font-bold ml-auto bg-white text-black border border-1 mt-4"
                >
                  Refresh connection
                  {/* <Spinner fillColor={''} /> */}
                </button>
              </div>
              {integrationMessage && <ErrorMessage className="text-right mt-1">{integrationMessage}</ErrorMessage>}
            </>
          )}
        </div>
      </Section>

      <div className="flex flex-row md:mt-48 mt-24">
        <StepButton onClick={() => navigate(-1)} className="mr-auto bg-white text-black border border-1">
          Back
        </StepButton>
        <StepButton
          onClick={() => navigate(routeNavigation(id, phase))}
          className="ml-auto bg-[#07074E] dark:bg-darkButton text-white"
        >
          Next
        </StepButton>
      </div>
    </div>
  );
};

export default ProjectSetup;
