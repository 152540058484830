import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckBox from '../../../../components/CheckBox';
import SubmitButton from '../../../../components/SubmitButton';
import TextInput from '../../../../components/TextInput';
import store from '../../../../store/store';
import Select, { StylesConfig } from 'react-select';

interface AddUserProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: any;
}

const EditUser = ({ setOpen, selectedUser }: AddUserProps) => {
  const { id } = useParams();
  const [name, setName] = useState(selectedUser?.name ?? '');
  const editUser = store((s) => s.userSlice.editUser);

  const submitEditUser = async (project_id) => {
    if (selectedUser?.id) {
      const r = await editUser(project_id, selectedUser.id, name);
      if (r) setOpen(false);
    }
  };

  return (
    <div className="p-3 md:p-0">
      <p className="font-flexa text-4xl dark:text-darkText">Edit User</p>
      <p className="text-sm mb-4 dark:text-darkSecondaryText mt-1">{`Change information for ${selectedUser?.name}. `}</p>

      <TextInput inputPlaceholder={'name'} value={name} onChange={setName} />
      <div className="mt-8">
        <SubmitButton className="mt-4" onClick={() => submitEditUser(id)}>
          Edit User
        </SubmitButton>
      </div>
    </div>
  );
};

export default EditUser;
