import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CheckBox from '../../../../components/CheckBox';
import SubmitButton from '../../../../components/SubmitButton';
import TextInput from '../../../../components/TextInput';
import store from '../../../../store/store';
import Select, { StylesConfig } from 'react-select';
import { MultiSelect } from '@mantine/core';
import { multiSelectStyles, multiSelectLightStyles } from './MultiSelectStyle';
import { DarkModeContext } from '../../../../hooks/hooks';

interface AddUserProps {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedUser: any;
  projectId: string;
}

const AddTagsToUser = ({ setOpen, selectedUser, projectId }: AddUserProps) => {
  const { id } = useParams();
  const [name, setName] = useState('');
  const [bypass, setBypass] = useState(false);
  const tags = store((s) => s.tagSlice.tags);
  const getTags = store((s) => s.tagSlice.getTags);
  const addTagsToUser = store((s) => s.tagSlice.addTagsToUser);
  const darkModeContext = useContext(DarkModeContext);

  const [selectedOption, setSelectedOption] = useState(null);

  const submitUser = async (project_id) => {
    const ids = selectedOption ? selectedOption.map((x) => x.id) : [];
    const r = await addTagsToUser(selectedUser?.id, ids, projectId);
    if (r) setOpen(false);
  };

  // remove duplicate tags
  const availableTags = tags.filter((tag) => selectedUser?.tags.every((userTag) => userTag.id != tag.id));

  useEffect(() => {
    getTags(id);
  }, [id]);

  return (
    <div className="p-3 md:p-0">
      <p className="font-flexa text-4xl dark:text-darkText">Add Tag to User</p>
      <p className="text-sm mb-4 dark:text-darkSecondaryText">
        {selectedUser?.name ? `Add tags to ${selectedUser.name}` : 'Add tags to user'}
      </p>
      {selectedUser?.tags.length > 0 && <p className="text-sm dark:text-darkText">Current tags</p>}
      {selectedUser?.tags.length > 0 && (
        <p className="text-sm mb-4 dark:text-darkSecondaryText">
          {selectedUser?.tags.map((tag, i) => (
            <span key={i}>
              {tag.name}
              {i < selectedUser?.tags.length - 1 && `, `}
            </span>
          ))}
        </p>
      )}
      <Select
        maxMenuHeight={100}
        isMulti
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={availableTags}
        getOptionLabel={(tag) => tag.name}
        getOptionValue={(tag) => tag.name}
        styles={darkModeContext.darkMode && multiSelectStyles}
      />

      <div className="mt-8">
        <SubmitButton className="mt-4" onClick={() => submitUser(id)}>
          Add Tags
        </SubmitButton>
      </div>
    </div>
  );
};

export default AddTagsToUser;
