import React from 'react';
import Countdown from 'react-countdown';

// Random component
const Completionist = () => <div className="pt-4" />;

// Renderer callback with condition
const renderer = ({ days, hours, minutes, seconds, completed }) => {
  if (completed) {
    // Render a completed state
    return <Completionist />;
  } else {
    // Render a countdown

    return (
      <div className="flex gap-1 pt-2 dark:text-darkText">
        <div>
          <span className="  text-xs">
            <span className="text-base font-bold"> {`${days} `}</span>
            days,
          </span>
        </div>
        <div>
          <span className="  text-xs">
            <span className="text-base font-bold">{`${hours} `}</span>
            hours,
          </span>
        </div>
        <div>
          <span className="  text-xs">
            <span className="text-base font-bold">{`${minutes} `}</span>
            min,
          </span>
        </div>
        <div>
          <span className="  text-xs">
            <span className="text-base font-bold">{`${seconds} `}</span>
            sec
          </span>
        </div>
      </div>
    );
  }
};

interface CountdownTimerProps {
  phaseData: any;
}

const CountdownTimer = ({ phaseData }: CountdownTimerProps) => {
  if (!phaseData || !phaseData.starts_at) return <div className="pt-8" />;
  return <Countdown key={`${phaseData.id}_Countdown`} date={new Date(phaseData.starts_at)} renderer={renderer} />;
};

export default CountdownTimer;
