import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useParams } from 'react-router-dom';
import tw from 'twin.macro';
import { LOCAL_STORAGE_TYPES } from '../../../../../types/shared';
import './DateTimePicker.css';

const Label = tw.label`dark:text-darkText text-xs font-bold mb-1`;

interface DateTimePickerProps {
  startDate: null | Date;
  setStartDate: React.Dispatch<React.SetStateAction<null | Date>>;
  endDate: null | Date;
  setEndDate: React.Dispatch<React.SetStateAction<null | Date>>;
}
const DateTimePicker = ({ startDate, setStartDate, endDate, setEndDate }: DateTimePickerProps) => {
  const { id } = useParams();

  const filterPassedTime = (time) => {
    const selectedDate = new Date(time);
    if (startDate) {
      return startDate.getTime() < selectedDate.getTime();
    } else {
      const currentDate = new Date();
      return currentDate.getTime() < selectedDate.getTime();
    }
  };

  return (
    <div className="flex flex-row max-w-full overflow-hidden space-x-4">
      <div className="flex flex-col">
        <Label>Select Start Time </Label>
        <DatePicker
          selected={startDate}
          onChange={(date) => {
            console.log('date: ', date);
            if (date) {
              localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_STARTS_AT + id, date.toISOString());
            }
            setStartDate(date);
            if (date > endDate) {
              setEndDate(null);
            }
          }}
          // selectsStart
          showTimeSelect
          dateFormat="Pp"
          // startDate={startDate}
          // endDate={endDate}
          minDate={new Date()}
          placeholderText="Start Time"
          className="p-2 border dark:text-darkText border-grey-100 dark:bg-darkSecondary border border-gray-200 dark:border-gray-600 focus:outline-none  focus:border-gray-300  dark:focus:border-gray-300 rounded-md"
          calendarClassName="dark:text-darkText dark:bg-darkSecondary"
        />
      </div>
      <div className="flex flex-col">
        <Label>Select End Time </Label>
        <DatePicker
          selected={endDate}
          onChange={(date) => {
            if (date) {
              localStorage.setItem(LOCAL_STORAGE_TYPES.PLUTO_PHASE_ENDS_AT + id, date.toISOString());
            }
            setEndDate(date);
          }}
          // selectsEnd
          showTimeSelect
          dateFormat="Pp"
          // startDate={startDate}
          // endDate={endDate}
          filterTime={filterPassedTime}
          placeholderText="End Time"
          minDate={startDate ? startDate : new Date()}
          className="p-2 border dark:text-darkText border-grey-100 dark:bg-darkSecondary border border-gray-200 dark:border-gray-600 focus:outline-none  focus:border-gray-300  dark:focus:border-gray-300 rounded-md"
          calendarClassName="dark:text-darkText dark:bg-darkSecondary"
        />
      </div>
    </div>
  );
};

export default DateTimePicker;
