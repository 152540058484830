export interface LocationState {
  state: {
    edit: boolean;
  };
}

export type ToggleRegistrationRequirements = {
  eth_balance: boolean;
  twitter: boolean;
  discord: boolean;
};

export enum MissionTypes {
  eth_balance = 'eth_balance',
  twitter = ' twitter',
  discord = 'discord',
}

export enum NetworkDbName {
  ETHEREUM = 'ethereum',
  GOERLI = 'ethereum-goerli',
}

export enum NetworkTypes {
  MAINNET = 'Mainnet',
  GOERLI = 'Goerli Testnet',
}

export enum LOCAL_STORAGE_TYPES {
  PLUTO_TWITTER_MISSION = 'pluto_twitter_mission_',
  PLUTO_DISCORD_MISSION = 'pluto_discord_mission_',
  PLUTO_ETHEREUM_MISSION = 'pluto_ethereum_mission_',
  PLUTO_CURRENT_CONTRACT = 'pluto_current_contract_',
  PLUTO_MINT_PRICE = 'pluto_mint_price_',
  PLUTO_MINT_QUANTITY = 'pluto_mint_quantity_',
  PLUTO_PHASE_FUNCTIONS = 'pluto_phase_functions_',
  PLUTO_EDITING_PHASE = 'pluto_editing_phase_',
  PLUTO_CURRENT_PHASE_TAGS = 'pluto_current_phase_tags_',
  PLUTO_PHASE_STARTS_AT = 'pluto_phase_starts_at_',
  PLUTO_PHASE_ENDS_AT = 'pluto_phase_ends_at_',
}

declare global {
  interface Date {
    addDays(days: number): Date;
  }
}

Date.prototype.addDays = function (days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
};
