import { StoreApi } from 'zustand';
import axios from '../async/axios';
import { lens } from '@dhmk/zustand-lens';
import { Store } from './store';
import { SiweMessage } from 'siwe';

export enum AccountLoadingState {
  NULL,
  GET_ACCOUNT,
}

export interface AccountSlice {
  loadingType: AccountLoadingState;
  loadingMessage: string;
  errorType: AccountLoadingState;
  errorMessage: string;
  isUserSignedIn: boolean;
  walletAddress: null | string;
  accountData: null | any;
  currentTeam: string;
  fetchIsUserSignedIn: (cb?: () => void) => Promise<boolean>;
  signOut: (cb?: () => void) => Promise<void>;
  signIn: (address: string, message: string, signature: string, cb?: () => void) => Promise<void>;
  resetState: () => Promise<void>;
  resetLoading: () => Promise<void>;
}

const initalState = {
  loadingType: AccountLoadingState.NULL,
  loadingMessage: '',
  errorMessage: '',
  errorType: AccountLoadingState.NULL,
  isUserSignedIn: false,
  walletAddress: null,
  accountData: null,
  currentTeam: null,
};

export const accountSlice: AccountSlice = lens((setState, getState, api: StoreApi<Store>) => ({
  ...initalState,
  fetchIsUserSignedIn: async (cb) => {
    getState().resetLoading();
    try {
      setState({ loadingType: AccountLoadingState.GET_ACCOUNT });
      const response = await axios.get(`/account`);
      if (response && response.status === 200) {
        setState({
          isUserSignedIn: true,
          accountData: response.data.data,
          currentTeam: response.data.data.teams[0]?.id,
        });

        // get projects
        api.getState().projectSlice.getAllProjects();
        api.getState().walletModalSlice.closeWalletModal();
        setState({ loadingType: AccountLoadingState.NULL });
        if (cb) cb();
        return true;
      }
    } catch (e) {
      setState({
        errorType: AccountLoadingState.GET_ACCOUNT,
        errorMessage: e.response?.data?.error,
        loadingType: AccountLoadingState.NULL,
      });

      return false;
    }
  },
  signOut: async (cb) => {
    try {
      const response = await axios.post(`/accounts/signout`);

      if (response && response.status === 200) {
        api.getState().universalSlice.resetAppState();
        if (cb) cb();
      }
    } catch {}
  },
  signIn: async (address, message, signature, cb): Promise<void> => {
    if (!address) return;

    const scrapedNonce = message.match(/Nonce\: [a-z0-9]+/gi)[0].split('Nonce: ')[1];

    try {
      const response = await axios.post(
        `/accounts/web3signin`,
        {
          chain: 'ethereum',
          address: address,
          message,
          signature,
          link_account: false,
        },
        { headers: { 'X-CSRF-Token': scrapedNonce } }
      );

      if (response && response.status === 200) {
        getState().fetchIsUserSignedIn(cb);
      }
    } catch (e) {}
  },
  resetLoading: async () => {
    setState({
      loadingType: AccountLoadingState.NULL,
      loadingMessage: '',
      errorType: AccountLoadingState.NULL,
      errorMessage: '',
    });
  },
  resetState: async () => {
    setState(initalState);
  },
}));
