import React from 'react';
// import { Provider as SolanaProvider } from "./solana"
import { Provider as WAGMIProvider } from './wagmi';
import { Wallets as WAGMIWallets } from './wagmi';

type WalletProviderProps = {
  children?: React.ReactNode;
};

const WalletProviders = ({ children }: WalletProviderProps) => {
  return (
    <WAGMIProvider>
      {/* <SolanaProvider>{children}</SolanaProvider> */}
    </WAGMIProvider>
  );
};

const Wallets = () => {
  return (
    <>
      <WAGMIWallets />
      {/* <SolanaWallets /> */}
    </>
  );
};

export { WalletProviders, Wallets };
