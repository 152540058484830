import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/images/pluto-x-thicc.png';
import styles from './marketing.module.css';
import { Link, useNavigate } from 'react-router-dom';
import store from '../../store/store';
import { useAccount } from 'wagmi';
import MintkitComponent from './components/MintkitComponent';
import GetStartedCodeSection from './components/SetupCodeSection';
import InfoSection from './components/InfoSection';
import ContactSection from './components/ContactSection';
import { SiGithub, SiTwitter, SiDiscord } from 'react-icons/si';
import MarketingFooter from './components/MarketingFooter';
import { MarketingButton } from './components/MarketingButton';

export default function Marketing() {
  const navigate = useNavigate();

  const { address } = useAccount();

  const accountData = store((state) => state.accountSlice.accountData);

  // Redirect if user signed in, else open wallet modal
  const startBuilding = () => {
    if (address && accountData) {
      navigate('/projects');
    } else {
      // openWalletModal();
      navigate('/connect');
    }
  };

  const headerLinks = [
    { text: 'Docs', link: 'https://docs.pluto.xyz' },
    { text: 'Github', link: 'https://github.com/pluto' },
    { text: 'Jobs', link: 'https://github.com/pluto' },
  ];

  return (
    <>
      <div className={`overflow-x-hidden bg-marketingBg`}>
        <div className="hidden w-screen absolute">
          <div className="items-center flex flex-col text-white">|</div>
        </div>
        <div className={styles.gradientBg}>
          <header className="w-full flex flex-row px-8 md:px-16 xl:px-36 py-16 relative">
            <Link to="/projects" className=" group">
              <div className="flex flex-row items-center">
                <h1 className={`text-2xl tracking-wide  mt-2 text-white`}>Mintkit</h1>
              </div>
            </Link>

            <div className="absolute mx-auto left-0 right-0 w-16 hidden lg:block">
              <a href="https://www.pluto.xyz" target="_blank">
                <img src={logo} className="h-16 w-auto" alt="logo" />
              </a>
            </div>

            <div className="flex flex-row items-center space-x-2 ml-auto">
              <div className="sm:ml-0 hidden md:block mr-4">
                <nav>
                  <ul className="flex flex-row align-bottom space-x-8 m-0">
                    {headerLinks.map((link) => (
                      <a
                        key={link.text}
                        className={`text-white text-md ${styles.linkUnderline}`}
                        target="_blank"
                        href={link.link}
                      >
                        {link.text}
                      </a>
                    ))}
                  </ul>
                </nav>
              </div>
              <button
                className="relative flex flex-row rounded-full px-4 py-2  border border-[1px] text-white hover:cursor-pointer items-center hover:scale-[102.5%] hover:bg-white hover:text-black transition-all"
                onClick={() => startBuilding()}
              >
                Launch App
              </button>
              {/* TODO  mobile menu? */}
              {/* <button
              type="button"
              className="max-w-xs flex items-center text-sm rounded-full "
              id="user-menu-button"
              aria-expanded="false"
              aria-haspopup="true"
              onClick={() => setUserMenuOpen(!userMenuOpen)}
            >
              <span className="sr-only">Open user menu</span>
            </button> */}
            </div>

            {/* <button
            className="block mr-4 sm:hidden"
            onClick={() => {
              openNav();
            }}
          >
            <img src={burger} className="h-6 -mt-2" alt="menu" />
          </button> */}

            {/* TODO: finish me -- needs to appear */}
            <aside className="hidden">
              <nav>
                <ul>
                  {headerLinks.map((link) => (
                    <a
                      key={link.text}
                      className="text-white text-md hover:underline transition-all"
                      target="_blank"
                      href={link.link}
                    >
                      {link.text}
                    </a>
                  ))}
                </ul>
              </nav>
            </aside>
          </header>

          <div className="flex flex-col pt-8 lg:pt-32 2xl:pt-36 pb-16 lg:pb-36 px-8 md:px-16 xl:px-36 items-center place-content-center">
            <h1 className={`text-white mb-8 lg:mb-12 text-5xl md:text-8xl animate-fade-in-down text-center`}>
              NFT Minting best <br className="hidden lg:block" /> practices in one SDK
            </h1>

            <h2 className="text-marketingText text-xl mb-4 lg:max-w-2xl text-center">
              Mintkit enables NFT mints to scale. A simple integration adds early access lists, private minting for
              friends and family and bot protected public mints. Your brand, your website, your contract.
            </h2>

            <div className="flex flex-row gap-4 items-center place-content-center">
              <MarketingButton className="mt-8" onClick={() => startBuilding()}>
                Start Building
              </MarketingButton>
              <a
                target="_blank"
                href="https://github.com/pluto"
                className={`font-medium py-3 md:mx-10 mt-8 text-white flex flex-row items-center  text-black hover:cursor-pointer transition-all  ${styles.arrow}`}
              >
                Read the docs
              </a>
            </div>
          </div>
        </div>

        <MintkitComponent />

        <hr className="pt-16 lg:pt-32 2xl:pt-36 text-white opacity-25 w-1/2  mx-auto" />

        <GetStartedCodeSection />

        <hr className="pt-32 2xl:pt-36 text-white opacity-25 w-1/2  mx-auto" />

        <InfoSection />

        <ContactSection />

        <MarketingFooter />
      </div>
    </>
  );
}
