import { RadioGroup } from '@headlessui/react';
import React, { useState } from 'react';
import Button from '../../components/Button';
import tw from 'twin.macro';
import avatar from '../../assets/images/avatar-upload.png';
import twitter from '../../assets/images/twitter.png';
import discord from '../../assets/images/discord.png';
import opensea from '../../assets/images/opensea.png';

import { useNavigate } from 'react-router-dom';
import TextInput from '../../components/TextInput';
import store from '../../store/store';
import Spinner from '../../components/Spinner';
import SubmitButton from '../../components/SubmitButton';
import { ProjectLoadingState } from '../../store/projectSlice';
import ErrorMessage from '../../components/ErrorMessage';
import { PLUTO_COMMUNITY_IMAGE } from '../../assets/images/pluto-community-image';

const Section = tw.div`my-8 flex flex-col`;
const Label = tw.label`my-4 dark:text-darkText`;

const SectionHeader = tw.p`mb-2`;
const Card = tw.div`py-12 px-3 border border-gray-200 rounded-md text-center space-y-4`;

const NewProject = () => {
  const navigate = useNavigate();

  const newProject = store((s) => s.projectSlice.newProject);
  const loadingType = store((s) => s.projectSlice.loadingType);
  const errorType = store((s) => s.projectSlice.errorType);
  const errorMessage = store((s) => s.projectSlice.errorMessage);

  const [projectName, setProjectName] = useState('');
  const [projectDomains, setProjectDomains] = useState('');

  const createNewProject = async () => {
    let domains = projectDomains ? projectDomains.replace(/\s+/g, '').split(',') : [];
    newProject(projectName, domains, PLUTO_COMMUNITY_IMAGE, (id) => navigate(`/projects/${id}`));
  };

  return (
    <div className="max-w-xl mx-auto mb-8">
      <p className="text-6xl font-flexa font-bold mb-4 dark:text-darkText">Create Project</p>

      <p className="dark:text-darkText">
        A project represents an integration with Mintkit. Use a project to manage a website and contract integration,
        the users in a community and the gating methods used for minting.
      </p>
      <Section>
        <Label>Project name</Label>
        <TextInput inputPlaceholder={'Project name'} value={projectName} onChange={setProjectName} />
      </Section>
      <Section>
        <Label>{`Project domains (separate multiple domains by comma)`}</Label>
        <TextInput inputPlaceholder={'yourproject.xyz'} value={projectDomains} onChange={setProjectDomains} />
      </Section>
      <hr className="my-4 dark:border-gray-600" />
      <div className="flex flex-row justify-between ">
        <SubmitButton onClick={() => navigate(`/projects`)}>Back</SubmitButton>
        <SubmitButton className="flex flex-row items-center" onClick={() => createNewProject()}>
          Create {loadingType === ProjectLoadingState.NEW_PROJECT && <Spinner color={'white'} />}
        </SubmitButton>
      </div>
      {errorType === ProjectLoadingState.NEW_PROJECT && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </div>
  );
};

export default NewProject;
