import React, { useContext, useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { atomOneDark, atomOneLight } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IoDocument } from 'react-icons/io5';
import { TbClipboard } from 'react-icons/tb';
import { BsCheck2Circle } from 'react-icons/bs';
import './CodeSnippetComponent.css';
import { DarkModeContext } from '../../hooks/hooks';

interface CodeEditorProps {
  language: 'javascript' | 'typescript' | 'vbscript-html';
  codeString: string;
  showLineNumbers?: boolean;
  bodyStyles?: string;
  showHeader?: boolean;
  ignoreDarkMode?: boolean;
}

/**
 * Component to show snippet of code
 *
 * @param {string} language - "javascript" | "typescript"
 * @param {string} codeString - The code to be displayed - pass in as one long string
 * @param {boolean} lashowLineNumbersbel - Text label above the drag and drop area
 */
const CodeSnippetComponent = ({
  language,
  codeString,
  bodyStyles,
  showLineNumbers = true,
  showHeader = true,
  ignoreDarkMode = false,
}: CodeEditorProps) => {
  const [copyVisible, setCopyVisible] = useState(false);
  const [effect, setEffect] = useState(false);

  const darkModeContext = useContext(DarkModeContext);

  return (
    <div
      className={`mt-4 relative ${bodyStyles}`}
      onMouseEnter={() => setCopyVisible(true)}
      onMouseLeave={() => setCopyVisible(false)}
    >
      <div
        className={`${showHeader ? '' : 'hidden'} rounded-t-lg ${
          ignoreDarkMode
            ? 'bg-[#282c34] text-[#ABB2BF] border-[rgb(105,105,105)]'
            : 'dark:bg-[#282c34] bg-[#fafafa]  dark:text-[#ABB2BF] text-[#383a42] border-[rgb(55,55,55)] dark:border-[rgb(105,105,105)]'
        }  w-full pl-2  border-b border-opacity-50  py-1.5`}
      >
        <span className="ml-1 flex flex-row items-center  self-start px-2">
          <IoDocument size={14} />
          <span className="ml-1">Example.js</span>
        </span>
      </div>

      <SyntaxHighlighter
        className={`${showHeader ? '' : 'rounded-t-lg'} rounded-b-lg hide-scrollbar`}
        style={darkModeContext.darkMode ? atomOneDark : atomOneLight}
        language={language}
        showLineNumbers={showLineNumbers}
        wrapLines={false}
        lineNumberStyle={{
          color: 'dark:rgb(75,75,75) rgb(255,255,255)',
          borderRight: '1px solid rgb(105,105,105)',
          marginRight: '0.75rem',
        }}
      >
        {codeString}
      </SyntaxHighlighter>
      <div
        onClick={() => {
          setEffect(true);
          setTimeout(() => setEffect(false), 1500);
          navigator.clipboard.writeText(codeString);
        }}
        className={`z-10 absolute bottom-4 text-white -translate-x-1/2 left-1/2 transform  transition-all duration-300 ease-in-out ${
          copyVisible ? 'scale-100' : 'scale-0'
        } `}
      >
        <span className="flex flex-row w-24 items-center py-2 bg-plutoblue-500 hover:bg-plutoblue-400 dark:bg-plutoorange-500 hover:cursor-pointer dark:hover:bg-plutoorange-600 rounded-full place-content-center">
          {effect ? (
            <BsCheck2Circle size={16} className={`text-white `} />
          ) : (
            <TbClipboard size={16} className="text-white" />
          )}
          <span className="pl-2">{effect ? 'Copied' : 'Copy'}</span>
        </span>
      </div>
    </div>
  );
};

export default CodeSnippetComponent;
