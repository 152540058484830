import { Menu } from '@headlessui/react';
import React from 'react';

interface WalletMenuButtonProps {
  children: string | JSX.Element;
}

// WalletMenuButtonProps
const WalletMenuButton: React.FC<WalletMenuButtonProps> = ({ children }) => {
  return (
    <Menu.Button className="rounded-full px-4 py-1.5 flex flex-row items-center transition-all hover:bg-lightHover hover:dark:bg-darkHover dark:border-darkText border border-plutoblue-500 ">
      {children}
    </Menu.Button>
  );
};

export default WalletMenuButton;
