import React from 'react';

interface ButtonProps {
  text: string;
  additionalStyle?: string;
  onClick?: () => void;
}

const SecondaryButton = ({ text, additionalStyle, onClick }: ButtonProps) => {
  return (
    <button
      onClick={onClick}
      className={`font-bold text-xs lg:text-sm dark:border dark:text-white border hover:bg-opacity-25 bg-transparent dark:darkButtonText  text-plutoblue-500 px-6 py-2 rounded-full text-center ${additionalStyle}`}
    >
      {text}
    </button>
  );
};

export default SecondaryButton;
