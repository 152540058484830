import React from 'react';
import './Spinner';

type FillColorType = 'black' | 'white';

type SpinnerProps = {
  size?: string;
  color: FillColorType;
};

const Spinner = ({ size, color }: SpinnerProps) => {
  const dimensions = size === 'large' ? ' h-64 w-64 border-8 border-t-8' : `h-4 w-4  border-t-2 border-t-${color}`;
  return <div className={` ml-2 loader ease-linear rounded-full ${dimensions}`}></div>;
};

export default Spinner;
